import Axios from 'axios';
import { Message } from 'element-ui'
import qs from 'qs'
/*let baseURL;
if(process.env.NODE_ENV==='develop'){
  baseURL = '/api/'
}else {
  baseURL = process.env.VUE_APP_BASE_URL
}*/
const axios =  Axios.create({
  baseURL:'/api/',
  timeout:300000
})



/**
 * 请求开始时的拦截器
 */
 axios.interceptors.request.use(
  request=>{
    if(request.method==='post'&&request.headers['Content-Type']==='application/x-www-form-urlencoded'){
      request.data = qs.stringify({
        ...request.data
      })
    }
    return request
  },
  error=>{
    return Promise.reject(error)
  }
)

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  response=>{
    return response
  },
  error=>{
    if(error && error.response){
      switch (error.response.status) {
        case 400:
          error.message = '错误请求'
          break
        case 401:
          error.message = '未授权，请重新登录'
          break
        case 403:
          if (error.response.data.code == '32008002') {
            sessionStorage.clear()
            // removeToken()
          } else {
            error.message = '拒绝访问'
          }
          break
        case 404:
          error.message = '请求错误,未找到该资源'
          break
        case 405:
          error.message = '请求方法未允许'
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器端出错'
          break
        case 501:
          error.message = '网络未实现'
          break
        case 502:
          error.message = '网络错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网络超时'
          break
        case 505:
          error.message = 'http版本不支持该请求'
          break
        default:
          error.message = `连接错误${error.response.status}`
      }
    }else{
      error.message = '连接服务器失败';
    }
    Message({
      message:error.message,
      type:'error',
      duration:10 * 1000
    })
    return Promise.reject(error.response)
  }
)


const CancelToken = Axios.CancelToken;
export const source = CancelToken.source();

export function $post(url,params,config={headers: {'Content-Type': 'application/x-www-form-urlencoded'}}){
  return new Promise((resolve, reject) => {
    axios.post(url, params, config).then(res => {
      // 处理请求结果
      resolve(res);
    }).catch(error => {
      reject(error)
    });
  })
}

export function $postForJson(url,params,config={headers:{'Content-Type':'application/json'}}){
  return new Promise((resolve, reject) => {
    axios.post(url, params, config).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error)
    });
  });
}

export function $get(url,config){
  return new Promise((resolve, reject) => {
    axios.get(url,config).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error)
    });
  });
}
