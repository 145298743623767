var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "identityCard" }, [
    _c("div", { staticClass: "boxHeader" }, [
      _c("div", { staticClass: "boxHeader-left" }, [
        _c("div", { on: { click: _vm.black } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }),
          _vm._v("返回")
        ])
      ]),
      _c("div", { staticClass: "boxHeader-center" }, [_vm._v("身份证扫描")]),
      _c("div", { staticClass: "boxHeader-right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/handle_countdown.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("倒计时：" + _vm._s(_vm.countdown) + "s")])
      ])
    ]),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxContent" }, [
      _c("div", { staticClass: "carImg" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/handle_idcard.png"),
            alt: ""
          }
        })
      ]),
      _c("p", [_vm._v("请在屏幕下方进行身份证扫描")]),
      _c("div", { staticClass: "xiaImg" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/handle_downarrow.png"),
            alt: ""
          }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }