<template>
  <div class="dynamic-form">
    <el-form ref="formCompony" :rules="rules" class="form-area">
      <el-row>
	      <el-col>
		      <i class="el-icon-arrow-left backIcon" @click="goback">返回</i>
	      </el-col>
        <el-col class="style-col">
          <h3 class="title-top">{{taskName}}</h3>
          <span class="recv-id">申请编号：{{subjectRecvId}}</span>
        </el-col>
        <el-col>
          <el-card class="card-items">
            <span class="fillSpan">
              <i class="el-icon-bottom-right fillSpan-icon"></i>
              选择类型
              <i class="fillI"></i>
            </span>
            <div style="margin:10px">
              <el-form :model="baseInfoForm" label-width="220px">
                <el-form-item label="类型:">
                  <el-radio-group v-model="baseInfoForm.servType">
                    <el-radio :label="2">企业申请</el-radio>
                    <el-radio :label="1">个人申请</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-form>
            </div>
          </el-card>
        </el-col>
        <el-col v-show="baseInfoForm.servType==2">
          <el-card class="card-items">
            <span class="fillSpan">
              <i class="el-icon-bottom-right fillSpan-icon"></i>
              企业申请
              <i class="fillI"></i>
            </span>
            <div style="margin:10px">
              <el-form ref="enterForm" :model="enterForm" label-width="220px" :rules="formRules">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="申请单位" prop="enterpriseName">
                      <el-input v-model="enterForm.enterpriseName" placeholder="请输入申请单位" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="单位类型" prop="enterpriseType">
                      <el-select v-model="enterForm.enterpriseType" placeholder="请选择单位类型" style="width:100%" clearable>
                        <el-option value="1" label="中小企业"></el-option>
                        <el-option value="2" label="民营企业"></el-option>
                        <el-option value="3" label="私营企业"></el-option>
                        <el-option value="4" label="个体工商户"></el-option>
                        <el-option value="5" label="社会组织"></el-option>
                        <el-option value="6" label="国有企业"></el-option>
                        <el-option value="7" label="政府机构"></el-option>
                        <el-option value="99" label="其他"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否法定代表人" prop="isLegal">
                      <el-radio-group v-model="enterForm.isLegal">
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="代表人" prop="enterpriseLegalName">
                      <el-input v-model="enterForm.enterpriseLegalName" placeholder="请输入代表人姓名" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="法人证件类型" prop="enterpriseLegalType">
                      <el-select v-model="enterForm.enterpriseLegalType" style="width:100%" placeholder="请选择法人证件类型" clearable @change="(val)=>{cardTypeChange(val,'enterpriseLegalIdcard')}">
                        <el-option label="身份证" value="111" ></el-option>
                        <el-option label="其他" value="999" ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="法人证件号码" prop="enterpriseLegalIdcard">
                      <el-input v-model="enterForm.enterpriseLegalIdcard" placeholder="请输入法人证件号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件类型" prop="enterpriseCardType">
                      <el-select v-model="enterForm.enterpriseCardType" style="width:100%" placeholder="请选择证件类型" clearable>
                        <el-option label="工商局登记号（营业执照）" value="003" ></el-option>
                        <el-option label="其他" value="999" ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件号码" prop="enterpriseCardNum">
                      <el-input v-model="enterForm.enterpriseCardNum" placeholder="请输入证件号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人" prop="enterpriseContactName">
                      <el-input v-model="enterForm.enterpriseContactName" placeholder="请输入联系人" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人手机" prop="enterpriseContactMobile">
                      <el-input v-model="enterForm.enterpriseContactMobile" placeholder="请输入联系人手机" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="固定电话">
                      <el-input v-model="enterForm.enterpriseContactFixed" placeholder="请输入固定电话" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人邮箱">
                      <el-input v-model="enterForm.enterpriseContactEmail" placeholder="请输入联系人邮箱" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="企业地址">
                      <el-input v-model="enterForm.enterpriseContactAddr" placeholder="请输入企业地址" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="事项备注">
                      <el-input v-model="enterForm.remark" placeholder="请输入事项备注" clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-card>
        </el-col>
        <el-col v-show="baseInfoForm.servType==1">
          <el-card class="card-items">
            <span class="fillSpan">
              <i class="el-icon-bottom-right fillSpan-icon"></i>
              个人申请
              <i class="fillI"></i>
            </span>
            <div style="margin:10px">
              <el-form :model="personForm" ref="personForm" label-width="220px" :rules="formRules">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="姓名" prop="personName">
                      <el-input v-model="personForm.personName" placeholder="请输入姓名" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="性别" prop="personSex">
                      <el-select v-model="personForm.personSex" style="width:100%" placeholder="请选择性别" clearable>
                        <el-option value="男"></el-option>
                        <el-option value="女"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="出生日期">
                      <el-date-picker
                        v-model="personForm.personBirth"
                        value-format="yyyy-MM-dd"
                        type="date"
                        :editable="false"
                        placeholder="选择出生日期" style="width:100%" clearable>
                      </el-date-picker>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件类型" prop="personCardType">
                      <el-select v-model="personForm.personCardType" style="width:100%" @change="(val)=>{cardTypeChange(val,'personCardNum')}" placeholder="请选择证件类型" clearable>
                        <el-option value="111" label="身份证"></el-option>
                        <el-option value="99" label="其他"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="证件号码" prop="personCardNum">
                      <el-input v-model="personForm.personCardNum" placeholder="请输入证件号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="籍贯">
                      <el-input v-model="personForm.personBirthPlace" placeholder="请输入籍贯" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="手机号码" prop="personMobile">
                      <el-input v-model="personForm.personMobile" placeholder="请输入手机号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="固定号码">
                      <el-input v-model="personForm.personFixed" placeholder="请输入固定号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="电子邮箱">
                      <el-input v-model="personForm.personContactEmail" placeholder="请输入电子邮箱" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="文化程度">
                      <el-select v-model="personForm.personDegree" style="width:100%" placeholder="请选择文化程度" clearable>
                        <el-option value="博士"></el-option>
                        <el-option value="硕士"></el-option>
                        <el-option value="本科"></el-option>
                        <el-option value="博士"></el-option>
                        <el-option value="大专"></el-option>
                        <el-option value="高中（中专）"></el-option>
                        <el-option value="初中"></el-option>
                        <el-option value="小学"></el-option>
                        <el-option value="其他"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="是否外籍">
                      <el-radio-group v-model="baseInfoForm.personIsForeign" clearable>
                        <el-radio label="1">是</el-radio>
                        <el-radio label="0">否</el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="国籍">
                      <el-input v-model="personForm.personNationality" placeholder="请输入国籍" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="联系人">
                      <el-input v-model="personForm.personContactName" placeholder="请输入联系人" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="地址">
                      <el-input v-model="personForm.personContactAddr" placeholder="请输入地址" clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-card>
        </el-col>
        <el-col v-if="baseInfoForm.isAgent===1">
          <el-card class="card-items">
            <span class="fillSpan">
              <i class="el-icon-bottom-right fillSpan-icon"></i>
              委托人信息
              <i class="fillI"></i>
            </span>
            <div style="margin:10px">
              <el-form :model="baseInfoForm" label-width="220px">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="委托人姓名:">
                      <el-input v-model="baseInfoForm.applyerName" placeholder="请输入委托人姓名" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="委托人证件类型:">
                      <el-select v-model="baseInfoForm.applyerCardType" style="width:100%" placeholder="请选择委托人证件类型" clearable>
                        <el-option v-for="item in cardTypeList1" :key="item.value" :value="item.value" :label="item.label"></el-option>
                      </el-select>
                    </el-form-item>                  
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="委托人证件号码:">
                      <el-input v-model="baseInfoForm.applyerCardCode" placeholder="请输入委托人证件号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="委托人手机号码:">
                      <el-input v-model="baseInfoForm.applyerMobile" placeholder="请输入委托人手机号码" clearable></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>              
            </div>
          </el-card>
        </el-col>      

        <el-col v-for="item in avueOptions" :key="item.name">
          <el-card class="card-items">
            <div class="fillSpan">
              <i class="el-icon-bottom-right fillSpan-icon"></i>
              {{item.name}}
              <i class="fillI"></i>
            </div>
            <div style="margin:10px 10px;">
              <el-form label-width="220px" :model="avueForm" :ref="item.name">
                <el-row :gutter="15">
                  <el-col :span="12" v-for="_item in item.form.column" :key="_item.prop">
                    <el-form-item :label="_item.label" :rules="_item.rules" :prop="_item.prop">
                      <el-input 
                        v-model="avueForm[_item.prop]"
                        v-if="_item.type==='input'"
                        clearable
                        @change="(value)=>{dynamicFormChange(value,_item)}"
                      ></el-input>
                      <el-input 
                        v-model="avueForm[_item.prop]"
                        v-if="_item.type==='textarea'"
                        type="textarea"
                        :autosize="{ minRows: 2, maxRows: 4}"
                        clearable
                        @change="(value)=>{dynamicFormChange(value,_item)}"
                      ></el-input>
                      <el-checkbox-group 
                        v-else-if="_item.type==='checkbox'"
                        v-model="avueForm[_item.prop]"
                        @change="(value)=>{dynamicFormChange(value,_item)}"
                      >
                        <el-checkbox v-for="__item in _item.dicData" :key="__item.optionSort" :label="__item.value">{{__item.label}}</el-checkbox>
                      </el-checkbox-group>
                      <el-radio-group 
                        v-else-if="_item.type==='radio'"
                        v-model="avueForm[_item.prop]"
                        @change="(value)=>{dynamicFormChange(value,_item)}"
                      >
                        <el-radio v-for="__item in _item.dicData" :key="__item.optionSort" :label="__item.value">
                          {{ __item.label }}
                        </el-radio>
                      </el-radio-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </el-card>
        </el-col>

        <!-- 需要上传的材料 -->
        <el-col>
          <el-card class="card-items">
            <span class="fillSpan">
              <i class="el-icon-bottom-right fillSpan-icon"></i>
              需要上传的材料
              <i class="fillI"></i>
            </span>
            <div style="margin:20px 10px;">
              <!-- 材料列表 -->
              <el-table
                ref="tableData"
                :data="tableData"
                stripe
                border
                :header-cell-style="{background:'#f7f7f7'}"
                height="300"
              >
                <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
                <el-table-column prop="materialName" label="材料名称" width="180" align="center"></el-table-column>
                <el-table-column label="数量要求" width="180" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.mSource==10">申请人自备、</span>
                    <span v-else-if="scope.row.mSource==20">政府部门核发、</span>
                    <span v-else-if="scope.row.mSource==30">中介机构或法定机构产生、</span>
                    <span v-if="scope.row.isOriginal==1">正本原件{{scope.row.originalNum}}份；</span>
                    <span v-if="scope.row.isCopy==1">正本复印件{{scope.row.copyNum}}份；</span>
                    <span v-if="scope.row.isDuplicateOriginal==1">副本原件{{scope.row.duplicateNum}}份；</span>
                    <span v-if="scope.row.isDuplicateCopy==1">副本复印件{{scope.row.duplicateCopyNum}}份；</span>
                  </template>
                </el-table-column>
                <el-table-column label="打印样表" align="center">
                  <template slot-scope="s">
                    <el-button type="primary" plain icon="el-icon-printer" @click="printFile(s.row.sampleFile)">打印</el-button>
                  </template>
                </el-table-column>
                <el-table-column label="扫码上传" align="center">
                  <template slot-scope="s">
<!--                    <span v-if="s.row.tempFileIds">系统生成</span>-->
	                  <div :key="uploadKey">
		                  <el-button

				                  v-if="s.row.fileIds.length===0"
				                  type="primary"
				                  plain
				                  icon="el-icon-mobile-phone"
				                  @click="openUploadDialog(s.row.materialId)"
		                  >手机扫码</el-button>
		                  <el-tag v-else closable @close="removePhoneUploadFile(s.row.materialId)">已上传</el-tag>
	                  </div>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="材料要求" align="center">
                  <template slot-scope="s">
                    <span v-if="s.row.mMedia == 1">纸质</span>
                    <span v-else-if="s.row.mMedia == 2">电子</span>
                    <span v-else-if="s.row.mMedia == 3">纸质、电子</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col style="text-align:center" id="nextHandlerButton">
          <el-button class="saveBtn" type="primary" @click="prepareSave" :loading="showLoading">下一步</el-button>
        </el-col>
      </el-row>

      <el-dialog
        title="扫码上传"
        :visible.sync="uploadDialogControl"
        width="30%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @closed="closeUploadDialog()"
      >
	      <div class="wechat-upload">
		      请用手机微信-扫描二维码上传材料
	      </div>
        <el-result v-show="fileUploadStatus" icon="success" title="上传成功"></el-result>
        <div v-show="!fileUploadStatus" class="qrcode" ref="qrCodeUrl"></div>

        <div slot="footer">
          <!-- <el-button @click="uploadDialogControl=false">取消</el-button>
          <el-button type="primary" @click="confirmFile()" :loading="uploading">确定</el-button> -->
        </div>
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
import * as formRules from '@/utils/formRules.js'
import QRCode from 'qrcodejs2';
import printJS from 'print-js'

import {source} from '@/utils/axios.js'
import { CombineMessage } from '@rongcloud/imlib-next';

/**
 * 根据证件类型返回证件验证规则
 * @param typeCode
 */
const cardTypeValidate = (typeCode)=>{
  let res;
  if(typeCode==='111'){
    res = [
      {required:true,message:'请输入申请人证件号',trigger:'blur'},
      {validator:formRules.FormValidate.validateIdentifyId,trigger:'blur'}
    ]
  }else{
    res = [{required:true,message:'请输入申请人证件号',trigger:'blur'}]
  }
  return res;
}
export default {
  name: "dynamicForm",
  data() {
    const cardTypeList1 = [
      { label: "身份证", value: "111" },
      { label: "其他", value: "099" }
    ];
    return {
      isPerson: 1,
      avueOptions: [],
      formObj: {},
      taskName: "",
      taskId: "",
      showLoading: false,
      tableData: [],
      subjectRecvId: "",
      uploadRefresh: true,
      uploadDialogControl: false,
      uploadId: "",
      fileList: [],
      submitLoading: false,
      selfData: [],
      isDisable: true,
      rules: {},
      taskFieldList: [],
      //基本信息and法定代表人信息
      baseInfoForm: {
        servType: 2
      },
      // 证件类型列表
      cardTypeList1: cardTypeList1,
      formRules: {
        applyerMobile: [
          { required: true, message: "请输入申请人手机号", trigger: "blur" },
          {
            trigger: "blur"
          }
        ],
        isAgent: [
          { required: true, message: "请选择申请人是否代理", trigger: "change" }
        ],
        isLegal: [
          {
            required: true,
            message: "请选择申请人是否法人代表",
            trigger: "change"
          }
        ],
        applyerName: [
          { required: true, message: "请输入申请人姓名", trigger: "blur" }
        ],
        applyerCardType: [
          { required: true, message: "请选择申请人证件类型", trigger: "change" }
        ],
        applyerSex: [
          { required: true, message: "请选择申请人性别", trigger: "change" }
        ],
        isMaterialAll: [
          {
            required: true,
            message: "请选择是否已提交全部材料",
            trigger: "change"
          }
        ],
        outputType: [
          { required: true, message: "请选择出件方式", trigger: "change" }
        ],
        personName:[
          {required:true,message:'请输入姓名',trigger:'blur'}
        ],
        personSex:{required:true,message:'请选择性别',trigger:'change'},
        personCardType:{required:true,message:'请选择证件类型',trigger:'change'},
        personCardNum:cardTypeValidate(null),
        personMobile:[
          {required:true,message:'请输入手机号码',trigger:'blur'},
          {validator:formRules.FormValidate.validatePhone,trigger:'blur'}
        ],
        enterpriseName:{required:true,message:'请输入申请单位',trigger:'blur'},
        enterpriseLegalName:{required:true,message:'请输入法定代表人',trigger:'blur'},
        enterpriseLegalType:{required:true,message:'请选择法人证件类型',trigger:'change'},
        enterpriseLegalIdcard:cardTypeValidate(null),
        enterpriseCardType:{required:true,message:'请选择证件类型',trigger:'change'},
        enterpriseCardNum:{required:true,message:'请输入证件号码',trigger:'blur'},
        enterpriseContactName:{required:true,message:'请输入联系人',trigger:'blur'},
        enterpriseContactMobile:[{required:true,message:'请输入联系人手机',trigger:'blur'},
          {validator:formRules.FormValidate.validatePhone,trigger:'blur'}
        ]
      },

      avueForm: {},
      newAvueForm: {},

      enterForm:{},// 企业申请
      personForm:{}, // 个人申请

      tableKey:1,
      uploading:false,
      fileUploadStatus:false,
      qrcode:null,
	    uploadKey:1,
      
    };
  },

  created() {
    this.getDataJson();
    this.taskId = this.$route.query.taskId;
    this.taskName = this.$route.query.taskName;
    this.getFillData();
  },
	mounted(){
    document.title=this.$route.query.taskName;
    if(sessionStorage.getItem('platform')==='自助机'){
      /**将方法暴露给 window 以便 webview 调用 */
      window.getGaoData = this.getGaoData;
      let isAgentInfo = JSON.parse(sessionStorage.getItem('isAgentInfo'))
      this.$set(this.baseInfoForm,'isAgent',isAgentInfo.type)

      this.$set(this.enterForm,'enterpriseContactMobile',isAgentInfo.phone);
      this.$set(this.enterForm,'enterpriseLegalType','111');
      this.$set(this.enterForm,'enterpriseLegalIdcard',isAgentInfo.idNumber);
      this.$set(this.enterForm,'enterpriseLegalName',isAgentInfo.name);

      this.$set(this.personForm,'personCardNum',isAgentInfo.idNumber);
      this.$set(this.personForm,'personCardType','111');
      this.$set(this.personForm,'personMobile',isAgentInfo.phone);
      this.$set(this.personForm,'personName',isAgentInfo.name);
      let gender = isAgentInfo.idNumber.charAt(16);

      this.$set(this.personForm,'personSex',gender%2===0?'女':'男')

      this.$set(this.personForm,'personBirth',this.getBirth(isAgentInfo.idNumber))
    }
  },
  computed:{
    /** 筛选出没有系统生成材料的材料列表 */
    filterMaterialList(){
      return this.tableData.filter(item=>{
        return item.tempFileIds===''
      })
    }
  },
  methods: {
    getBirth(idCard) {
      let birthday = idCard.slice(6, 14);
        //通过正则表达式来指定输出格式为:1990-01-01
      return birthday.replace(/(.{4})(.{2})/, "$1-$2-");
    },
	  /**
	   * 获取办件ID
	   */
    getFillData() {
			let cardInfo = JSON.parse(sessionStorage.getItem('isAgentInfo'));
      this.$post("/template/generateRecvId",{cardID:cardInfo.idNumber,name:cardInfo.name}).then(res => {
        if (res.data.recvId) {
          this.subjectRecvId = res.data.recvId;
        }else{
					this.$message.warning(res.data.msg);
	        this.$router.go(-1)
        }
      });
    },
    handleChange() {
      console.log(JSON.stringify(this.selfData));
    },
    getDataJson() {
      let loading = this.$loading({
        fullscreen:true,
        lock:true
      })
      this.$post(
        "/template/getTemplateByTaskId?taskId=" + this.$route.query.taskId
      ).then(res=>{
        if (res.data.code == 200) {
          this.selfData = res.data.taskFieldList; //动态表单数据
          if (res.data.taskFieldList && res.data.taskFieldList.length > 0) {
            this.setFormOption(res.data.taskFieldList);
          }
          res.data.materialTempList.forEach(ele =>{
            for(var i in ele.emptyFile){
              console.log(ele.emptyFile[i])
               var photoExt = ele.emptyFile[i].substr(ele.emptyFile[i].lastIndexOf(".")).toLowerCase();//获得文件后缀名
              if(ele.emptyFile[i] != '' && photoExt == '.pdf') {
                     ele.print = ''
              }
            }
            
          })
          console.log(res.data.materialTempList)
          this.tableData = res.data.materialTempList; //材料列表数据
          this.tableData.forEach(i => {
            i.fileIds = [];
          });
          loading.close();
        }else{
          loading.close();
        }
      }).catch(()=>{
        loading.close()
      })
      
    },
    goback() {
      this.$router.push("/dynamicList");
    },
    /**
     * 打开文件上传弹窗
     * @param id 材料id
     */ 
    printFile(id) {
      // let baseUrl = '';
      // if(process.env.NODE_ENV==='develop'){
      //   baseUrl = '/api';
      // }else {
      //   baseUrl = process.env.VUE_APP_BASE_URL;
      // }
      // let src = `${baseUrl}/acceptFile/readFile?fileId=${id}`;
      var src
      for(var i in id) {
        console.log(id[i])
        src = id[i];
      }
      open(src)
    },
    openUploadDialog(id) {
      this.uploadId = id;
      /* if(sessionStorage.getItem('platform')==='自助机'){
        
      } */
      // window.android.cameraGao(id);
      this.uploadDialogControl = true;
      this.$nextTick(()=>{
        if(this.qrcode){
          this.createQrcode(this.uploadId,true);
        }else{
          this.createQrcode(this.uploadId,false);
        }
				this.uploaderListening(this.uploadId);
      })
    },
	  uploaderListening(uploadId){
			if(uploadId){
				let params = {
					materialId:uploadId,
					recvId:this.subjectRecvId
				}
				this.$get('/WebCarCabinet/getFileId',{params},{cancelToken:source.token}).then(res=>{
					let {code,materialId,msg,fileJson,fileId} = res.data;
					if(code===200){
						if(fileId){
							this.fileUploadStatus=true;
							this.tableData.forEach(item=>{
								if(item.materialId===materialId){
									this.$set(item.fileIds,0,JSON.parse(fileJson))
								}
							})
							this.uploadKey++;
							setTimeout(()=>{
								this.uploadDialogControl = false;
							},2000)
						}else{
							setTimeout(()=>{
								this.uploaderListening(this.uploadId)
							},2000)
						}
					}
				})
			}
	  },
	  removePhoneUploadFile(materialId){
			this.tableData.forEach(item=>{
				if(item.materialId===materialId){
					item.fileIds = [];
				}
			})
		  this.uploadKey++;
			this.$message.warning('上传文件已移除，请重新上传');
	  },
    /**监听文件列表更改 */
    uploadOnChange(file, fileList) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = ["pdf", "doc", "docx", "jpg", "png", "jpeg", "gif"];
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.warning("上传文件只能是 pdf、doc、docx以及图片格式");
        // 强制更新dom
        this.uploadRefresh = false;
        this.$nextTick(() => {
          this.uploadRefresh = true;
        });
        return false;
      }
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.warning("文件大小不能超过10M");
        this.uploadRefresh = false;
        this.$nextTick(() => {
          this.uploadRefresh = true;
        });
        return false;
      }
      this.fileList = fileList;
    },
    removeUploadFile(file, fileList) {
      this.fileList = fileList;
    },
    /**
     * 确认上传材料
     */
    confirmFile() {
      if (this.fileList.length === 0) {
        this.$message.warning("请上传材料");
        return;
      }
      this.uploading = true;
      this.fileList.forEach(async (item, index) => {
        let formData = new FormData();
        formData.append("file", item.raw);
        formData.append("userId", "1234");
        await this.handleUploadFile(formData).then(res => {
          let { msg, code, data } = res.data;
          if (code === 200) {
            this.tableData.forEach(i => {
              if (i.materialId === this.uploadId) {
                i.fileIds.push(data);
              }
            });
            // 最后一次循环关闭弹窗，重置id和文件列表
            if (index === this.fileList.length - 1) {
              setTimeout(() => {
                this.$message.success(msg);
                this.tableKey++
                this.$nextTick(() => {
                  this.uploading=false;
                  this.uploadId = "";
                  this.uploadDialogControl = false;
                  this.fileList = [];
                });
              }, 100);
            }
          }else{
            this.$message.error('文件上传失败');
            this.uploading=false;
          }
        }).catch(()=>{
          this.uploading=false;
        })
      });
    },
    closeUploadDialog() {
      this.uploadId = "";
      this.fileList = [];
      this.fileUploadStatus=false;
      this.qrcode.clear();
			source.cancel('Operation canceled by the user.');
    },
    /**
     * 调用上传接口
     * @param formData
     */
    async handleUploadFile(formData) {
	    return await this.$post("/acceptFile/uploadFile", formData, {
	      headers: {"Content-Type": "multipart/form-data"}
      });
    },
    /**
     * 文件移除
     */
    removeFile(index, row) {
      row.splice(index, 1);
      this.tableKey++
    },
    /**
     * 预览文件
     * @param index 索引
     * @param row fileIds[]
     */
    viewFile(index, row) {
      let baseUrl = "";
      if (process.env.NODE_ENV === "develop") {
        baseUrl = "/api";
      } else {
        baseUrl = process.env.VUE_APP_BASE_URL;
      }
      // 对office文档执行下载
      if (
        row[index].fileType === "xlsx" ||
        row[index].fileType === "docx" ||
        row[index].fileType === "xls" ||
        row[index].fileType === "doc" ||
        row[index].fileType === "ppt" ||
        row[index].fileType === "pptx"
      ) {
        let ele = document.createElement("iframe");
        ele.src = `${baseUrl}/acceptFile/download?fileId=${row[index].fileId}`;
        ele.style.display = "none";
        ele.style.height = 0;
        document.body.appendChild(ele);
        setTimeout(() => {
          ele.remove();
        }, 60 * 1000);
      } else {
        // 反之可以预览
        window.open(
          `${baseUrl}/acceptFile/viewFile?fileId=${row[index].fileId}`,
          "文件预览",
          "_blank"
        );
      }
    },
    prepareSave(){
      // 判断是否提交全部材料
      let uploadedList = this.tableData.filter(item=>{
        return item.fileIds.length!=0;
      })
      if(this.filterMaterialList.length===uploadedList.length){
        this.baseInfoForm.isMaterialAll = 1;
      }else{
        this.baseInfoForm.isMaterialAll = 0;
      }


      if(this.baseInfoForm.servType===1){
        this.$refs.personForm.validate(flag=>{
          if(flag){
            this.handlebtn()
          } else {
            return this.$message.error("请将必填信息填写完整！");
          }
        })
      }
      if(this.baseInfoForm.servType===2){
        this.$refs.enterForm.validate(flag=>{
          if(flag){
            this.handlebtn()
          } else {
            return this.$message.error("请将必填信息填写完整！");
          }
      })
      }
    },
    //保存
    handlebtn() {
      if (this.avueOptions.length > 0) {
        let validateMark = true;
        for(let i=0;i<this.avueOptions.length;i++){
          this.$refs[this.avueOptions[i].name][0].validate(flag=>{
            if(!flag){
              validateMark=false;
            }
          })
        }
        if(validateMark){
          this.submitForm();
        }else{
          this.$message.error("请将必填信息填写完整！");
        }
      }else{
        this.submitForm()
      }
    },

    submitForm(){
      let allObject = {};
      allObject.materialData = [];
      this.tableData.forEach(item => {
        allObject.materialData.push({
          materialId: item.materialId,
          fileIds: item.fileIds
        });
      });
      allObject.taskId = this.$route.query.taskId;
      allObject.taskName = this.$route.query.taskName;

      if(!this.baseInfoForm.isAgent){
        this.baseInfoForm.isAgent = this.baseInfoForm.applyerName?1:0;
      }

      if(this.baseInfoForm.servType==1){
        allObject.recvData = {...this.baseInfoForm,...this.personForm};

        // 如果委托人没有填，那把个人信息赋值给委托人
        allObject.recvData.applyerCardCode = allObject.recvData.applyerCardCode?allObject.recvData.applyerCardCode:allObject.recvData.personCardNum

        allObject.recvData.applyerName = allObject.recvData.applyerName ? allObject.recvData.applyerName : allObject.recvData.personName;
      
        allObject.recvData.applyerMobile = allObject.recvData.applyerMobile ? allObject.recvData.applyerMobile : allObject.recvData.personMobile;


        allObject.recvData.applyerCardType = allObject.recvData.applyerCardType ? allObject.recvData.applyerCardType : allObject.recvData.personCardType;
      }else{
        allObject.recvData = {...this.baseInfoForm,...this.enterForm};
        // 如果委托人没有填，那把企业信息赋值给委托人
        allObject.recvData.applyerCardCode = allObject.recvData.applyerCardCode?allObject.recvData.applyerCardCode:allObject.recvData.enterpriseCardNum

        allObject.recvData.applyerName = allObject.recvData.applyerName ? allObject.recvData.applyerName : allObject.recvData.enterpriseLegalName;
      
        allObject.recvData.applyerMobile = allObject.recvData.applyerMobile ? allObject.recvData.applyerMobile : allObject.recvData.enterpriseContactMobile;


        allObject.recvData.applyerCardType = allObject.recvData.applyerCardType ? allObject.recvData.applyerCardType : allObject.recvData.enterpriseCardType;
      }
      allObject.fillData = this.newAvueForm; //动态表单数据
      allObject.materialData = this.tableData; //材料数据
      allObject.recvId = this.subjectRecvId;
      for (let i in this.baseInfoForm) {
        if (i.indexOf("$") > -1) {
          delete this.baseInfoForm[i];
        }
      }
      this.showLoading = true;
      this.$once('hook:beforeDestroy',()=>{
        this.showLoading = false;
      })
      this.$postForJson(
        "/template/saveFillRecvToRedis",
        allObject
      ).then(res => {
        let { code, msg } = res.data;
        if (code === 200) {
          this.$message.success(msg);
          setTimeout(() => {
            this.$router.push({
              path: "/dynamicSubmit",
              query: { recvId: this.subjectRecvId,taskId:this.taskId }
            });
          }, 1000);
        } else {
          this.$message.error(msg);
					this.showLoading=false;
        }
      }).catch(()=>{
        this.showLoading=false;
      })
    },
    /** 渲染表单 */
    setFormOption(list) {
      list.forEach(i => {
        let option = {
          size: "small",
          labelWidth: 220,
          submitBtn: false,
          emptyBtn: false,
          menuBtn: false,
          column: []
        };
        i.bmgTaskFieldList.forEach(item => {
          if(item.fieldType===2){
            this.$set(this.avueForm,item.fieldCode,[])
          }
          let obj = {};
          obj.label = item.fieldName;
          obj.prop = item.fieldCode;
          let fieldType = this.getFieldType(item.fieldType);
          // 设置验证规则
          obj.rules = [
            {
              required: item.isRequire===1,
              trigger: fieldType.trigger,
              message: `请${fieldType.trigger === "change" ? "选择" : "输入"}${
                item.fieldName
              }`
            }
          ];
          obj.type = fieldType.type;
          // 判断是否有选择项
          if (item.optionValues && item.optionValues.length > 0) {
            obj.dicData = [];
            item.optionValues.forEach(j => {
              let obj2 = {};
              obj2.value = j.optionValue;
              obj2.label = j.optionName;
              obj2.optionSort = j.optionSort;
              obj.dicData.push(obj2);
            });
          }
          option.column.push(obj);
        });
        let _obj = {
          name: i.category,
          form: option
        };
        this.avueOptions.push(_obj);
      });
    },
    /**根据 fieldType 返回 avue 需要的表单类型 */
    getFieldType(t) {
      let obj = {};
      if (t === 1) {
        obj.type = "radio";
        obj.trigger = "change";
      } else if (t === 2) {
        obj.type = "checkbox";
        obj.trigger = "change";
      } else if (t === 3) {
        obj.type = "input";
        obj.trigger = "blur";
      } else if (t === 4) {
        obj.type = "textarea";
        obj.trigger = "blur";
      }
      return obj;
    },
    cardTypeChange(val,key){
      this.formRules[key] = cardTypeValidate(val);
    },
    
    /**
     * 获取高拍仪的返回数据
     */
    getGaoData(data){
      this.tableData.forEach(i => {
        if (i.materialId === this.uploadId) {
          i.fileIds.push(data.data);
        }
      });
      setTimeout(()=>{
        this.tableKey++
        this.uploadId = "";
      })
    },
    /**
     * 创建二维码
     */
    createQrcode(materialId,instence){
			let str = `${window.location.protocol}//${window.location.host}/phoneUpload?materialId=${materialId}&subjectRecvId=${this.subjectRecvId}&materialName=${this.tableData.find(item=>item.materialId===materialId).materialName}`;
			console.log(str)
			if(instence){
				this.qrcode.makeCode(str)
			}else{
				this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
					text: str,
					width: 200,
					height: 200,
					colorDark: '#000000',
					colorLight: '#ffffff',
					correctLevel: QRCode.CorrectLevel.H
				})
			}
			// window.open(`${window.location.protocol}//${window.location.host}/phoneUpload?materialId=${materialId}&subjectRecvId=${this.subjectRecvId}&materialName=${this.tableData.find(item=>item.materialId===materialId).materialName}`,'blank')

    },
    /**
     * 监听动态表单
     * @param value
     * @param column
     */
    dynamicFormChange(value, column){
      if(value && value.length>0){
        if(column.dicData){
          let arr = [];
          if(Object.prototype.toString.call(value)==='[object String]'){
            column.dicData.forEach(item=>{
              if(value === item.value){
                arr.push({label:item.label,value:1,optionSort:item.optionSort})
              }else{
                arr.push({label:item.label,value:0,optionSort:item.optionSort})
              }
            })
          }else if(Object.prototype.toString.call(value)==='[object Array]'){
            let dicData = JSON.parse(JSON.stringify(column.dicData));
            value.forEach(val=>{
              dicData.forEach((item,index)=>{
                if(val === item.label){
                  item.value = 1
                }
              })
            })
            arr = dicData;
          }
          this.$set(this.newAvueForm,column.prop,arr);
        }else{
          this.$set(this.newAvueForm,column.prop,value);
        }
      }else{
        this.newAvueForm[column.prop]?delete this.newAvueForm[column.prop]:null
      }
    }
  },
	beforeDestroy() {
		source.cancel()
	}
};
</script>

<style lang="less" scoped>
@themeColor:#2F56FF;
.top {
  width: 100%;
  height: 119px;
  background: @themeColor;
  line-height: 119px;
  .title {
    color: #fff;
    font-size: 32px;
    line-height: 119px;
    margin-left: 10px;
  }
}
.title-top {
  font-size: 32px;
  text-align: center;
}
.recv-id{
	float:right;line-height:33px;
	font-size: 16px;
}
.titleStyle {
  font-weight: bold;
  float: left;
}
.el-input--small .el-input__inner {
  width: 50%;
}
.item-dync {
  width: 50%;
  float: left;
}
.saveBtn {
  width: 300px;
  height: 40px;
  font-size: 22px;
  margin: 20px auto 140px;
  text-align: center;
}
.fillSpan {
  font-size: 20px;
}
.fillSpan-icon {
  color: @themeColor;
  font-weight: bold;
  margin-right: 15px;
}
.el-icon-arrow-left {
  padding: 10px;
  cursor: pointer;
}
.style-col {
  margin: 0 auto;
}
.form-area{
  width: 1100px;
  margin: auto;
}
.card-items{
  margin-bottom:48px;
}
.file-name{
  cursor: pointer;
  &:hover{
    color:@themeColor;
  }
}
.qrcode{
  display: flex;
  justify-content: center;
  &::v-deep img {
    width: 200px;
    height: 200px;
    background-color: #fff; //设置白色背景色
    padding: 6px; // 利用padding的特性，挤出白边
    box-sizing: border-box;
  }
}
.backIcon{
	font-size: 22px;
}
.wechat-upload{
	text-align: center;
}
</style>
