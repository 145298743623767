import Vue from 'vue';
import VueRouter from 'vue-router';

import NProgress from 'nprogress';

Vue.use(VueRouter)

import index from '@/views/index.vue'

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    meta: { title: '首页' },
    component: index
  },
  {
    path: '/mainIndex',
    name: 'mainIndex',
    component: () => import('@/views/mainIndex.vue')
  },
  {
    //视频通话
    path: '/communicate',
    name: 'communicate',
    component: () => import('@/views/communicate.vue')
  },
  {
    //办件1身份识别方式
    path: '/doPiece',
    name: 'doPiece',
    component: () => import('@/views/doPiece.vue')
  },
  {
    //办件2身份证扫描
    path: '/identityCard',
    name: 'identityCard',
    component: () => import('@/views/identityCard.vue')
  },
  {
    //取件1取件码页面
    path: '/collectAparcel',
    name: 'collectAparcel',
    component: () => import('@/views/collectAparcel.vue')
  },
  {
    //取件2柜门页面
    path: '/cabinetDoor',
    name: 'cabinetDoor',
    component: () => import('@/views/cabinetDoor.vue')
  },
  {
    //存件1密码登录
    path: '/doSavea',
    name: 'doSavea',
    component: () => import('@/views/doSavea.vue')
  },
  {
    //存件2存件方式
    path: '/parcel',
    name: 'parcel',
    component: () => import('@/views/parcel.vue')
  },
  {
    //存件3选择柜格
    path: '/bergsonism',
    name: 'bergsonism',
    component: () => import('@/views/bergsonism.vue')
  },
  {
    //存件4柜门已开
    path: '/cabinetDoorOpen',
    name: 'cabinetDoorOpen',
    component: () => import('@/views/cabinetDoorOpen.vue')
  },
  // ======== 智能填报 👇 ==========
  {
    // 展示列表 
    path: '/dynamicList',
    name: 'dynamicList',
    meta: { title: '列表' },
    component: () => import('@/views/dynamicList.vue')
  },
  {
    // 展示列表2 
    path: '/dynamicListTo',
    name: 'dynamicListTo',
    meta: { title: '列表' },
    component: () => import('@/views/dynamicListTo.vue')
  },
  {
    // 动态表单
    path: '/dynamicForm',
    name: 'dynamicForm',
    component: () => import('@/views/dynamicForm.vue')
  },
  { // mobile
    path: '/mattersList',
    name: 'mattersList',
    meta: { title: '事项列表' },
    component: () => import('@/IntelligentReporting/mattersList.vue')
  },
  { // 事项填报 mobile
    path: '/formFill',
    name: 'formFill',
    component: () => import('@/IntelligentReporting/formFill.vue')
  },
  { // 材料确认 mobile
    path: '/materialConfirm',
    name: 'materialConfirm',
    component: () => import('@/IntelligentReporting/materialConfirm.vue')
  },
  {
    // 提交事项 mobile
    path: '/reportSubmit',
    name: 'reportSubmit',
    component: () => import('@/IntelligentReporting/reportSubmit.vue')
  },
  { // 提交事项 pc
    path: '/dynamicSubmit',
    name: 'dynamicSubmit',
    component: () => import('@/views/dynamicSubmit.vue')
  },
  {
    path:'/phoneUpload',
    name:'phoneUpload',
    component: ()=>import('@/IntelligentReporting/phoneUpload.vue')
  },
  // ===== 智能填报 👆 ======
  {
    path: '*',
    component: () => import('@/components/notFound.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, form, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  document.title = to.meta.title?to.meta.title:'无人车';
  next()
})

router.afterEach(() => {
  NProgress.done()
})

export default router
