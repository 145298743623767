// 即时通讯基础能力库 IMLib
import * as RongIMLib from "@rongcloud/imlib-next";

// 实时音视频基础能力库 RTCLib
import { installer as rtcInstaller, RCRTCClient, RCFrameRate } from "@rongcloud/plugin-rtc";

// 实时音视频通话能力库 CallLib
import { installer as callInstaller, RCCallClient, RCCallSession, RCCallErrorCode } from "@rongcloud/plugin-call";

import Rongyun from './RongYun.js'

/**
 * @type {RCCallSession}
 */
let callSession;

/**
 * CallSession 事件
 */
 const getCallSessionEvent = (element) => {
    return {
        onRinging: (sender) => {
            console.log(`收到 ${sender.userId} 振铃`);
        },
        onAccept: (sender) => {
            console.log(`${sender.userId} 已接听`);
        },
        onHungup: (sender) => {
            console.log(`${sender.userId} 已挂断`);
            // 群组中移除相应节点
        },
        onTrackReady: (track) => {
            // track.isLocalTrack() 是否为本地资源
            // track.isAudioTrack() 是否为音频
            // track.isVideoTrack() 是否为视频
            // track.getUserId()    产生该 track 的用户id

            // 远程的音频直接播放, 为了减少回音，可不播放本端音频
            if(!track.isLocalTrack()){
                if(track.isAudioTrack()){
                    track.play()
                }
            }

            // 视频在对应的容器里播放
            if (track.isVideoTrack()) {
                /**
                 * @type {HTMLVideoElement}
                 */
                let node = document.createElement('video');
                node.setAttribute('id', `${track.getUserId()}_RongCloudRTC_1`);
                node.setAttribute('autoplay', 'autoplay');
                node.setAttribute('playsinline', 'playsinline');
                node.setAttribute('width', '100%');
                node.setAttribute('height', '100%');
                if(track.getUserId()===Rongyun.TOKEN.userId){
                    document.getElementById('meFrames').appendChild(node);
                }else{
                    document.getElementById('he-frames').appendChild(node);
                }
                track.play(document.getElementById(`${track.getUserId()}_RongCloudRTC_1`));
            }
        },
        onMemberModify: (sender) => {},
        onMediaModify: (sender) => {},
        onAudioMuteChange: (muteUser) => {},
        onVideoMuteChange: (muteUser) => {}
    }
}


/**
 * callSession 事件注册
 */
const registerCallSessionEvent = (session) => {
    const events = getCallSessionEvent()
    session.registerSessionListener(events)
}


/**
 * 初始化IM客户端
 */
RongIMLib.init({
    appkey: Rongyun.KEY,
});


/**
 * 连接IM
 * @param userToken {string}
 */
 function imConnect(userToken){
    return RongIMLib.connect(userToken)
}


/**
 * RTC 客户端初始化
 * @type {RCRTCClient}
 */
const rtcClient = RongIMLib.installPlugin(rtcInstaller,{
    timeout:30*1000
})

/**
 * CallLib 客户端初始化
 * @type {RCCallClient}
 */
const rtcCaller = RongIMLib.installPlugin(callInstaller, {

    /**
     * rtcClient 实例 （必填）
     */
    rtcClient,
    /**
     * 被动收到邀请 （收到一个远端发起的新会话）, 会产生一个新的 session 对象 （必填）
     * @param session
     */
    onSession(session) {
        callSession = session;
        registerCallSessionEvent(callSession);
    },

    /**
     *  以下三条只要满足一条，就会触发onSessionClose
     *  1、本端用户自己主动挂断
     *  2、服务端把本端用户踢出 RTC 房间
     *  3、房间里小于2个人
     *  @param {RCCallSession} session 被结束的 session 对象
     *  @param summaryInfo 结束一个 session 的后汇总信息
     */
    onSessionClose(session, summaryInfo) {

    },
});


/**
 * 发起通话
 * @param {string} targetId 被呼叫一方的用户 id   必填
 * @param {1|2} mediaType 1->音频呼叫 or 2->音视频呼叫  必填
 * @param {string} element 视频容器ID
 * @return {Promise<void>}
 */
function lunchSingleCall(targetId,mediaType, element) {
    return new Promise((resolve, reject) => {
        rtcCaller.call({
            targetId,
            mediaType,
            listener:getCallSessionEvent(element),
            constraints:{
                video:{
                    frameRate:RCFrameRate.FPS_30
                }
            }
        }).then(res=>{
            if(res.code===RCCallErrorCode.SUCCESS){
                registerCallSessionEvent(res.session);
                callSession = res.session;
                resolve({code:'success',targetId})
            }else{
                resolve({code:res.code,targetId})
            }
        }).catch(err=>{
            reject(err)
        })
    })

}


/**
 * 接听通话
 */
function accept(){
    callSession.accept().then(({code})=>{
        if(code===RCCallErrorCode.SUCCESS){
            console.log('接听成功');
        }else{
            console.error(`呼叫失败，原因：${code}`)
        }
    })
}

/**
 * 挂断当前 callSession
 */
const hungup = () => {
    return new Promise((resolve, reject) => {
        callSession.hungup().then(({code})=>{
            if(code===RCCallErrorCode.SUCCESS){
                resolve({code:'success'})
            }else{
                reject({code:'error'})
            }
        }).catch(err=>{
            reject(err)
        })
    })

}


export {imConnect, lunchSingleCall,hungup,accept}
