var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.data, function(item, index) {
        return _c(
          "van-form",
          {
            key: index,
            ref: "vanForm_" + item.category,
            refInFor: true,
            staticClass: "form-card",
            attrs: {
              "label-width": "120px",
              "show-error": false,
              "validate-first": ""
            }
          },
          [
            _c("div", { staticClass: "form-card-content" }, [
              _c("img", {
                staticClass: "form-card-icon",
                attrs: { src: require("../assets/icon_2.png"), alt: "" }
              }),
              _c("span", { staticClass: "form-card-span" }, [
                _vm._v(_vm._s(item.category))
              ])
            ]),
            _vm._l(item.bmgTaskFieldList, function(i, index2) {
              return _c(
                "div",
                { key: index2, staticClass: "form-content-item" },
                [
                  i.fieldType === 3
                    ? _c("van-field", {
                        staticClass: "van-field-c",
                        attrs: {
                          name: i.fieldCode,
                          label: i.fieldName,
                          placeholder: "请输入" + i.fieldName,
                          rules: _vm.formRules[i.fieldCode],
                          required: i.isRequire === 1
                        },
                        on: {
                          input: function($event) {
                            return _vm.inputChange($event, i.fieldCode)
                          }
                        },
                        model: {
                          value: _vm.formObj[i.fieldCode],
                          callback: function($$v) {
                            _vm.$set(_vm.formObj, i.fieldCode, $$v)
                          },
                          expression: "formObj[i.fieldCode]"
                        }
                      })
                    : i.fieldType === 2
                    ? _c("van-field", {
                        staticClass: "van-field-c",
                        attrs: {
                          name: i.fieldCode,
                          label: i.fieldName,
                          rules: _vm.formRules[i.fieldCode],
                          required: i.isRequire === 1
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function() {
                                return [
                                  _c(
                                    "van-checkbox-group",
                                    {
                                      attrs: { direction: "horizontal" },
                                      on: {
                                        change: function($event) {
                                          return _vm.checkboxChange(
                                            $event,
                                            i.optionValues,
                                            i.fieldCode
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.formObj[i.fieldCode],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formObj,
                                            i.fieldCode,
                                            $$v
                                          )
                                        },
                                        expression: "formObj[i.fieldCode]"
                                      }
                                    },
                                    _vm._l(i.optionValues, function(i2) {
                                      return _c(
                                        "van-checkbox",
                                        {
                                          key: i2.optionValue,
                                          attrs: {
                                            name: i2.optionValue,
                                            shape: "square"
                                          }
                                        },
                                        [_vm._v(_vm._s(i2.optionName))]
                                      )
                                    }),
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : i.fieldType === 1
                    ? _c("van-field", {
                        staticClass: "van-field-c",
                        attrs: {
                          name: i.fieldCode,
                          label: i.fieldName,
                          rules: _vm.formRules[i.fieldCode],
                          required: i.isRequire === 1
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "input",
                              fn: function() {
                                return [
                                  _c(
                                    "van-radio-group",
                                    {
                                      attrs: { direction: "horizontal" },
                                      on: {
                                        change: function($event) {
                                          return _vm.radioChange(
                                            $event,
                                            i.optionValues,
                                            i.fieldCode
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.formObj[i.fieldCode],
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.formObj,
                                            i.fieldCode,
                                            $$v
                                          )
                                        },
                                        expression: "formObj[i.fieldCode]"
                                      }
                                    },
                                    _vm._l(i.optionValues, function(i2) {
                                      return _c(
                                        "van-radio",
                                        {
                                          key: i2.optionValue,
                                          attrs: { name: i2.optionValue },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "icon",
                                                fn: function(p) {
                                                  return [
                                                    _c("custom-radio", {
                                                      attrs: {
                                                        checked: p.checked
                                                      }
                                                    })
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          )
                                        },
                                        [_vm._v(_vm._s(i2.optionName) + " ")]
                                      )
                                    }),
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    : i.fieldType === 5
                    ? _c("van-field", {
                        staticClass: "van-field-c",
                        attrs: {
                          name: i.fieldCode,
                          readonly: "",
                          clickable: "",
                          label: i.fieldName,
                          placeholder: ""
                        },
                        on: {
                          click: function($event) {
                            return _vm.selectClick(i)
                          }
                        },
                        model: {
                          value: _vm.formObj[i.fieldCode],
                          callback: function($$v) {
                            _vm.$set(_vm.formObj, i.fieldCode, $$v)
                          },
                          expression: "formObj[i.fieldCode]"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            })
          ],
          2
        )
      }),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showPicker,
            callback: function($$v) {
              _vm.showPicker = $$v
            },
            expression: "showPicker"
          }
        },
        [
          _c("van-picker", {
            attrs: {
              "show-toolbar": "",
              columns: _vm.pickerOptions,
              "value-key": "optionName"
            },
            on: {
              confirm: function($event) {
                return _vm.onConfirm($event)
              },
              cancel: _vm.onCancel
            }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }