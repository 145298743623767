var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _vm._v(" 技术支持：北京新广视通科技集团有限责任公司 ")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }