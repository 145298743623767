<template>
<div class="phone-upload">
	<p class="phone-upload-title">{{materialName}}</p>
	<div class="phone-upload-tab">
		<div class="phone-upload-tabs" v-for="item in tabList" :key="item.id" :class="{'active-class':tabIndex===item.id}" @click="()=>{tabIndex=item.id}">{{item.title}}</div>
	</div>
	<div class="upload-content" v-if="tabIndex===1">
		<vanUploader v-model="fileList1" :after-read="afterRead" max-count="1">
			<div class="uploader-icon-p"></div>
		</vanUploader>
	</div>
	<div class="upload-content" v-if="tabIndex===2">
		<vanUploader v-model="fileList2" :after-read="afterRead" capture="camera" max-count="1">
			<div class="uploader-icon-c"></div>
		</vanUploader>
	</div>
	<div class="submit-button" @click="submitHandler">
		上传至无人车
	</div>
</div>
</template>

<script>
import {setRem} from "@/utils/commonFunc";
import {Uploader,Toast} from 'vant'
export default {
	name: "phoneUpload",
	components:{vanUploader:Uploader},
	data(){
		return {
			tabIndex:1,
			tabList:[
				{id:1,title:'图片上传'},
				{id:2,title:'拍照上传'}
			],
			fileList1:[],
			fileList2:[],
			materialName:'',
			fileIdByPic:null,
			fileIdByCam:null
		}
	},
	computed:{
		capture(){
			return this.tabIndex===2;
		}
	},
	created() {
		this.materialName = this.$route.query.materialName
	},
	mounted() {
		window.onresize = () => {
			return (() => {
				setRem(document)
			})();
		}
		this.$nextTick(()=>{
			setRem(document);
		})
	},
	methods:{
		afterRead(file){
			let fileList = this.tabIndex===1?'fileList1':'fileList2'
			this[fileList][0].status='uploading';
			this[fileList][0].message = '上传中';
			let formData = new FormData();
			formData.append('file',file.file);
			formData.append('userId','2037');
			this.$post('/acceptFile/uploadFile',formData,{headers:{'Content-Type':'multipart/form-data'}}).then(res=>{
				let {msg,code,data} = res.data;
				if(code===200){
					this.tabIndex===1?this.fileIdByPic=data:this.fileIdByCam=data;
					this[fileList][0].status='done';
					this[fileList][0].message = '';
				}else{
					this[fileList][0].status='failed';
					this[fileList][0].message = '上传失败';
				}
			})
		},
		submitHandler(){
			let fileId = this.tabIndex===1?this.fileIdByPic:this.fileIdByCam;
			if(fileId){
				this.$postForJson('/WebCarCabinet/getOutFileIdAndMaterialId',{
					fileJson:fileId,
					fileId:fileId.fileId,
					materialId:this.$route.query.materialId,
					recvId:this.$route.query.subjectRecvId
				}).then(res=>{
					let {code,msg} = res.data;
					if(code===200){
						Toast.success('上传成功')
					}else{
						Toast(msg)
					}
				})
			}else{
				Toast('请先上传文件')
			}
		}
	}
}
</script>

<style lang="less" scoped>
.phone-upload{
	height: 100vh;
	background-image: url("../assets/unmanned_vehicle/bg.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-color: #F6F6F6;
	.phone-upload-title{
		text-align: center;
		padding: 0.48rem 0;
		margin: 0;
		color: #fff;
		font-size: 0.38rem;
		font-weight: bold;
	}
	.phone-upload-tab{
		margin: 0 0.28rem;
		height: 0.96rem;
		display: flex;
		justify-content: space-between;
		border-radius: 0.1rem 0.1rem 0 0;
		overflow:hidden;
		.active-class{
			background-color: #fff !important;
		}
		.phone-upload-tabs{
			width: 50%;
			background-color: #EDEDED;
			font-size: 0.34rem;
			line-height: 0.96rem;
			text-align: center;
			color: #404D5B;
		}
	}
	.upload-content{
		margin: 0 0.28rem;
		background-color: #fff;
		height: 4.8rem;
		padding: 0.32rem;
		border-radius: 0 0 0.1rem 0.1rem;
	}
	.submit-button{
		width: 6.94rem;
		height: .88rem;
		background: #2F56FF;
		border-radius: .1rem;
		margin: 0.56rem  auto;
		font-size: 0.3rem;
		text-align: center;
		line-height: 0.88rem;
		color: #fff;
		font-weight: 500;
		cursor: pointer;
	}
	.uploader-icon-p{
		width:1.94rem;
		height: 1.94rem;
		background-image:url('../assets/unmanned_vehicle/pic.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size:100% 100%;
		border:1px dashed #d3d3d3;
		border-radius: 10px;
	}
	.uploader-icon-c{
		width:1.94rem;
		height: 1.94rem;
		background-image:url('../assets/unmanned_vehicle/photo.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size:100% 100%;
		border:1px dashed #d3d3d3;
		border-radius: 10px;
	}
}
</style>