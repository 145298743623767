var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "matters-list" },
    [
      _c("div", { staticClass: "search-area" }, [
        _c(
          "form",
          { attrs: { action: "/" } },
          [
            _c("van-search", {
              staticClass: "van-search-c",
              attrs: { placeholder: "请输入搜索关键词" },
              on: { search: _vm.onSearch, clear: _vm.onClear },
              scopedSlots: _vm._u([
                {
                  key: "left-icon",
                  fn: function() {
                    return [
                      _c("van-icon", {
                        attrs: { name: "search", color: "#fff" }
                      })
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.searchForm,
                callback: function($$v) {
                  _vm.searchForm = $$v
                },
                expression: "searchForm"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "van-list",
        {
          attrs: {
            finished: _vm.listFinished,
            "finished-text": "没有更多了",
            error: _vm.listError,
            "error-text": "请求失败，点击重新加载"
          },
          on: {
            "update:error": function($event) {
              _vm.listError = $event
            },
            load: function($event) {
              return _vm.getTaskList()
            }
          },
          model: {
            value: _vm.listLoading,
            callback: function($$v) {
              _vm.listLoading = $$v
            },
            expression: "listLoading"
          }
        },
        [
          _c(
            "van-row",
            { attrs: { type: "flex", gutter: "8" } },
            _vm._l(_vm.listData, function(item) {
              return _c(
                "van-col",
                { key: item.taskId, attrs: { span: "12" } },
                [
                  _c("div", { staticClass: "themes-list-item" }, [
                    _c(
                      "div",
                      {
                        staticClass: "themes-list-item-link",
                        attrs: { title: item.taskName },
                        on: {
                          click: function($event) {
                            return _vm.goToFill(item)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "themes-names" }, [
                          _vm._v(_vm._s(item.taskName))
                        ]),
                        _c("div", { staticClass: "right-arraw" })
                      ]
                    )
                  ])
                ]
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }