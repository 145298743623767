var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "material-comfirm" },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "form-card" },
        [
          _vm._m(1),
          _vm._l(_vm.materialList, function(item) {
            return _c("van-cell", {
              key: item.materialId,
              staticClass: "van-field-c",
              attrs: {
                title: item.materialName,
                value: "点击查看",
                "is-link": ""
              },
              on: {
                click: function($event) {
                  return _vm.cellClick(item.fileIds)
                }
              }
            })
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "esign-area form-card" },
        [
          _c("span", { staticClass: "esign-desc" }, [
            _vm._v("我承诺所有申报信息真实有效，自愿承担法律责任。请确认签字：")
          ]),
          _c("vue-esign", {
            ref: "esign",
            staticStyle: { "border-bottom": "1px solid #F2F2F2" },
            attrs: { isCrop: true, height: 200 }
          }),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "flex-direction": "row-reverse",
                "margin-top": "10px"
              }
            },
            [
              _c(
                "van-button",
                {
                  staticClass: "reset-button",
                  attrs: { type: "info", size: "small", color: "#6F8DE6" },
                  on: { click: _vm.resetEsign }
                },
                [_vm._v("重 置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "submit-button-c",
          on: {
            click: function($event) {
              return _vm.handleConfirm()
            }
          }
        },
        [_vm._v(" 确 认 ")]
      ),
      _c("van-overlay", { attrs: { show: _vm.showOverlay } }, [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex",
              "align-items": "center",
              "justify-content": "center",
              height: "100%"
            }
          },
          [
            _c("van-loading", { attrs: { type: "spinner", vertical: "" } }, [
              _vm._v("提交中，请稍后...")
            ])
          ],
          1
        )
      ]),
      _c(
        "van-overlay",
        {
          attrs: { show: _vm.showPdf },
          on: {
            click: function($event) {
              return _vm.closePdfOverlay()
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-end",
                height: "8%",
                "align-items": "center",
                "padding-right": "0.2rem"
              }
            },
            [
              _c("van-icon", {
                attrs: { name: "clear", color: "#fff", size: "0.5rem" },
                on: {
                  click: function($event) {
                    return _vm.closePdfOverlay()
                  }
                }
              })
            ],
            1
          ),
          _c("iframe", {
            attrs: {
              src: _vm.pdfSrc,
              frameborder: "0",
              width: "100%",
              height: "92%"
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "submit-status" }, [
      _c("div", { staticClass: "status-icon" }),
      _c("p", { staticClass: "status-text" }, [
        _vm._v("核验通过，请您确认以下申报材料")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-card-content" }, [
      _c("img", {
        staticClass: "form-card-icon",
        attrs: { src: require("./assets/icon_4.png"), alt: "" }
      }),
      _c("span", { staticClass: "form-card-span" }, [_vm._v(" 材料清单 ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }