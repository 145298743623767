var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bergsonism" }, [
    _c("div", { staticClass: "boxHeader" }, [
      _c("div", { staticClass: "boxHeader-left" }, [
        _c("div", { on: { click: _vm.black } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }),
          _vm._v("返回")
        ])
      ]),
      _c("div", { staticClass: "boxHeader-center" }, [
        _vm._v("请选择箱格大小")
      ]),
      _c("div", { staticClass: "boxHeader-right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/handle_countdown.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("倒计时：" + _vm._s(_vm.authTime) + "s")])
      ])
    ]),
    _c(
      "div",
      { staticClass: "boxContent" },
      [
        _c(
          "el-form",
          {
            staticClass: "demo-ruleForm",
            attrs: { model: _vm.ruleForm, "label-width": "150px" }
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "收件人信息" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.name,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "name", $$v)
                    },
                    expression: "ruleForm.name"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "办件编号" } },
              [
                _c("el-input", {
                  attrs: { disabled: "" },
                  model: {
                    value: _vm.ruleForm.reacId,
                    callback: function($$v) {
                      _vm.$set(_vm.ruleForm, "reacId", $$v)
                    },
                    expression: "ruleForm.reacId"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "ul",
          { staticClass: "geZi" },
          _vm._l(_vm.arrData, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                on: {
                  click: function($event) {
                    return _vm.getBox(index)
                  }
                }
              },
              [
                _c("img", {
                  attrs: {
                    src: index == _vm.ins ? item.imgUrlAll : item.imgUrl,
                    alt: ""
                  }
                })
              ]
            )
          }),
          0
        ),
        _c("p", { staticClass: "tiShi" }, [
          _vm._v("注：数值代表 长*宽*深，单位为毫米（mm）")
        ]),
        _c(
          "div",
          {
            staticClass: "btn",
            on: {
              click: function($event) {
                return _vm.submitForm()
              }
            }
          },
          [_vm._v("下一步")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }