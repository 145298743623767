  <template>
  <div class="container">
    <div class="home-content-conduct">
      <div class="home-content-conduct-title">
        <i class="el-icon-arrow-left back-btn" @click="goback">返回</i>
        <p class="title">事项列表</p>
        <div class="inpDiv">
          <el-input
            placeholder="搜索您要申报的主题"
            class="search-input"
            v-model="formInline.user"
            @keyup.native.enter="queryBtn()"
          >
            <i
              slot="suffix"
              class="el-input__icon el-icon-search"
              style="cursor:pointer"
              title="搜索"
              @click="queryBtn()"
            ></i>
          </el-input>
        </div>
        <!-- <i class="el-icon-s-operation" style="width:180px;float:right" @click="sjList()">市级列表</i> -->
        <el-button type="primary" icon="el-icon-s-operation"  style="width:180px;float:right" @click="sjList()">市级列表</el-button>
      </div>
      <div class="loading-area">
        <el-row :gutter="27" v-if="tableData.length>0">
          <el-col :span="6" v-for="item in tableData" :key="item.taskId" class="conduct-item">
            <div class="home-content-conduct-item">
              <div class="item-title-area" :title="item.taskName">
                <p class="conduct-item-title">{{item.taskName}}</p>
              </div>
              <div class="conduct-item-button-area">
                <div class="conduct-item-button" title="申报" @click="gotoDynamicForm(item)">申 报</div>
              </div>
            </div>
          </el-col>
        </el-row>
        <p v-else class="empty-placeholder">暂无数据</p>
      </div>
      <div class="home-content-conduct-page">
        <el-button
          class="conduct-page-button"
          title="上一页"
          :disabled="prevBtnDis"
          plain
          @click="handelPrevPage()"
        >上一页</el-button>
        <el-pagination
          ref="conductPage"
          background
          layout
          :total="total"
          :page-size="pageSize"
          :current-page="currentPage"
          @current-change="currentChange"
        />
        <el-button
          class="conduct-page-button"
          title="下一页"
          :disabled="nextBtnDis"
          plain
          @click="handelNextPage()"
        >下一页</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "dynamicList",
  data() {
	  return {
		  tableData: [],
		  formInline: {
			  user: ""
		  },
		  currentPage: 1,
		  total: 0,
		  pageSize: 12,
		  platform: sessionStorage.getItem('platform'),
		  /* 221用的区域id */
			areaId:'110109000000'
		  /* 10用 */
		   // areaId:'110115403000'
	  }
  },
  created() {
    this.getDataList();
  },
	computed: {
    /**控制上一页按钮的可用状态 */
    prevBtnDis() {
      return this.currentPage === 1;
    },
    /**控制下一页按钮的可用状态 */
    nextBtnDis() {
      let totalPage = Math.ceil(this.total / this.pageSize);
      return totalPage === this.currentPage || totalPage === 0;
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDataList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getDataList();
    },
    async getDataList() {
      const res = await this.$post(
        `/template/getTaskList?areaId=${this.areaId}&pageNum=${this.currentPage}&pageSize=${this.pageSize}&taskName=${this.formInline.user}`
      );
      if (res.data.code === 200) {
        this.tableData = res.data.list;
        this.total = res.data.totalSize;
      }
    },
   
    queryBtn() {
      this.getDataList();
    },
    gotoDynamicForm(id) {
      this.$router.push({
        name: "dynamicForm",
        query: { taskId: id.taskId, taskName: id.taskName }
      });
    },
    /**分页切换 */
    currentChange(v) {
      this.currentPage = v;
      this.getDataList();
    },

    /**点击上一页按钮 */
    handelPrevPage() {
      this.$refs.conductPage.prev();
    },

    /**点击下一页按钮 */
    handelNextPage() {
      this.$refs.conductPage.next();
    },



    goback(){
      this.$router.replace({path:'/mainIndex'})
    },
    sjList(){
      this.$router.replace({path:'/dynamicListTo'})
    }
  }
};
</script>

<style lang="less" scoped>
@baseFont: #333;
@baseBack: #2F56FF;
// .container{
//     width: 80%;
//     margin: 0 auto;
// }
.top {
  width: 100%;
  height: 119px;
  background: @baseBack;
  line-height: 119px;
  .title {
    color: #fff;
    font-size: 32px;
    line-height: 119px;
    margin-left: 10px;
  }
}

.el-pagination {
  margin: 20px;
  margin-right: 100px;
  text-align: right;
}
.el-table {
  margin: 0 auto;
}

.home-content-conduct {
  width: 85%;
  margin: 0 auto;
}
.home-content-conduct-page {
  text-align: center;
  margin-bottom: 50px;
  .conduct-page-button {
    border-color: @baseBack;
    color: @baseBack;
    &:nth-child(1) {
      margin-right: 10px;
    }
    &:nth-child(2) {
      margin-left: 10px;
    }
    &:focus,
    &:hover {
      background: #fff;
      border-color: @baseBack;
      color: @baseBack;
    }
    &:active {
      border-color: @baseBack;
      background-color: @baseBack;
      color: #fff;
    }
  }
  .conduct-page-button.is-disabled {
    border-color: #ebeef5 !important;
    color: #c0c4cc !important;
    &:active {
      background: #fff;
    }
  }
}
.title {
  width: 100%;
  text-align: center;
  font-size: 32px;
}
.home-content-conduct {
  margin-top: 28px;
  .home-content-conduct-title {
    position: relative;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 22px;
    font-weight: bold;
    color: @baseFont;

    .inpDiv {
      position: absolute;
      right: 180px;
    }
    .back-btn{
      position: absolute;
      left:0;
	    cursor: pointer;
    }
  }
  .search-input {
    &::v-deep input {
      &:focus {
        border-color: @baseBack;
      }
    }
  }
  .conduct-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-area {
    height: 510px;
    .empty-placeholder {
      line-height: 510px;
      color: #c0c4cc;
      text-align: center;
      margin: 0;
    }
    &::v-deep .custom-loading .el-loading-spinner i {
      font-size: 35px;
      color: #bd1a2d;
    }

    .home-content-conduct-item {
      width: 280px;
      height: 140px;
      border-radius: 2px;
      box-shadow: 0px 8px 30px 0px rgba(38, 9, 13, 0.06);
      border: 1px solid #fff;
      margin-bottom: 27px;
			.item-title-area{
				height: 45px;
			}
      .conduct-item-title {
        text-align: center;
        margin-top: 27px;
        margin-bottom: 0;
        color: @baseFont;
        font-size: 16px;
        padding: 0 25px;
        overflow:hidden; 
        text-overflow:ellipsis;
        display:-webkit-box; 
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2; 
      }

      &:hover {
        background-color: @baseBack;

        .conduct-item-title {
          color: #fff;
        }

        .conduct-item-button-area .conduct-item-button {
          border: 1px solid rgb(255, 255, 255);
          color: #fff;
          margin: 0 auto;
        }
      }

      .conduct-item-button-area {
        display: flex;
        justify-content: space-between;
        padding: 0 45px;

        .conduct-item-button {
          width: 160px;
          height: 32px;
          border: 1px solid @baseBack;
          border-radius: 2px;
          color: @baseBack;
          font-size: 16px;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>