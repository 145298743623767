import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'amfe-flexible';
// 全局自定义样式
import './styles/index.css'

import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/zh-CN';
// elementUI 样式
import 'element-ui/lib/theme-chalk/index.css';
// 自定义 elementui 样式
import './styles/element-custom-style/index.css'
import {$post,$postForJson,$get} from '@/utils/axios.js'


import less from 'less'

Vue.use(ElementUI, { locale,size:'small' })
Vue.use(less);

Vue.config.productionTip = false
Vue.prototype.$get = $get;
Vue.prototype.$post = $post;
Vue.prototype.$postForJson = $postForJson;

Vue.prototype.$timer = null;
// 添加全局事件监控方法
Vue.prototype.resetSetItem = function (key, newVal) {
  // 创建一个StorageEvent事件
  var newStorageEvent = document.createEvent('StorageEvent');
  const storage = {
    setItem: function (k, val) {
      sessionStorage.setItem(k, val);

      // 初始化创建的事件
      newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

      // 派发对象
      window.dispatchEvent(newStorageEvent)
    }
  }
  return storage.setItem(key, newVal);
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
