<template>
  <div class="dynamic-submit">
    <div class="content">
      <el-row>
        <el-col>
          <span class="back" @click="$router.go(-1)">
            <i class="el-icon-arrow-left"></i>
            返回
          </span>
        </el-col>
        <el-col>
          <h3 class="title">确认上传信息</h3>
        </el-col>
        <el-col class="card-items">
          <el-table :data="tableData" border>
            <el-table-column
              type="index"
              label="序号"
              width="80"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="materialName"
              label="材料名称"
              width="180"
              align="center"
            ></el-table-column>
            <el-table-column label="数量要求" width="180" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.mSource == 10">申请人自备、</span>
                <span v-else-if="scope.row.mSource == 20">政府部门核发、</span>
                <span v-else-if="scope.row.mSource == 30"
                  >中介机构或法定机构产生、</span
                >
                <span v-if="scope.row.isOriginal == 1"
                  >正本原件{{ scope.row.originalNum }}份；</span
                >
                <span v-if="scope.row.isCopy == 1"
                  >正本复印件{{ scope.row.copyNum }}份；</span
                >
                <span v-if="scope.row.isDuplicateOriginal == 1"
                  >副本原件{{ scope.row.duplicateNum }}份；</span
                >
                <span v-if="scope.row.isDuplicateCopy == 1"
                  >副本复印件{{ scope.row.duplicateCopyNum }}份；</span
                >
              </template>
            </el-table-column>
            <el-table-column label="文件名称" align="center">
              <template slot-scope="s">
                <p
                  v-for="item in s.row.fileIds"
                  :key="item.fileId"
                  class="file-name"
                >
                  {{ item.fileNameUp }}
                </p>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="材料要求" align="center">
              <template slot-scope="s">
                <span v-if="s.row.mMedia == 1">纸质</span>
                <span v-else-if="s.row.mMedia == 2">电子</span>
                <span v-else-if="s.row.mMedia == 3">纸质、电子</span>
              </template>
            </el-table-column>
            <el-table-column align="center" width="210" label="材料预览">
              <template slot-scope="s">
                <!-- <el-button
                  type="primary"
                  plain
                  class="btn"
                  icon="el-icon-download"
                  @click="downloadFile(s.row)"
                >下载</el-button>-->
                <el-button
                  type="primary"
                  icon="el-icon-zoom-in"
                  plain
                  class="btn"
                  @click="viewFile(s.row)"
                  >预览</el-button>
                <!--                <el-button type="primary" plain icon="el-icon-printer" class="btn" @click="printByAndroid(s.row)">打印</el-button>-->
              </template>
            </el-table-column>
          </el-table>
        </el-col>
        <el-col v-if="thePlatform === 'mobile'">
          <span class="esign-tips"
            >我承诺所有申报信息真实有效，自愿承担法律责任，请确认签字：</span
          >
          <el-card class="card-items esign-area">
            <div slot="header" style="text-align: right; margin-top: 10px">
              <el-button @click="resetEsign()">重 置</el-button>
            </div>
            <div style="margin-top: 10px; zoom: 1">
              <vue-esign
                ref="esign"
                :isCrop="true"
                class="esign-style"
              ></vue-esign>
            </div>
          </el-card>
        </el-col>

        <el-col>
          <el-form label-width="120px" :model="baseForm">
            <el-row>
              <el-col :span="24">
                <el-form-item label="出件方式">
                  <el-radio-group v-model="baseForm.outputType">
                    <el-radio :label="1">政务中心取件</el-radio>
                    <el-radio :label="3">EMS快递</el-radio>
                    <el-radio :label="5">无人政务车取件</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
              <div v-if="baseForm.outputType === 3">
                <el-col :span="12">
                  <el-form-item label="收件人">
                    <el-input v-model="emsForm.emsOutputRecvName"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="手机号">
                    <el-input v-model="emsForm.emsOutputRecvPhone"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="邮编">
                    <el-input
                      v-model="emsForm.emsOutputRecvLocalCode"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="所在地区">
                    <el-input v-model="emsForm.emsOutputRecvAddr"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24">
                  <el-form-item label="详细地址">
                    <el-input v-model="emsForm.emsOutputRecvMore"></el-input>
                  </el-form-item>
                </el-col>
              </div>
              <div v-if="baseForm.outputType === 1" style="margin-left: 120px">
                <p>{{ address.managerDept }}</p>
                <p>办理地址：{{ address.transactaddr }}</p>
                <p>工作时间：{{ address.transacttime }}</p>
                <p>咨询电话：{{ address.linkTel }}</p>
              </div>
            </el-row>
          </el-form>
        </el-col>

        <el-col style="text-align: center; margin-top: 20px">
          <el-button
            type="primary"
            class="submitBtn"
            :loading="submitLoading"
            @click="handelSubmit"
          >
            {{ esignStatus ? "提 交" : "确认签字" }}
          </el-button>
        </el-col>
      </el-row>
      <!-- <Footer class="bottom-content" /> -->
    </div>
    <publicLogin :visible.sync="loginDialogControl" />
    <!-- 提交弹框 -->
    <div class="submit-dialog" v-if="dialogVisible">
      <div class="submit-dialog-content">
        <el-image
          class="success-img"
          :src="require('../assets/unmanned_vehicle/success.png')"
          fit="cover"
        ></el-image>
        <p class="dialogSpa">申报成功</p>
        <p class="dialog-tip">您已申报成功，我们正在加速办理</p>
        <p class="dialog-tip">若遇到问题，请向客服求助：010-52315198</p>
        <div class="submit-dialog-content-button" @click.prevent="goHome()">
          办件结束，回到首页
        </div>
      </div>
    </div>

    <div class="file-confirm-dialog" v-if="fileConfirmDialog">
      <div class="file-confirm-dialog-content">
        <p class="content-header">请将以下材料放置在打开的柜门内</p>
        <div class="file-list">
          <el-checkbox-group v-model="confirmFileInBox">
            <div
              class="file-list-item"
              v-for="item in mMediaList"
              :key="item.materialId"
            >
              <p>{{ item.materialName }}</p>
              <el-checkbox
                class="file-list-item-checkbox"
                :label="item.materialId"
                >已放置</el-checkbox
              >
            </div>
          </el-checkbox-group>
        </div>
        <div class="file-confirm-button" @click="getUpData">放置完成，提交</div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible2" width="90%" fullscreen>
      <iframe
        :src="pdfSrc"
        frameborder="0"
        width="100%"
        height="100%"
        class="iframe"
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
import publicHeader from "@/components/publicHeader";
import Footer from "@/components/Footer";
import publicLogin from "@/components/publicLogin.vue";

import vueEsign from "vue-esign";

import { serialHandler } from "@/utils/serialHandler";
import { openTheDoor, doorStatus } from "@/utils/instructionList";
export default {
  name: "dynamicSubmit",
  components: {
    publicHeader,
    Footer,
    publicLogin,
    vueEsign,
  },
  data() {
    return {
      dialogVisible2: false,
      pdfSrc: "",
      recvId: this.$route.query.recvId,
      subjectTaskId: this.$route.query.taskId,
      loginDialogControl: false,
      activeNames: "",
      activeNames2: "",
      activeNames3: "",
      dialogVisible: false,
      tableData: [],
      submitLoading: false,
      taskId: this.$route.query.taskId,
      /** 签字状态： false 未签字 */
      esignStatus: false,
      /** 保存签字后返回的文件信息 */
      esignFileInfo: null,
      thePlatform: "mobile",
      baseForm: {},
      address: {},
      emsForm: {},
      fileConfirmDialog: false,
      mMediaList: [],
      confirmFileInBox: [],
      openEd: ""
    };
  },
  created() {
    this.getTableData();
    this.getAddress();
    document.body.style.zoom = "1";
  },
  methods: {
    getAddress() {
      this.$post("/template/getItemByIdTask", { taskId: this.taskId }).then(
        (res) => {
          console.log(res.data);
          let { code, guidesObject, msg } = res.data;
          if (code === 200) {
            this.address = guidesObject;
          } else {
            this.$message.error(msg);
          }
        }
      );
    },
    getTableData() {
      let loading = this.$loading({
        fullscreen: true,
        lock: true,
      });
      this.$post("/template/findMaterialArr?recvId=" + this.recvId)
        .then((res) => {
          let { code, materialArr, msg } = res.data;
          loading.close();
          if (code === 200) {
            this.taskId = materialArr[0].taskId;
            this.tableData = materialArr
          } else {
            this.$message.error(msg);
          }
        })
        .catch(() => {
          loading.close();
        });
    },
    submitForm() {
      this.submitLoading = true;
      let params = {
        recvId: this.recvId,
        taskId: this.taskId,
        outputType: this.baseForm.outputType,
      };
      params.signFileId1 = this.esignFileInfo.fileId;
      if (this.baseForm.outputType === 3) {
        for (let i in this.emsForm) {
          params[i] = this.emsForm[i];
        }
      }
      this.$postForJson(`/template/taskRecv`, params)
        .then((res) => {
          this.submitLoading = false;
          let { code, msg } = res.data;
          if (code === 200) {
            this.dialogVisible = true;
          } else {
            this.$message.error(msg);
          }
        })
        .catch(() => {
          this.submitLoading = false;
        });
    },
    handelSubmit() {
      if (this.esignStatus) {
        this.mMediaList = this.tableData.filter((item) => item.mMedia === "1");
        if (this.mMediaList.length > 0) {
          serialHandler.init();
          this.$post("/CarCabinet/findCarCode", { carCode: "1" }).then(
            (value) => {
              let { code, list, msg } = value.data;
              if (code === 200) {
                this.openEd = list[0];
                let bNum;
                switch (list[0]) {
                  case "1":
                    bNum = openTheDoor.b1;
                    break;
                  case "2":
                    bNum = openTheDoor.b2;
                    break;
                  case "3":
                    bNum = openTheDoor.b3;
                    break;
                  case "4":
                    bNum = openTheDoor.b4;
                    break;
                  case "5":
                    bNum = openTheDoor.b5;
                    break;
                  case "6":
                    bNum = openTheDoor.b6;
                    break;
	                case "7":
		                bNum = openTheDoor.b7;
		                break;
                  case "8":
                    bNum = openTheDoor.b8;
                }
                serialHandler.writeCOM(bNum).then(() => {
                  serialHandler.readCOM().then((value) => {
                    console.log(value, "看看办件开门返回的数据---------");
                      this.fileConfirmDialog = true;
                  });
                });
              }
            }
          );
        }else{
					this.submitForm()
        }
      } else {
        this.$refs.esign
          .generate()
          .then((res) => {
            this.submitLoading = true;
            this.$post("/acceptFile/uploadBase64ToFile", {
              file: res,
              userId: "122",
            })
              .then((res1) => {
                this.submitLoading = false;
                let { code, data, msg } = res1.data;
                if (code === 200) {
                  this.esignFileInfo = data;
                  this.esignStatus = true;
                  this.$message.success("签字成功");
                } else {
                  this.$message.error("签字失败");
                }
              })
              .catch((err) => {
                this.submitLoading = false;
                this.$message.error("服务器错误");
              });
          })
          .catch(() => {
            this.$message.warning("请先签名");
          });
      }
    },
    /**放置完成 */
    getUpData() {
      let bNum;
      switch (this.openEd) {
        case "1":
          bNum = doorStatus.b1;
          break;
        case "2":
          bNum = doorStatus.b2;
          break;
        case "3":
          bNum = doorStatus.b3;
          break;
        case "4":
          bNum = doorStatus.b4;
          break;
        case "5":
          bNum = doorStatus.b5;
          break;
        case "6":
          bNum = doorStatus.b6;
          break;
        case "7":
          bNum = doorStatus.b7;
          break;
        case "8":
          bNum = doorStatus.b8;
      }
      var timeOutAus1 = setInterval(() => {
        serialHandler.writeCOM(bNum).then(() => {
          serialHandler.readCOM().then((value) => {
            console.log(value, "看看办件门的状态----------");
            if (value[3] == "0") {
              clearInterval(timeOutAus1);
              this.fileConfirmDialog = false;
	            this.submitForm()
            }
          });
        });
      }, 2000);
    },
    isNeedLogin(val) {
      let user = sessionStorage.getItem("user");
      if (user) {
        this.$router.push({ name: "inquiryProcess", query: { phone: user } });
      } else {
        this.loginDialogControl = val;
      }
    },
    goHome() {
      this.$router.replace("/");
    },
    toProcess() {
      let user = sessionStorage.getItem("user");
      if (user) {
        this.$router.push({ name: "inquiryProcess", query: { phone: user } });
      } else {
        this.loginDialogControl = true;
      }
    },
    handelLogin() {
      this.$message.success("登录成功");
      this.loginDialogControl = false;
      setTimeout(() => {
        this.$router.push({
          name: "inquiryProcess",
          query: { query: { key: "value" } },
        });
      }, 1000);
    },
    /**
     * 文件移除
     */
    removeFile(index, row) {
      console.log(row);
      row.splice(index, 1);
      console.log(row);
      this.tableDataRefresh = false;
      this.$nextTick(() => {
        this.tableDataRefresh = true;
      });
    },
    viewFile(row) {
			if(row.fileIds.length>0){
				let baseUrl = "";
				if (process.env.NODE_ENV === "develop") {
					baseUrl = "/api";
				} else {
					baseUrl = process.env.VUE_APP_BASE_URL;
				}
				window.open(
						`${baseUrl}/acceptFile/viewFile?fileId=${row.fileIds[0].fileId}`,
						"文件预览",
						"_blank"
				);
			}else{
				this.$message.warning('暂无文件可以预览')
			}
			return;
      // 对office文档执行下载
      if (
        row[index].fileType === "xlsx" ||
        row[index].fileType === "docx" ||
        row[index].fileType === "xls" ||
        row[index].fileType === "doc" ||
        row[index].fileType === "ppt" ||
        row[index].fileType === "pptx"
      ) {
        let ele = document.createElement("iframe");
        ele.src = `${baseUrl}/acceptFile/download?fileId=${row[index].fileId}`;
        ele.style.display = "none";
        ele.style.height = 0;
        document.body.appendChild(ele);
        setTimeout(() => {
          ele.remove();
        }, 60 * 1000);
      } else {
        // 反之可以预览
        window.open(
          `${baseUrl}/acceptFile/viewFile?fileId=${row[index].fileId}`,
          "文件预览",
          "_blank"
        );
      }
    },
    viewFile2(row) {
      let baseUrl = "";
      if (process.env.NODE_ENV === "develop") {
        baseUrl = "/api";
      } else {
        baseUrl = process.env.VUE_APP_BASE_URL;
      }
      // TODO: 文件预览
    },
    downloadFile(row) {
      let baseUrl = "";
      if (process.env.NODE_ENV === "develop") {
        baseUrl = "/api";
      } else {
        baseUrl = process.env.VUE_APP_BASE_URL;
      }
      row.fileIds.forEach((item) => {
        let ele = document.createElement("iframe");
        ele.src = `${baseUrl}/acceptFile/download?fileId=${item.fileId}`;
        ele.style.display = "none";
        ele.style.height = 0;
        document.body.appendChild(ele);
        setTimeout(() => {
          ele.remove();
        }, 60 * 1000);
      });
    },
    resetEsign() {
      this.$refs.esign.reset();
    },
  },
};
</script>
<style lang="less" scoped>
@themeColor: #2f56ff;
.top {
  width: 100%;
  height: 119px;
  background: @themeColor;
  line-height: 119px;
}
.title2 {
  color: #fff;
  font-size: 32px;
  line-height: 119px;
  margin-left: 10px;
}
.content {
  width: 80%;
  height: auto;
  margin: 25px auto 70px;
  position: relative;
}
.back {
  cursor: pointer;
}
.title {
  text-align: center;
  font-size: 32px;
}
.introduce {
  width: 100%;
  background: #f0f0f0;
  padding: 10px;
  font-size: 16px;
  line-height: 28px;
  box-sizing: border-box;
}
.content::v-deep .el-collapse-item__header {
  background: #f0f0f0;
  text-indent: 20px;
}
.content::v-deep .el-collapse-item__wrap {
  border-bottom: 0;
}
.el-collapse-item p {
  text-indent: 20px;
}
.el-collapse {
  position: relative;
}
.fixSpan {
  position: absolute;
  left: 0;
  display: inline-block;
  width: 6px;
  height: 60px;
  background: @themeColor;
}
.bottom-content {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
.erweiDiv {
  width: 150px;
  height: 170px;
  position: absolute;
  right: -165px;
  top: 148px;
}
.erweiDiv img {
  display: inline-block;
  width: 150px;
  height: 150px;
}
.erweiDiv span {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.title-content {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f7f7f7;
  margin: 0;
}
.majorTitle {
  margin-left: 30px;
  font-size: 20px;
  font-weight: bold;
}
.regionTitle {
  float: right;
  color: #999999;
  font-size: 16px;
  margin-right: 30px;
}

.btn {
  width: 80px;
  color: @themeColor;
  border-color: @themeColor;
}
.el-table {
  font-size: 16px;
}
.submitBtn {
  width: 200px;
  height: 50px;
  background: @themeColor;
  font-size: 18px;
}

.iconPic {
  display: block;
  width: 70px;
  height: 70px;
  color: @themeColor;
  margin: 0 auto;
  font-size: 66px;
  text-align: center;
}

.sureBtn {
  width: 160px;
  height: 50px;
  background: @themeColor;
  font-size: 18px;
}
.queryBtn {
  width: 160px;
  height: 50px;
  color: @themeColor;
  font-size: 18px;
}

.card-items {
  margin-bottom: 48px;
}
.fillSpan {
  font-size: 20px;
}
.fillSpan-icon {
  color: @themeColor;
  font-weight: bold;
  margin-right: 15px;
}

.esign-area {
  &::v-deep .el-card__header {
    padding: 0 10px 10px 0;
  }
  &::v-deep .el-card__body {
    padding: 0;
  }
}

.pdfDiv {
  position: relative;
}
.iframe {
  height: 800px;
}

.submit-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  .submit-dialog-content {
    width: 480px;
    height: 325px;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 17.5px;
    padding-bottom: 45px;
    .success-img {
      width: 128px;
      height: 108px;
    }
    .dialogSpa {
      font-size: 16px;
      color: #141414;
      font-weight: 500;
      line-height: 25px;
    }
    .dialog-tip {
      margin: 0;
      color: #666;
      font-size: 14px;
      line-height: 25px;
    }
    .submit-dialog-content-button {
      width: 180px;
      height: 44px;
      background: #2f56ff;
      box-shadow: 0 10px 20px 0 rgba(47, 86, 255, 0.4);
      border-radius: 10px;
      margin-top: 50px;
      font-size: 16px;
      line-height: 44px;
      text-align: center;
      color: #fff;
	    cursor: pointer;
    }
  }
}

.file-confirm-dialog {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  .file-confirm-dialog-content {
    width: 1275px;
    height: 720px;
    background: #ffffff;
    border-radius: 15px;
    .content-header {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 30px;
    }
    .file-confirm-button {
      width: 270px;
      height: 60px;
      background: #2f56ff;
      box-shadow: 0 8px 15px 0 rgba(47, 86, 255, 0.4);
      border-radius: 8px;
      margin: 20px auto auto;
      text-align: center;
      color: #fff;
      font-size: 24px;
      line-height: 60px;
	    cursor: pointer;
    }
    .file-list {
      height: 439px;
      overflow-y: auto;
      padding: 0 38px;
      .file-list-item {
        display: flex;
        height: 72px;
        background: #f7f7f9;
        border-radius: 8px;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        p {
          font-size: 24px;
          color: #141414;
        }
        .file-list-item-checkbox {
          zoom: 1.5;
        }
      }
    }
  }
}
.esign-tips{
	line-height: 50px
}
</style>