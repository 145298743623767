<template>
	<div class="index-page">
		<div class="index-page-header-content">
			<div class="index-page-header">
				<div class="header-time">
					<timer-component />
				</div>
			</div>
		</div>
		<div class="index-page-content" @click="enterHandler">
<!--			<el-row v-if="debug">
				<el-col :span="8">
					<el-form v-model="instruction" label-width="120px" @click.prevent>
						<el-form-item>
							<el-input v-model="instruction.text"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button @click="openSerial">连接串口</el-button>
							<el-button @click="write">发送指令</el-button>
							<el-button @click="closeSerial">关闭</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>-->
			<el-image class="content-image" :src="contentImageUrl"></el-image>
		</div>
	</div>
</template>

<script>
import timerComponent from "@/components/timerComponent";
import {serialHandler} from "@/utils/serialHandler";
export default {
	name: "index",
	data(){
		return{
			contentImageUrl:require('../assets/unmanned_vehicle/standby_touch.png'),
			serialPort:null,
			instruction:{
				text:''
			},
			debug:this.$route.query.debug==='1'
		}
	},
	components:{
		timerComponent
	},
	methods:{
		enterHandler(){
			this.$router.push({path:'/mainIndex'})
		},
		openSerial(){
			serialHandler.init()
		},
		write(){
			let instruction = this.instruction.text.split(' ');
			serialHandler.writeCOM(instruction).then(()=>{
				serialHandler.readCOM().then(value=>{
					console.log('readed:',value)
				})
			})
		},
		closeSerial(){
			console.log('close')
			serialHandler.closeCOM();
		}
	}
}
</script>

<style lang="less" scoped>
.index-page{
	width: 100vw;
	height: 100vh;
	background-image: url("../assets/unmanned_vehicle/standby_bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	.index-page-header-content{
		padding-top: 43px;
	}
	.index-page-header{
		height: calc(54px*0.75);
		display: flex;
		margin-left: calc(60px*0.75);
		margin-right: calc(60px*0.75);
		align-items: center;
		background-image: url("../assets/unmanned_vehicle/word.png");
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		.header-time{
			font-size: calc(32px*0.75);
			color: #fff;
		}
	}
	.index-page-content{
		height: calc(100vh - 102px);
		width: 100vw;
		position: relative;
		.content-image{
			width: 100%;
			height: calc(158px*0.75);
			position: absolute;
			bottom: calc(48px*0.75);
		}
	}
}
</style>