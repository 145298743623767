// @ts-check

/**
 * 将时间解析为字符串
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 * @description 默认格式：{y}-{m}-{d} {h}:{i}:{s}
 */
export  function parseTime(time, cFormat){
	if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
	})
  return time_str
}

/**全部证件类型 */
export function allCardTypeList(){
  return [ // 全部证件类型
    {label:'统一社会信用代码',value:'001'},
    {label:'组织机构代码',value:'002'},
    {label:'工商局登记号（营业执照）',value:'003'},
    {label:'事业单位法人证书',value:'004'},
    {label:'社会团体法人等级证书',value:'005'},
    {label:'党政机关法人（无证书）',value:'006'},
    {label:'其他法人或其他组织有效证件',value:'099'},
    {label:'居民身份证',value:'111'},
    {label:'临时居民身份证',value:'112'},
    {label:'户口簿',value:'113'},
    {label:'中国人民解放军军官证',value:'114'},
    {label:'中国人民武装警察警官证',value:'115'},
    {label:'出生医学证明',value:'117'},
    {label:'中国人民解放军士官证',value:'118'},
    {label:'中国人民武装警察部队士兵证',value:'119'},
    {label:'中国人民解放军文职人员证',value:'120'},
    {label:'中国人民武装警察部队文职人员证',value:'122'},
    {label:'居住证',value:'154'},
    {label:'外交护照',value:'411'},
    {label:'公务护照',value:'412'},
    {label:'公务普通护照',value:'413'},
    {label:'普通护照',value:'414'},
    {label:'旅行证',value:'415'},
    {label:'外国人旅行证',value:'418'},
    {label:'台湾居民来往大陆通行证',value:'511'},
    {label:'港澳居民来往内地通行证',value:'516'},
    {label:'外国人永久居留身份证',value:'553'},
    {label:'外国人居留证或居留许可',value:'554'},
    {label:'外国人临时居留证',value:'555'},
    {label:'外交人员身份证明',value:'791'},
    {label:'境外人员身份证明',value:'792'},
    {label:'驻华机构证明',value:'813'},
    {label:'营业执照',value:'821'},
    {label:'律师事务所执业许可证',value:'830'},
    {label:'其他',value:'999'},
  ]
}

/**
 * 身份证的验证规则
 * @param {string} value 
 * @returns
 */
export function validateIdentifyId(value){
  if(value){
    if (checkCode(value)) {
      var date = value.substring(6, 14);
      if (checkDate(date)) {
        if (checkProv(value.substring(0, 2))) {
          return true
        }
      }
    } else {
      return false;
    }    
  }
}

/**
 * 检查身份编码
 * @param val
 * @returns
 */
 function checkCode(val) {
  var p = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
  var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
  var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
  var code = val.substring(17);
  if (p.test(val)) {
    var sum = 0;
    for (var i = 0; i < 17; i++) {
      sum += val[i] * factor[i];
    }
    if (parity[sum % 11] == code.toUpperCase()) {
      return true;
    }
  }
  return false;
}
/**
 * 检查日期
 * @param val
 * @returns
 */
var checkDate = function (val) {
  var pattern = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
  if (pattern.test(val)) {
    var year = val.substring(0, 4);
    var month = val.substring(4, 6);
    var date = val.substring(6, 8);
    var date2 = new Date(year + '-' + month + '-' + date);
    if (date2 && date2.getMonth() == (parseInt(month) - 1)) {
      return true;
    }
  }
  return false;
};
/**
 * 检查省份
 * @param val
 * @returns
 */
var checkProv = function (val) {
  var pattern = /^[1-9][0-9]/;
  var provs = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江 ',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北 ',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏 ',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门'
  };
  if (pattern.test(val)) {
    if (provs[val]) {
      return true
    }
  }
  return false;
};


/**验证手机号 */
export function validatePhone(value){
  if(value){
    const phoneReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
    return phoneReg.test(value)
  }

}
/**验证固定电话 */
export function validateOfficePhone(value){
  if(value){
    const officePhoneReg = /^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/;
    return officePhoneReg.test(value)    
  }
}
export function validateEmail(value) {
  if (value) {
    const emailReg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
    return emailReg.test(value)
  }
}


/**
 * 基于750的设计图配置 rem 单位
 * @param {Document} doc
 */
export function setRem(doc){
  let psdWidth = 7.5;
  let docEl = doc.documentElement;
  if(docEl.clientWidth>=640){
    docEl.style.fontSize = 50 * ( 6.4 / psdWidth ) + 'px';
  }else{
    docEl.style.fontSize = (docEl.clientWidth / psdWidth) + 'px';
    docEl.style.height  = '100%';
    doc.body.style.height = '100%';
  }
};

/**
 * 防抖
 * @param {*} fn 防抖的方法 
 * @param {*} t 延迟
 * @returns 
 */
export const DebounceBy = (fn, t) => {
  let delay = t || 500
  let timer
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer)
    }
 
    let callNow = !timer
 
    timer = setTimeout(() => {
      timer = null
    }, delay)
 
    if (callNow) fn.apply(this, args)
  }
}