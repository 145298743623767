<template>
  <div class="communicate">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <img
          src="@/assets/unmanned_vehicle/logo.png"
          class="boxHeader-left-logo"
          alt=""
        />
        <p><timer-component /></p>
      </div>
    </div>
    <div class="boxContet">
      <div class="boxContet-left">
        <!--    对方画面    -->
        <div class="heFrames" id="he-frames"></div>
        <div class="guaDuan" @click="hungup" title="挂断">
          <img src="@/assets/unmanned_vehicle/refuse.png" alt="" />
        </div>
        <p>挂断</p>
      </div>
      <div class="boxContet-right">
        <!--   我的画面容器     -->
        <div class="meFrames" id="meFrames"></div>
        <div class="diZhi">
          <img
            src="@/assets/unmanned_vehicle/location.png"
            alt=""
          />北京市亦庄经济技术开发区街道
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timerComponent from "@/components/timerComponent";
import * as callLib from '@/utils/CallLib';
import rongyun from "@/utils/RongYun";

export default {
  name: "communicate",
  components: {
    timerComponent,
  },
  data() {
    return {
      myVidoeId:rongyun.TOKEN.userId
    };
  },
  mounted(){
    this.initIm();
		setTimeout(()=>{
			this.$message.info('发起通话');
      // this.getMedia();
      this.caller();
		},1000)
  },
  methods:{
    initIm(){
      callLib.imConnect(rongyun.TOKEN.token).then(res=>{
        if (res.code === 0) {
          console.log('链接成功, 链接用户 id 为: ', res.data.userId);
          /*let node = document.createElement('video');
          node.setAttribute('id', `${res.data.userId}`);
          node.setAttribute('autoplay', 'autoplay');
          node.setAttribute('playsinline', 'playsinline');
          node.setAttribute('width', '100%');
          node.setAttribute('height', '100%');

          let meFrames = document.getElementById('meFrames');
          meFrames.appendChild(node);*/
        } else {
          console.error('链接失败, code:', res.code)
        }
      })
    },
    async caller(){
      try{
        const res = await callLib.lunchSingleCall('2',2,'2')
        let {code,targetId} = res;
        if(code === 'success'){
          this.$message.info('呼叫成功，请等待');
        }else{
          this.$message.error('呼叫失败');
        }
      }catch (e) {
        console.error(e)
      }
    },
    hungup(){
      callLib.hungup().then(res=>{
        if(res.code === 'success'){
          this.$message.info('挂断成功');
          setTimeout(()=>{
            this.$router.go(-1)
          },2000)
        }else{
          this.$message.error('挂断失败');
        }
      }).catch(err=>{
        console.error(err)
      })
    },

    async getMedia(){
      try{
        /**
         * @type {HTMLVideoElement}
         */
        const video = document.querySelector('#he-frames');
        console.log('video:',video)
        video.srcObject = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
      }catch(err){
        console.error(err)
      }
      /* navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: "user" } }).then(res=>{
        this.$refs.myVideo.srcObject = res;
        this.$refs.myVideo.play();
      }).catch(err=>{
        console.error("getMedia: ",err)
      }) */
    },
  }
};
</script>

<style lang="less" scoped>
@baseZoom: 0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.communicate {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/1.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  .boxHeader-left {
    display: flex;
    p {
      font-size: calc(24px * @baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(60px * @baseZoom);
      margin-left: calc(31px * @baseZoom);
    }
    .boxHeader-left-logo {
      width: calc(390px * @baseZoom);
      height: calc(120px * @baseZoom);
    }
  }
}
.boxContet {
  width: 100%;
  height: calc(920px * @baseZoom);
  display: flex;
  .boxContet-left {
    width: calc(100% - calc(532px * @baseZoom));
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: calc(10px * @baseZoom);
    margin-left: calc(31px * @baseZoom);
    .heFrames {
      width: 95%;
      height: calc(694px * @baseZoom);
      background-color: #000;
      border-radius: calc(10px * @baseZoom);
      margin: auto;
      margin-top: calc(37px * @baseZoom);
    }
    .guaDuan {
      width: calc(240px * @baseZoom);
      height: calc(80px * @baseZoom);
      background: #e7392e;
      border-radius: calc(40px * @baseZoom);
      margin: auto;
      margin-top: calc(33px * @baseZoom);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: calc(66px * @baseZoom);
        height: calc(32px * @baseZoom);
      }
    }
    p {
      font-size: calc(30px * @baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(20px * @baseZoom);
      text-align: center;
    }
  }
  .boxContet-right {
    width: calc(532px * @baseZoom);
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-radius: calc(10px * @baseZoom);
    margin-right: calc(32px * @baseZoom);
    margin-left: calc(24px * @baseZoom);
    position: relative;
    .meFrames {
      width: 90%;
      height: calc(264px * @baseZoom);
      background: #000000;
      border-radius: calc(10px * @baseZoom);
      margin: auto;
      margin-top: calc(37px * @baseZoom);
    }
    .diZhi {
      font-size: calc(24px * @baseZoom);
      font-weight: 400;
      color: #ffffff;
      position: absolute;
      bottom: calc(37px * @baseZoom);
      left: calc(32px * @baseZoom);
      img {
        width: calc(20px * @baseZoom);
        height: calc(24px * @baseZoom);
        margin-right: calc(16px * @baseZoom);
        margin-bottom: calc(-2px * @baseZoom);
      }
    }
  }
}
</style>
