var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "index-page" }, [
    _c("div", { staticClass: "index-page-header-content" }, [
      _c("div", { staticClass: "index-page-header" }, [
        _c("div", { staticClass: "header-time" }, [_c("timer-component")], 1)
      ])
    ]),
    _c(
      "div",
      { staticClass: "index-page-content", on: { click: _vm.enterHandler } },
      [
        _c("el-image", {
          staticClass: "content-image",
          attrs: { src: _vm.contentImageUrl }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }