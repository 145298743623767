var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "top" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { attrs: { span: 5, offset: 5 } }, [
            _c("span", { staticClass: "title" }, [_vm._v("办好一件事")])
          ]),
          _vm.needLogin
            ? _c(
                "el-col",
                { attrs: { span: 5, offset: 8 } },
                [
                  _c(
                    "el-button",
                    { staticClass: "btn", on: { click: _vm.tologin } },
                    [_vm._v("查询办事进度")]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }