<template>
    <div class="top">
        <el-row>
            <el-col :span="5" :offset="5">
                <span class="title">办好一件事</span>
            </el-col>
            <el-col :span="5" :offset="8" v-if="needLogin">
                <el-button class="btn" @click="tologin">查询办事进度</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name:'publicHeader',
    props:{
        needLogin:{
            default:true,
            type:Boolean
        }
    },
    methods:{
        tologin(){
            this.$emit('isLogin',true)
        }
    }
}
</script>

<style scoped>
.top{
    width: 100%;
    height: 119px;
    background: #BD1A2D;
    line-height: 119px;
}
.title{
    color:#fff;
    font-size: 32px;
    line-height: 119px;
    margin-left: -35px;
}
.el-button{
    background: #DC7D7E;
    width: 132px;
    height: 48px;
    font-size: 16px;
    color:#fff;
    border: 0;
}
</style>