<template>
  <div v-if="checked" class="checked-style">
    <div style="width:0.14rem;height:0.14rem;margin-top:0.06rem;margin-left:0.06rem;background-color:#6F8DE6;border-radius:50%"></div>
  </div>
  <div v-else class="unchecked-style"></div>
</template>
<script>
export default {
  props:{
    checked:{
      require:true,
      type:Boolean
    }
  }
}
</script>
<style scoped lang="less">
@marginTop:2px;
.checked-style{
  width:0.26rem;height:0.26rem; border-radius:50%;border:1px solid #6F8DE6;margin-top:@marginTop;
}
.unchecked-style{
  width:0.26rem;height:0.26rem; border-radius:50%;border:1px solid #999;margin-top:@marginTop;
}
</style>