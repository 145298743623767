<template>
  <div class="form-fill">
    <div class="top-tab">
      <div v-for="item in topTabList" :key="item.id" class="top-tab-item" :class="{'active-class':servType==item.id}" @click="()=>{servType=item.id;servTypeChange(item.id)}">{{item.text}}</div>
    </div>
    <van-form label-width="120px" class="form-card" ref="applyerInfo" :show-error="false" validate-first v-show="servType==1">
      <div class="form-card-content">
        <img class="form-card-icon" src="./assets/icon_1.png" alt="">
        <span class="form-card-span">个人申请信息</span>
      </div>
      <van-field class="van-field-c" v-model="baseForm.personName" label="姓名" placeholder="请输入姓名" required :rules="[{required:true,message:'请输入姓名',trigger:'onBlur'}]" />
      <van-field class="van-field-c" label="性别" placeholder="请输入姓名">
        <template #input>
          <van-radio-group v-model="baseForm.personSex" direction="horizontal">
            <van-radio name="男">男
              <template #icon="props">
                <custom-radio :checked="props.checked"></custom-radio>
              </template>
            </van-radio>
            <van-radio name="女">女
              <template #icon="props">
                <custom-radio :checked="props.checked"></custom-radio>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field class="van-field-c" v-model="baseForm.personBirth" label="出生日期" placeholder="请选择出生日期" readonly clickable @click="openCalendar('personBirth')" right-icon="arrow-down" />

      <van-field class="van-field-c" v-model="baseForm.personCardType" label="个人证件类型" placeholder="请选择个人证件类型" readonly clickable required :rules="[{required:true,message:'请选择个人证件类型',trigger:'onChange'}]" @click="selectClick('personCardType')" right-icon="arrow-down" ></van-field>

      <van-field class="van-field-c" v-model="baseForm.personCardNum" label="个人证件号码" name="personCardNum" placeholder="请输入个人证件号码" required :rules="formRules.personCardNum" />

      <van-field class="van-field-c" v-model="baseForm.personBirthPlace" label="籍贯" placeholder="请输入籍贯" />

      <van-field class="van-field-c" v-model="baseForm.personMobile" label="手机号码" type="tel" placeholder="请输入手机号码" required :rules="formRules.validatePhone" />

      <van-field class="van-field-c" v-model="baseForm.personFixed" label="固定电话" type="tel" placeholder="请输入固定电话" :rules="formRules.validateFixPhone" />

      <van-field class="van-field-c" v-model="baseForm.personContactEmail" label="邮箱" placeholder="请输入邮箱" />
      
      <van-field class="van-field-c" label="是否外籍">
        <template #input>
          <van-radio-group v-model="baseForm.personIsForeign" direction="horizontal" @change="(name)=>{personIsForeignChange(name)}">
            <van-radio name="1">是
              <template #icon="props">
                <custom-radio :checked="props.checked"></custom-radio>
              </template>
            </van-radio>
            <van-radio name="0">否
              <template #icon="props">
                <custom-radio :checked="props.checked"></custom-radio>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>

      <van-field v-if="baseForm.personIsForeign==1" class="van-field-c" v-model="baseForm.personNationality" label="国籍" placeholder="请输入国籍" />

      <van-field class="van-field-c" v-model="baseForm.personDegree" label="文化程度" placeholder="请选择文化程度" readonly clearable @click="selectClick('personDegree')" right-icon="arrow-down" />

      <van-field class="van-field-c" v-model="baseForm.personContactName" label="联系人" placeholder="请输入联系人" />

      <van-field class="van-field-c" v-model="baseForm.personContactAddr" label="地址" placeholder="请输入地址" />
    </van-form>

    <van-form label-width="120px" class="form-card" validate-first ref="enterForm" :show-error="false" v-show="servType==2">
      <div class="form-card-content">
        <img class="form-card-icon" src="./assets/icon_1.png" alt="">
        <span class="form-card-span">企业申请信息</span>
      </div>
      <van-field class="van-field-c" v-model="baseForm.enterpriseName" label="申请单位" placeholder="请输入申请单位" required name="enterpriseName" :rules="[{required:true,message:'请输入申请单位',trigger:'onBlur'}]" />
      <van-field class="van-field-c" v-model="baseForm.enterpriseType" label="单位类型" placeholder="请选择单位类型" readonly
        clickable @click="selectClick('enterpriseType')" right-icon="arrow-down" />

      <van-field class="van-field-c" label="是否法定代表人" required :rules="[{required:true,message:'请选择是否法定代表人',trigger:'onChange'}]">
        <template #input>
          <van-radio-group v-model="baseForm.isLegal" direction="horizontal">
            <van-radio name="1">是
              <template #icon="props">
                <custom-radio :checked="props.checked"></custom-radio>
              </template>
            </van-radio>
            <van-radio name="0">否
              <template #icon="props">
                <custom-radio :checked="props.checked"></custom-radio>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field class="van-field-c" v-model="baseForm.enterpriseLegalName" label="代表人姓名" placeholder="请输入代表人姓名" name="enterpriseLegalName" required :rules="[{required:true,message:'请输入代表人姓名',trigger:'onBlur'}]" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseCardType" label="单位证件类型" placeholder="请选择单位证件类型" readonly clickable required name="enterpriseCardType" :rules="[{required:true,message:'请选择单位证件类型',trigger:'onChange'}]" @click="selectClick('enterpriseCardType')" right-icon="arrow-down" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseCardNum" name="enterpriseCardNum" label="单位证件号码" placeholder="请输入单位证件号码" required :rules="[{required:true,message:'请输入单位证件号码',trigger:'onBlur'}]" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseLegalType" label="法人证件类型"
        placeholder="请选择法人证件类型" readonly clickable @click="selectClick('enterpriseLegalType')" required name="enterpriseLegalType" :rules="[{required:true,message:'请选择法人证件类型',trigger:'onChange'}]" right-icon="arrow-down" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseLegalIdcard" name="enterpriseLegalIdcard" label="法人证件号码" required 
        placeholder="请输入法人证件号码" :rules="formRules.personCardNum" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseContactName" label="联系人" placeholder="请输入联系人" required name="enterpriseContactName" :rules="[{required:true,message:'请输入联系人',trigger:'onBlur'}]" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseContactFixed" type="tel" label="固定电话" placeholder="请输入固定电话" name="enterpriseContactFixed" :rules="formRules.validateFixPhone" />

      <van-field class="van-field-c" type="tel" v-model="baseForm.enterpriseContactMobile" label="联系人手机" placeholder="请输入联系人手机" required name="enterpriseContactMobile" :rules="formRules.validatePhone" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseContactEmail" label="联系人邮箱" placeholder="请输入联系人邮箱" required name="enterpriseContactEmail" :rules="formRules.validateEmail" />

      <van-field class="van-field-c" v-model="baseForm.enterpriseContactAddr" label="企业地址" placeholder="请输入企业地址" />

      <van-field class="van-field-c" label="事项备注" v-model="baseForm.remark" placeholder="请输入事项备注"></van-field>
    </van-form>



    <van-form label-width="120px" class="form-card" validate-first ref="Principal" :show-error="false">
      <div class="form-card-content">
        <img class="form-card-icon" src="./assets/icon_1.png" alt="">
        <span class="form-card-span">委托人信息</span>
      </div>
      <van-field class="van-field-c" label="委托人姓名" v-model="baseForm.applyerName" placeholder="请输入委托人姓名" />
      <van-field class="van-field-c" label="委托人证件类型" placeholder="请选择委托人证件类型" v-model="baseForm.applyerCardType" readonly clearable @click="selectClick('applyerCardType')" right-icon="arrow-down" ></van-field>
      <van-field class="van-field-c" label="委托人证件号码" placeholder="请输入委托人证件号码" v-model="baseForm.applyerCardCode" name="applyerCardCode" :rules="formRules.applyerCardCode"></van-field>
      <van-field class="van-field-c" label="委托人手机号码" placeholder="请输入委托人手机号码" v-model="baseForm.applyerMobile"></van-field>
    </van-form>

    
    <!-- 动态表单部分 👇 -->
    <vantDynamicForm ref="dynamicData" :data="dynamicData" />
    <!-- 动态表单部分 👆 -->

    <div class="form-card">
      <div class="form-card-content">
        <img class="form-card-icon" src="./assets/icon_download.png" alt="">  
        <span class="form-card-span">
          请下载电子材料并盖章
        </span>
      </div>
      <van-cell class="van-field-c" title="电子材料下载" is-link @click="showDialog=true" />
    </div>
    <div class="form-card">
      <div class="form-card-content">
        <img class="form-card-icon" src="./assets/icon_3.png" alt="">  
        <span class="form-card-span">
          需要上传的材料
        </span>
      </div>
      <div class="material-list">
        <van-row>
          <van-col span="12">
            <span style="font-size:0.28rem;line-height:0.6rem;float: left;">上传方式</span>
          </van-col>
          <van-col span="12">
            <div class="button-group-c">
              <div class="elc-document" v-for="item in uploadType" :key="item.id" :class="{'pz-upload':uploadId===item.id}" @click="changeUploadType(item.id)">{{item.text}}</div>
            </div>
          </van-col>
        </van-row>
        <van-row gutter="10" style="margin-top:0.38rem;margin-bottom:0.2rem" type="flex">
          <van-col span="12" v-for="item in filterMaterialList" :key="item.materialId">
            <div class="upload-item">
              <van-uploader 
                v-model="fileList[item.materialId]" 
                :after-read="file=>{afterRead(file,item.materialId)}" 
                :before-delete="()=>{return beforeDelete(item.materialId)}"
                :capture="useCameraMode" 
                max-count="1" >
                <div class="uploader-icon-c"></div>
              </van-uploader>
              <p class="uploader-text">{{item.materialName}}</p>
            </div>
          </van-col>
        </van-row>
      </div>
    </div>

    <div class="submit-button-c" @click="handelSubmit()">
      下一步
    </div>


    <!-- dialog 输入邮箱 👇 -->
    <van-dialog v-model="showDialog" :showConfirmButton="false" :close-on-click-overlay="true">
      <span class="close-span" @click="showDialog=false">
        <van-icon name="cross" />
      </span>
      <div class="dialog-content">
        <p style="font-size:0.36rem; color:#333;margin:0">电子材料下载</p>
        <p style="margin-top:0.24rem;margin-bottom:0.4rem;color:#999;font-size:0.24rem;line-height:0.4rem">输入邮箱后我们会将电子材料发送至您的邮箱中请注意查收</p>
        <van-field class="v-field-c-email" placeholder="请输入您的邮箱地址" v-model="emailAddr"></van-field>

        <div class="submit-email-c" @click="sendEmail()">
          <van-loading type="spinner"  color="#fff" v-if="emailSendLoading">
            发送中...
          </van-loading>
          <span v-else>确 定</span> 
        </div>
      </div>
    </van-dialog>
    <!-- dialog 输入邮箱 👆 -->


    <!-- select 弹框 👇 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="pickerOptions" value-key="label" @confirm="onConfirm($event)" @cancel="onCancel"  />
    </van-popup>
    <!-- select 弹框 👆 -->

    <!-- 日期选择 👇 -->
    <van-popup v-model="showCalendar" position="bottom" :lazy-render="false">
      <van-datetime-picker
        type="date"
        title="选择日期"
        :value="new Date(1990,0,1)"
        :min-date="new Date(1930,0,1)"
        @confirm="selectDate($event)"
        @cancel="closeCalendar"
      />
    </van-popup>
    <!-- 日期选择 👆 -->

    <!-- loading 遮罩 👇 -->
    <van-overlay :show="showOverlay">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
        <van-loading type="spinner" vertical>提交中，请稍后...</van-loading>
      </div>
    </van-overlay>
    <!-- loading 遮罩 👆 -->
  </div>
</template>

<script>
  import {Cell, CellGroup, Form,Field,Picker,Radio, RadioGroup, Checkbox,CheckboxGroup, Popup, Row, Col,Dialog, Icon, Overlay, Loading, Uploader, DatetimePicker } from 'vant';
  import { Toast } from 'vant';
  import vantDynamicForm from '@/IntelligentReporting/components/vanDynamicForm.vue'
  import customRadio from '@/IntelligentReporting/components/customRadio.vue'
  import {parseTime,validateIdentifyId, validatePhone, validateOfficePhone,setRem, DebounceBy,validateEmail} from '@/utils/commonFunc.js'
  /**
   * 根据证件类型返回证件验证规则 必填
   * @param typeCode {string} 身份证号
   * @param isRequire {boolean} 是否必填 默认必填
   */
  const cardTypeValidate = (typeCode,isRequire=true)=>{
    let res;
    if(typeCode==='111'){
      res = [
        {required:isRequire,message:'请输入申请人证件号',trigger:'onBlur'},
        {
          validator:function(val){
            return validateIdentifyId(val)
          },
          message:'证件号码格式不正确',
          trigger:'onBlur'
        }
      ]
    }else{
      res = [{required:isRequire,message:'请输入申请人证件号',trigger:'onBlur'}]
    }
    return res;
  }
  export default {
    name:'formFill',
    components:{
      vantDynamicForm,

      vanCell:Cell,
      vanCellGroup:CellGroup,
      vanForm:Form,
      vanField:Field,
      vanPicker:Picker,
      vanRadio:Radio,
      vanRadioGroup:RadioGroup,
      vanCheckbox:Checkbox,
      vanCheckboxGroup:CheckboxGroup,
      vanPopup:Popup,
      vanRow:Row,
      vanCol:Col,
      vanDialog:Dialog.Component,
      vanIcon:Icon,
      vanOverlay:Overlay,
      vanLoading:Loading,
      vanUploader:Uploader,
      vanDatetimePicker:DatetimePicker,
      customRadio
    },
    data(){
      return{
        recvId:'',
        baseForm:{}, // 基础表单数据
        showPicker:false,
        pickerOptions: [],

        selectKey:'',
        /**个人证件类型 */
        personCardTypeList:[
          {label:'居民身份证',value:'111'},
          {label:'其他',value:'099'},
        ],
        enterpriseCardTypeList:[// 企业证件类型
          {label:'工商局登记号（营业执照）',value:'003'},
          {label:'其他',value:'099'},
        ],
        outputTypeList:[ // 出件方式
          {label:'窗口领取',value:'1'},
          {label:'公告送达',value:'2'},
          {label:'邮寄送达',value:'3'},
          {label:'网站下载',value:'4'},
          {label:'其他',value:'5'},
          {label:'直接送达',value:'6'},
          {label:'置留送达',value:'7'},
          {label:'自助柜',value:'8'},
          {label:'无需出件',value:'99'},
        ],
        enterpriseTypeList:[ // 企业类型
          {label:'中小企业',value:1},
          {label:'民营企业',value:2},
          {label:'私营企业',value:3},
          {label:'个体工商户',value:4},
          {label:'社会组织',value:5},
          {label:'国有企业',value:6},
          {label:'政府机构',value:7},
          {label:'其他',value:99},
        ],
        personDegreeList:[
          {value:'博士',label:'博士'},
          {value:'硕士',label:'硕士'},
          {value:'本科',label:'本科'},
          {value:'高中（大专）',label:'高中（大专）'},
          {value:'初中',label:'初中'},
          {value:'小学',label:'小学'},
          {value:'其他',label:'其他'},
        ],
        dynamicData:[], // 表单列表
        materialList:[], // 材料列表
        materialData:[], // 上传完以后的材料
        servType:2,
        topTabList:[
          {id:2,text:'企业申请'},
          {id:1,text:'个人申请'}
        ],
        uploadType:[
          {id:1,text:'拍照上传'},
          {id:2,text:'电子证照'}
        ],
        uploadId:1,
        useCameraMode:true,
        showDialog:false, // 展示弹窗
        showOverlay:false, // 展示遮罩
        fileList:{}, // 文件上传列表
        formRules:{
          applyerMobile:[
            {required:true,message:'请输入申请人手机号',trigger:'onBlur'},
            {
              validator:(val)=>{
                return validatePhone(val)
              },
              message:'请输入正确的手机号',
              trigger:'onBlur'
            }
          ],
          isAgent:[{required:true,message:'请选择申请人是否代理',trigger:'onChange'}],
          isLegal:[{required:true,message:'请选择申请人是否法人代表',trigger:'onChange'}],
          applyerName:[{required:true,message:'请输入申请人姓名',trigger:'onBlur'}],
          applyerCardType:[{required:true,message:'请选择申请人证件类型',trigger:'onChange'}],
          applyerSex:[{required:true,message:'请选择申请人性别',trigger:'onChange'}],
          isMaterialAll:[{required:true,message:'请选择是否已提交全部材料',trigger:'onChange'}],
          outputType:[{required:true,message:'请选择出件方式',trigger:'onChange'}],
          applyerCardCode:cardTypeValidate(null,false),
          servType:[
            {required:true,message:'请选择出件方式',trigger:'onChange'}
          ],

          personCardNum:cardTypeValidate(null,true),
          validatePhone:[
            {
              validator:(val)=>{
                return validatePhone(val)
              },
              message:'手机号格式错误',
              trigger:'onBlur'
            },
            {required:true,message:'请输入手机号',trigger:'onBlur'}
          ],
          validateFixPhone:[
            {
              required:false,
              validator:(val)=>{
                return validateOfficePhone(val)
              },
              message:'固定电话号码格式错误',
              trigger:'onBlur'
            }
          ],
          validateEmail:[
            {required:true,message:'请输入邮箱',trigger:'onBlur'},
            {
              validator:(val)=>{
                return validateEmail(val)
              },
              message:'请输入正确的邮箱地址',
              trigger:'onBlur'
            }
          ]
        },
        showCalendar:false, // 显示日历
        emailAddr:'', // 电子邮件地址
        emailSendLoading:false
      }
    },
    created(){
      this.getDynamicForm();

      /**获取接件id */
      this.$post('/template/generateRecvId').then(res=>{
        let {code,recvId} = res.data;
        if(code === 200){
          this.recvId = recvId;
        }
      })
    },
    mounted(){
      window.onresize = () => {
        return (() => {
          setRem(document)
        })();
      }
      this.$nextTick(()=>{
        document.title=this.$route.query.taskName;
        setRem(document);
        if(this.$route.query.skipUrl){
          sessionStorage.setItem('skipUrl',this.$route.query.skipUrl)
        }
      })
    },
    computed:{
      /** 筛选出没有系统生成材料的材料列表 */
      filterMaterialList(){
        return this.materialList.filter(item=>{
          return item.tempFileIds===''
        })
      }
    },
    methods:{
      /**获取动态表单 */
      getDynamicForm(){
        this.$post('/template/getTemplateByTaskId',{taskId:this.$route.query.taskId}).then(res=>{
          let {code,materialTempList,taskFieldList} = res.data;
          if(code===200){
            this.dynamicData = taskFieldList;
            this.materialList = materialTempList;
          }
        })
      },
      /** select类型的表单选择调起弹窗 */
      selectClick(t){
        this.showPicker=true;
        if(t==='applyerCardType' || t==='enterpriseLegalType' || t === 'personCardType'){
          this.pickerOptions = this.personCardTypeList;
        }else if(t==='enterpriseCardType'){
          this.pickerOptions = this.enterpriseCardTypeList;
        }else if(t==='outputType'){
          this.pickerOptions = this.outputTypeList
        }else if(t==='enterpriseType'){
          this.pickerOptions = this.enterpriseTypeList
        }else if(t==='personDegree'){
          this.pickerOptions = this.personDegreeList;
        }
        this.selectKey = t
      },

      /** 确认选择select */
      onConfirm(value) {
        this.baseForm[this.selectKey] = value.label;

        if(this.selectKey==='applyerCardType'){
         this.formRules.applyerCardCode = cardTypeValidate(value.value,false);
         this.$refs.Principal.resetValidation('applyerCardCode')
        }
        if(this.selectKey === 'enterpriseLegalType' || this.selectKey==='personCardType'){
          this.formRules.personCardNum = cardTypeValidate(value.value,true);
          if(this.selectKey === 'enterpriseLegalType'){
            this.$refs.enterForm.resetValidation('enterpriseLegalIdcard');
          }else{
            this.$refs.applyerInfo.resetValidation('personCardNum');
          }
        }

        this.showPicker = false;
        this.$nextTick(()=>{
          this.pickerOptions=[];
          this.selectKey=''
        })
      },
      onCancel(){
        this.pickerOptions=[];
        this.selectKey='';
        this.showPicker = false;
      },
      openCalendar(value){
        this.selectKey = value;
        this.showCalendar=true;
      },
      selectDate(date){
        this.baseForm[this.selectKey] = parseTime(date,'{y}-{m}-{d}');
        this.showCalendar=false;
        this.$nextTick(()=>{
          this.selectKey = ''
        })
      },
      closeCalendar(){
        this.selectKey='';
        this.showCalendar=false;
      },
      /**上传按钮 */
      handelSubmit(){
        // 判断是否提交全部材料
        if(Object.keys(this.fileList).length===this.filterMaterialList.length){
          this.baseForm.isMaterialAll = 1
        }else{
          this.baseForm.isMaterialAll = 0
        }

        let refName = this.servType===1?'applyerInfo':'enterForm';

        this.$refs[refName].validate().then(()=>{
          this.submitData()
        }).catch(err=>{
          Toast(err[0].message)
        })
      },
      submitData(){
        this.$refs.dynamicData.validateVanForm().then(()=>{
          this.showOverlay=true; // 打开loading遮罩
          this.baseForm.isAgent = this.baseForm.applyerName?1:0;
          let param = {
            taskId:this.$route.query.taskId,
            taskName:this.$route.query.taskName,
            recvData:{...this.baseForm},
            fillData:this.$refs.dynamicData.newFormObj,
            materialData:this.materialData,
            recvId:this.recvId
          }
          if(this.servType===2){
            param.recvData.applyerCardCode = param.recvData.applyerCardCode?param.recvData.applyerCardCode:param.recvData.enterpriseCardNum

            param.recvData.applyerName = param.recvData.applyerName ? param.recvData.applyerName : param.recvData.enterpriseLegalName;
            
            param.recvData.applyerMobile = param.recvData.applyerMobile ? param.recvData.applyerMobile : param.recvData.enterpriseContactMobile;


            // 通过select的name查找value
            if(param.recvData.applyerCardType){
              param.recvData.applyerCardType = this.returnEnumValue(param.recvData.applyerCardType,this.personCardTypeList);            
            }else{
              param.recvData.applyerCardType= this.returnEnumValue(param.recvData.enterpriseCardType,this.enterpriseCardTypeList);
            }
          }else{
            param.recvData.applyerCardCode = param.recvData.applyerCardCode?param.recvData.applyerCardCode:param.recvData.personCardNum

            param.recvData.applyerName = param.recvData.applyerName ? param.recvData.applyerName : param.recvData.personName;
            
            param.recvData.applyerMobile = param.recvData.applyerMobile ? param.recvData.applyerMobile : param.recvData.personMobile;

            // 通过select的name查找value
            if(param.recvData.applyerCardType){
              param.recvData.applyerCardType = this.returnEnumValue(param.recvData.applyerCardType,this.personCardTypeList);            
            }else{
              param.recvData.applyerCardType= this.returnEnumValue(param.recvData.personCardType,this.personCardTypeList);
            }
          }



          param.recvData.enterpriseType = this.returnEnumValue(param.recvData.enterpriseType,this.enterpriseTypeList);

          param.recvData.enterpriseLegalType = this.returnEnumValue(param.recvData.enterpriseLegalType,this.personCardTypeList);

          param.recvData.enterpriseCardType = this.returnEnumValue(param.recvData.enterpriseCardType,this.enterpriseCardTypeList);

          param.recvData.personCardType = this.returnEnumValue(param.recvData.personCardType,this.personCardTypeList);

          this.$postForJson('/template/saveFillRecvToRedis',param).then(res=>{
            let {code,msg} = res.data;
            if(code === 200){
              this.showOverlay=false;
              Toast('提交成功');
              setTimeout(()=>{
                this.$router.push({name:'materialConfirm',query:{recvId:this.recvId,taskId:this.$route.query.taskId,taskName:this.$route.query.taskName}})
              },1000)
            }else{
              this.showOverlay=false;
              this.$nextTick(()=>{
                if(code === 2001){
                  Toast.fail(msg);
                }else{
                  Toast(msg)
                }
              })
            }
          }).catch(()=>{
            this.showOverlay = false;
            this.$nextTick(()=>{
              Toast.fail('网络错误')
            })
          })
        }).catch(err=>{
          Toast(err[0].message)
        })
      },
      /**
       * 根据 label 返回枚举的 value
       */
      returnEnumValue(label,arr){
        if(label){
          let obj = arr.find(i=>i.label===label);
          return obj.value;
        }else{
          return ''
        }
      },
      /**
       * 文件读取成功后的回调函数
       * @param file 文件实例
       * @param id 材料id
       */
      afterRead(file,id){
        this.fileList[id][0].status='uploading';
        this.fileList[id][0].message = '上传中'
        let formData = new FormData();
        formData.append('file',file.file);
        formData.append('userId','1234')
        this.$post('/acceptFile/uploadFile',formData,{headers:{'Content-Type':'multipart/form-data'}}).then(res=>{
          let {msg,code,data} = res.data;
          if(code === 200){
            this.fileList[id][0].status='done';
            this.fileList[id][0].message = ''
            if(this.materialData.length===0){
              this.materialData.push({materialId:id,fileIds:[data]})
            }else{
              let res = this.materialData.find(item=>{
                if(item.materialId === id){
                  return item
                }
              })
              if(res){
                res.fileIds = [data];
              }else{
                this.materialData.push({materialId:id,fileIds:[data]})
              }
            }
          }else{
            this.fileList[id][0].status='failed';
            this.fileList[id][0].message = '上传失败'
          }
        })
      },
      /**删除上传的文件 */
      beforeDelete(id){
        let res = this.materialData.find(item=>{
          return item.materialId===id
        })
        res.fileIds = [];
        return true
      },
      /**
       * 切换上传模式
       * @param id 1 拍照 2 电子证照
       */
      changeUploadType(id){
        this.uploadId=id;
        this.useCameraMode = id===1?true:false;
      },
      /**
       * 法定代表人类型变更时的回调
       * @param name 1 个人 2 企业
       */
      servTypeChange(name){
        this.baseForm.servType = name;
        if(name=='1'){
          delete this.baseForm.enterpriseName
          delete this.baseForm.enterpriseType
          delete this.baseForm.enterpriseLegalName
          delete this.baseForm.enterpriseLegalType
          delete this.baseForm.enterpriseLegalIdcard
          delete this.baseForm.enterpriseCardType
          delete this.baseForm.enterpriseCardNum
          delete this.baseForm.enterpriseContactName
          delete this.baseForm.enterpriseContactMobile
          delete this.baseForm.enterpriseContactFixed
          delete this.baseForm.enterpriseContactEmail
          delete this.baseForm.enterpriseContactAddr
          this.$refs.enterForm.resetValidation()
        }else{
          delete this.baseForm.personName
          delete this.baseForm.personSex
          delete this.baseForm.personBirth
          delete this.baseForm.personCardType
          delete this.baseForm.personCardNum
          delete this.baseForm.personBirthPlace
          delete this.baseForm.personMobile
          delete this.baseForm.personFixed
          delete this.baseForm.personIsForeign
          delete this.baseForm.personDegree
          delete this.baseForm.personContactName
          delete this.baseForm.personContactMobile
          delete this.baseForm.personContactEmail
          delete this.baseForm.personContactAddr
          delete this.baseForm.personNationality;
          this.$refs.applyerInfo.resetValidation()

        }
      },
      /**
       * 变更是否外籍
       * @param {string} name 1 是 0 否
       */
      personIsForeignChange(name){
        console.log(name)
        if(name==='0'){
          delete this.baseForm.personNationality
        }
      },
      /**发送邮件 */
      sendEmail:DebounceBy(function(){
        if(this.emailAddr===''){
          Toast('请输入邮箱地址')
        }else{
          if(!this.emailSendLoading){
            this.emailSendLoading=true;
            this.$postForJson('/template/sendEmail',{email:this.emailAddr,taskId:this.$route.query.taskId}).then(res=>{
              this.emailSendLoading=false
              let {code} = res.data;
              if(code===200){
                Toast('✔ 电子材料已发送，请注意查收');
                this.emailAddr=''
                this.showDialog=false;
              }else{
                Toast.fail('邮件发送失败')
              }
            }).catch(()=>{
              Toast.fail('服务器连接失败')
              this.emailSendLoading=false;
            })            
          }
        }
      },1000)
    }
  }
</script>

<style lang="less" scoped>
.form-fill{
  padding: 0 0.24rem 0.01rem;
  .top-tab{
    margin-top: 0.4rem;
    margin-bottom: 0.32rem; 
    display: flex;
    padding: 0 0.24rem;
    .active-class{
      background-color: #fff;
      color:#6F8DE6 !important;
    }
    .top-tab-item{
      border-top: 1px solid #fff;
      border-bottom:1px solid #fff;
      width: 50%;
      font-size:0.35rem;
      line-height: 0.72rem;
      text-align: center;
      color: #fff;
      &:first-child{
        border-left: 1px solid #fff;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      &:last-child{
        border-right: 1px solid #fff;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }
  }
  .form-card{
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    .form-card-content{
      height:0.9rem;width: 100%;display:flex;align-items:center;    
      .form-card-icon{
        width: 0.28rem;
      }
      .form-card-span{
        font-size: 0.3rem;
        line-height: 0.9rem;
        margin-left:0.16rem;
        color:#333;
        font-weight: bold;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
}
.van-field-c{
  padding: 0.2rem 0.05rem;
}
.v-field-c-email{
  border: 1px solid #D3D3D3;
  border-radius: 10px;
}
.van-field-c.van-cell--required::before{
  left: -5px;
}
.van-field-c{
  &::after{
    left: 0;
    right: 0;
    border-bottom: 1px dashed #D3D3D3;
  }
  &:last-child{
    &::after{
      border-bottom: none;
    }
  }
}
.button-group-c{
  display: flex;
  justify-content: space-around;
  font-size: 0.28rem;
  line-height: 0.6rem;
  text-align: center;
  .pz-upload, .elc-document{
    width: 45%;
    border-radius: 10px;
  }
 .pz-upload{
    background-color: #6F8DE6;
    color:#fff !important;
  }
  .elc-document{
    border: 1px solid #6F8DE6;
    color:#6F8DE6;
  } 
}
.submit-button-c{
  line-height: 0.8rem;
  width: 4.8rem;
  text-align: center;
  background-color: #6F8DE6;
  border-radius: 10px;
  font-size: 0.33rem;
  color:#fff;
  margin:0.48rem auto 0.78rem;
}
 .submit-email-c{
    line-height: 0.8rem;
    text-align: center;
    background-color: #6F8DE6;
    border-radius: 10px;
    font-size: 0.33rem;
    color:#fff;
    margin-top: 0.48rem;
 }
.dialog-content{
  text-align: center;
  padding: 0.5rem;
}
.close-span{
  position: absolute;
  top:0.25rem;
  right:0.25rem;
}
.wrapper-c{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.upload-item{
  display: flex;
  flex-direction: column;
  align-items: center;
  .uploader-icon-c{
    width:3.12rem;
    height: 2.34rem;
    background-image:url('./assets/add.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size:0.8rem;
    border:1px dashed #d3d3d3;
    border-radius: 10px;
  }
  .uploader-text{
    font-size:0.26rem;color:#333;text-align:center;margin:0;
    margin: 0.24rem 0;
  }
}
</style>
<style lang="less">
body{
  background-image:url('./assets/taiyuan_theme_bg.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #EDEDED;
}

</style>