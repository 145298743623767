<template>
  <div class="report-submit">
    <van-form class="form-card" label-width="120px">
      <div class="form-card-content">
        <img class="form-card-icon" src="./assets/icon_5.png" alt="">  
        <span class="form-card-span">
          材料提交
        </span>
      </div>
      <van-field class="van-field-c" label="材料提交途径" readonly>
        <template #input>
          <van-radio-group value="2" direction="horizontal">
            <van-radio name="1">上门取件
              <template #icon="p">
                <custom-radio :checked="p.checked"></custom-radio>
              </template>
            </van-radio>
            <van-radio name="2">现场办理
              <template #icon="p">
                <custom-radio :checked="p.checked"></custom-radio>
              </template>
            </van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div v-if="false">
        <van-field class="van-field-c" label="姓名" placeholder="请输入联系人姓名"></van-field>
        <van-field class="van-field-c" label="手机号" placeholder="请输入联系人手机号"></van-field>
        <van-field class="van-field-c" label="地址" placeholder="请输入联系人地址"></van-field>        
      </div>
      <van-cell class="van-field-c" title="办理地址" :value="guidesObject.transactaddr"></van-cell>
      <van-cell class="van-field-c" title="工作时间" :value="guidesObject.transacttime"></van-cell>
    </van-form>

    <div class="submit-button-c" @click="submitData()">
      提 交
    </div>


    <!-- loading 遮罩 👇 -->
    <van-overlay :show="showOverlay">
      <div class="wrapper-c">
        <van-loading type="spinner" vertical>提交中，请稍后...</van-loading>
      </div>
    </van-overlay>
    <!-- loading 遮罩 👆 -->

    <!-- 提交成功后的弹窗提示 👇 -->
    <van-dialog v-model="showDialog" :showConfirmButton="false" :close-on-click-overlay="true" @closed="judgeRouter()">
      <span class="close-span" @click="()=>{showDialog=false}">
        <van-icon name="cross" />
      </span>
      <div class="dialog-content">
        <img src="./assets/success.png" style="width:1.28rem" alt="提交成功">
        <p style="font-size:0.36rem; color:#6F8DE6;margin:0">申报成功</p>
        <p style="font-size:0.24rem;color:#666;margin-top:0.24rem;margin-bottom:0.39rem">您已申报成功，我们正在加速处理</p>
        <p style="margin:0;color:#999;font-size:0.24rem;line-height:0.4rem">{{guidesObject.deptname}}，工作时间：
          <br>
          {{guidesObject.transacttime}}</p>
      </div>
    </van-dialog>
    <!-- 提交成功后的弹窗提示 👆 -->
  </div>
</template>

<script>
  import {Form,Field,RadioGroup,Radio, Cell, Overlay,Loading,Dialog, Toast, Icon} from 'vant'

  import {setRem} from '@/utils/commonFunc.js'
  import customRadio from '@/IntelligentReporting/components/customRadio.vue'
  export default {
    name:'reportSubmit',
    components:{
      vanForm:Form,
      vanField:Field,
      vanRadioGroup:RadioGroup,
      vanRadio:Radio,
      vanCell:Cell,
      vanOverlay:Overlay,
      vanLoading:Loading,
      vanDialog:Dialog.Component,
      vanIcon:Icon,
      customRadio
    },
    data(){
      return {
        baseForm:{},
        showOverlay:false,
        showDialog:false,
        transactaddr:'',
        transacttime:'',
        guidesObject:{}
      }
    },
    created(){
      this.getAddress()
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          setRem(document)
        })();
      }
      this.$nextTick(()=>{
        document.title=this.$route.query.taskName;
        setRem(document);
      })
    },
    methods:{
      getAddress(){
        this.$post('/template/getItemByIdTask',{taskId:this.$route.query.taskId}).then(res=>{
          console.log(res.data);
          let {code,guidesObject,msg} = res.data;
          if(code===200){
            this.guidesObject = guidesObject;
          }else{
            Toast(msg)
          }
        })
      },
      submitData(){
        this.showOverlay = true;
        let params = {recvId:this.$route.query.recvId,taskId:this.$route.query.taskId,outputType:1}
        this.$postForJson('/template/taskRecv',params).then(res=>{
          let {code,msg} = res.data;
          if(code===200){
            this.showOverlay = false;
            this.showDialog = true;
          }else{
            this.showOverlay=false;
            this.$nextTick(()=>{
              if(code === 2001){
                Toast.fail(msg);
              }else{
                Toast(msg)
              }
            })
          }
        }).catch(()=>{
          this.showOverlay=false;
          this.$nextTick(()=>{
            Toast.fail(msg);
          })
        })
      },
      judgeRouter(){
        let skipUrl = sessionStorage.getItem('skipUrl');
        if(skipUrl){
          window.open(skipUrl,'_self');
        }else{
          this.$router.push('mattersList');
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.report-submit{
  padding: 0.24rem;
  .form-card{
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    .form-card-content{
      height:0.9rem;width: 100%;display:flex;align-items:center;    
      .form-card-icon{
        width: 0.28rem;
      }
      .form-card-span{
        font-size: 0.3rem;
        line-height: 0.9rem;
        margin-left:0.16rem;
        color:#333;
        font-weight: bold;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .van-field-c{
    padding: 0.2rem 0.05rem;
    &::after{
      left: 0;
      right: 0;
      border-bottom: 1px dashed #D3D3D3;
    }
    &:last-child{
      &::after{
        border-bottom: none;
      }
    }
  }
  .submit-button-c{
    line-height: 0.8rem;
    width: 4.8rem;
    text-align: center;
    background-color: #6F8DE6;
    border-radius: 10px;
    font-size: 0.33rem;
    color:#fff;
    margin:0.48rem auto 0.78rem;
  }
  /* dialog & loading */
  .wrapper-c{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .close-span {
    position: absolute;
    top: 0.25rem;
    right: 0.25rem;

  }
  .dialog-content {
    text-align: center;
    padding: 0.5rem 0.2rem;
  }
  /* dialog & loading */
}
</style>

<style lang="less">
body{
  background-image:url('./assets/taiyuan_theme_bg.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #EDEDED;
}
</style>