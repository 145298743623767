var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dynamic-submit" },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-row",
            [
              _c("el-col", [
                _c(
                  "span",
                  {
                    staticClass: "back",
                    on: {
                      click: function($event) {
                        return _vm.$router.go(-1)
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "el-icon-arrow-left" }),
                    _vm._v(" 返回 ")
                  ]
                )
              ]),
              _c("el-col", [
                _c("h3", { staticClass: "title" }, [_vm._v("确认上传信息")])
              ]),
              _c(
                "el-col",
                { staticClass: "card-items" },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.tableData, border: "" } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          width: "80",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "materialName",
                          label: "材料名称",
                          width: "180",
                          align: "center"
                        }
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "数量要求",
                          width: "180",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(scope) {
                              return [
                                scope.row.mSource == 10
                                  ? _c("span", [_vm._v("申请人自备、")])
                                  : scope.row.mSource == 20
                                  ? _c("span", [_vm._v("政府部门核发、")])
                                  : scope.row.mSource == 30
                                  ? _c("span", [
                                      _vm._v("中介机构或法定机构产生、")
                                    ])
                                  : _vm._e(),
                                scope.row.isOriginal == 1
                                  ? _c("span", [
                                      _vm._v(
                                        "正本原件" +
                                          _vm._s(scope.row.originalNum) +
                                          "份；"
                                      )
                                    ])
                                  : _vm._e(),
                                scope.row.isCopy == 1
                                  ? _c("span", [
                                      _vm._v(
                                        "正本复印件" +
                                          _vm._s(scope.row.copyNum) +
                                          "份；"
                                      )
                                    ])
                                  : _vm._e(),
                                scope.row.isDuplicateOriginal == 1
                                  ? _c("span", [
                                      _vm._v(
                                        "副本原件" +
                                          _vm._s(scope.row.duplicateNum) +
                                          "份；"
                                      )
                                    ])
                                  : _vm._e(),
                                scope.row.isDuplicateCopy == 1
                                  ? _c("span", [
                                      _vm._v(
                                        "副本复印件" +
                                          _vm._s(scope.row.duplicateCopyNum) +
                                          "份；"
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: { label: "文件名称", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(s) {
                              return _vm._l(s.row.fileIds, function(item) {
                                return _c(
                                  "p",
                                  {
                                    key: item.fileId,
                                    staticClass: "file-name"
                                  },
                                  [_vm._v(" " + _vm._s(item.fileNameUp) + " ")]
                                )
                              })
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "address",
                          label: "材料要求",
                          align: "center"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(s) {
                              return [
                                s.row.mMedia == 1
                                  ? _c("span", [_vm._v("纸质")])
                                  : s.row.mMedia == 2
                                  ? _c("span", [_vm._v("电子")])
                                  : s.row.mMedia == 3
                                  ? _c("span", [_vm._v("纸质、电子")])
                                  : _vm._e()
                              ]
                            }
                          }
                        ])
                      }),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          width: "210",
                          label: "材料预览"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(s) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "btn",
                                    attrs: {
                                      type: "primary",
                                      icon: "el-icon-zoom-in",
                                      plain: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.viewFile(s.row)
                                      }
                                    }
                                  },
                                  [_vm._v("预览")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.thePlatform === "mobile"
                ? _c(
                    "el-col",
                    [
                      _c("span", { staticClass: "esign-tips" }, [
                        _vm._v(
                          "我承诺所有申报信息真实有效，自愿承担法律责任，请确认签字："
                        )
                      ]),
                      _c("el-card", { staticClass: "card-items esign-area" }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "text-align": "right",
                              "margin-top": "10px"
                            },
                            attrs: { slot: "header" },
                            slot: "header"
                          },
                          [
                            _c(
                              "el-button",
                              {
                                on: {
                                  click: function($event) {
                                    return _vm.resetEsign()
                                  }
                                }
                              },
                              [_vm._v("重 置")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "margin-top": "10px", zoom: "1" } },
                          [
                            _c("vue-esign", {
                              ref: "esign",
                              staticClass: "esign-style",
                              attrs: { isCrop: true }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-col",
                [
                  _c(
                    "el-form",
                    { attrs: { "label-width": "120px", model: _vm.baseForm } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "出件方式" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value: _vm.baseForm.outputType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.baseForm,
                                            "outputType",
                                            $$v
                                          )
                                        },
                                        expression: "baseForm.outputType"
                                      }
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("政务中心取件")
                                      ]),
                                      _c("el-radio", { attrs: { label: 3 } }, [
                                        _vm._v("EMS快递")
                                      ]),
                                      _c("el-radio", { attrs: { label: 5 } }, [
                                        _vm._v("无人政务车取件")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.baseForm.outputType === 3
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "收件人" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.emsForm.emsOutputRecvName,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.emsForm,
                                                  "emsOutputRecvName",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emsForm.emsOutputRecvName"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "手机号" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.emsForm.emsOutputRecvPhone,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.emsForm,
                                                  "emsOutputRecvPhone",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emsForm.emsOutputRecvPhone"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "邮编" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.emsForm
                                                  .emsOutputRecvLocalCode,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.emsForm,
                                                  "emsOutputRecvLocalCode",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emsForm.emsOutputRecvLocalCode"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "所在地区" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.emsForm.emsOutputRecvAddr,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.emsForm,
                                                  "emsOutputRecvAddr",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emsForm.emsOutputRecvAddr"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 24 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { label: "详细地址" } },
                                        [
                                          _c("el-input", {
                                            model: {
                                              value:
                                                _vm.emsForm.emsOutputRecvMore,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.emsForm,
                                                  "emsOutputRecvMore",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "emsForm.emsOutputRecvMore"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.baseForm.outputType === 1
                            ? _c(
                                "div",
                                { staticStyle: { "margin-left": "120px" } },
                                [
                                  _c("p", [
                                    _vm._v(_vm._s(_vm.address.managerDept))
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "办理地址：" +
                                        _vm._s(_vm.address.transactaddr)
                                    )
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "工作时间：" +
                                        _vm._s(_vm.address.transacttime)
                                    )
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      "咨询电话：" + _vm._s(_vm.address.linkTel)
                                    )
                                  ])
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center", "margin-top": "20px" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "submitBtn",
                      attrs: { type: "primary", loading: _vm.submitLoading },
                      on: { click: _vm.handelSubmit }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.esignStatus ? "提 交" : "确认签字") +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("publicLogin", {
        attrs: { visible: _vm.loginDialogControl },
        on: {
          "update:visible": function($event) {
            _vm.loginDialogControl = $event
          }
        }
      }),
      _vm.dialogVisible
        ? _c("div", { staticClass: "submit-dialog" }, [
            _c(
              "div",
              { staticClass: "submit-dialog-content" },
              [
                _c("el-image", {
                  staticClass: "success-img",
                  attrs: {
                    src: require("../assets/unmanned_vehicle/success.png"),
                    fit: "cover"
                  }
                }),
                _c("p", { staticClass: "dialogSpa" }, [_vm._v("申报成功")]),
                _c("p", { staticClass: "dialog-tip" }, [
                  _vm._v("您已申报成功，我们正在加速办理")
                ]),
                _c("p", { staticClass: "dialog-tip" }, [
                  _vm._v("若遇到问题，请向客服求助：010-52315198")
                ]),
                _c(
                  "div",
                  {
                    staticClass: "submit-dialog-content-button",
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.goHome()
                      }
                    }
                  },
                  [_vm._v(" 办件结束，回到首页 ")]
                )
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.fileConfirmDialog
        ? _c("div", { staticClass: "file-confirm-dialog" }, [
            _c("div", { staticClass: "file-confirm-dialog-content" }, [
              _c("p", { staticClass: "content-header" }, [
                _vm._v("请将以下材料放置在打开的柜门内")
              ]),
              _c(
                "div",
                { staticClass: "file-list" },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.confirmFileInBox,
                        callback: function($$v) {
                          _vm.confirmFileInBox = $$v
                        },
                        expression: "confirmFileInBox"
                      }
                    },
                    _vm._l(_vm.mMediaList, function(item) {
                      return _c(
                        "div",
                        { key: item.materialId, staticClass: "file-list-item" },
                        [
                          _c("p", [_vm._v(_vm._s(item.materialName))]),
                          _c(
                            "el-checkbox",
                            {
                              staticClass: "file-list-item-checkbox",
                              attrs: { label: item.materialId }
                            },
                            [_vm._v("已放置")]
                          )
                        ],
                        1
                      )
                    }),
                    0
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "file-confirm-button",
                  on: { click: _vm.getUpData }
                },
                [_vm._v("放置完成，提交")]
              )
            ])
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible2, width: "90%", fullscreen: "" },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible2 = $event
            }
          }
        },
        [
          _c("iframe", {
            staticClass: "iframe",
            attrs: {
              src: _vm.pdfSrc,
              frameborder: "0",
              width: "100%",
              height: "100%"
            }
          })
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }