// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/unmanned_vehicle/standby_bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/unmanned_vehicle/word.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".index-page[data-v-a83bd3b0] {\n  width: 100vw;\n  height: 100vh;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: center;\n}\n.index-page .index-page-header-content[data-v-a83bd3b0] {\n  padding-top: 0.29861rem;\n}\n.index-page .index-page-header[data-v-a83bd3b0] {\n  height: calc(0.375rem*0.75);\n  display: flex;\n  margin-left: calc(0.41667rem*0.75);\n  margin-right: calc(0.41667rem*0.75);\n  align-items: center;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: contain;\n  background-position: center;\n}\n.index-page .index-page-header .header-time[data-v-a83bd3b0] {\n  font-size: calc(0.22222rem*0.75);\n  color: #fff;\n}\n.index-page .index-page-content[data-v-a83bd3b0] {\n  height: calc(100vh - 0.70833rem);\n  width: 100vw;\n  position: relative;\n}\n.index-page .index-page-content .content-image[data-v-a83bd3b0] {\n  width: 100%;\n  height: calc(1.09722rem*0.75);\n  position: absolute;\n  bottom: calc(0.33333rem*0.75);\n}\n", ""]);
// Exports
module.exports = exports;
