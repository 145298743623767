<template>
  <div class="doSavea">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
      </div>
      <div class="boxHeader-center">密码登录</div>
      <div class="boxHeader-right">
        <img src="@/assets/unmanned_vehicle/handle_countdown.png" alt="" />
        <p>倒计时：{{countdown}}s</p>
      </div>
    </div>
    <div class="boxContent">
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item>
          <img
            src="@/assets/unmanned_vehicle/storage_signin_phone.png"
            alt=""
            class="imgUrlAll"
          />
          <el-input
            v-model="formInline.user"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="border-bor"></div>
        </el-form-item>
        <el-form-item>
          <img
            src="@/assets/unmanned_vehicle/storage_signin_lock1.png"
            alt=""
            class="imgUrlAll"
          />
          <el-input
	          type="password"
            v-model="formInline.region"
            placeholder="请输入密码"
	          @keyup.native.enter="onSubmit"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "doSavea",
  data() {
    return {
      formInline: {
        user: "",
        region: "",
      },
	    countDownTimer:0,
	    countdown:120
    };
  },
	mounted() {
		this.countDownTimer = setInterval(()=>{
			if(this.countdown===0){
				clearInterval(this.timer);
				this.$router.push("/mainIndex");
			}else{
				this.countdown--;
			}
		},1000)
	},
	beforeDestroy() {
		clearInterval(this.countDownTimer)
	},
	methods: {
    /**返回 */
    black() {
      this.$router.push("/mainIndex");
    },
    onSubmit() {
			this.$get('/CarCabinet/wrclogin',{params:{loginName:this.formInline.user,loginPass:this.formInline.region}}).then(res=>{
				let resData = res.data;
				if(resData.code===200){
					this.$message.success('登录成功')
					sessionStorage.setItem('user',JSON.stringify(resData.user));
					this.$router.push({path:'/parcel'})
				}else{
					this.$message({
						type:'warning',
						message:resData.msg
					})
				}
			})
    },
  },
};
</script>

<style lang="less" scoped>
@baseZoom:0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.doSavea {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/1.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    width: calc(160px * @baseZoom);
    height: calc(72px*@baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(10px * @baseZoom);
    margin-left: calc(60px * @baseZoom);
    margin-top: calc(24px*@baseZoom);
    font-size: calc(32px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(72px*@baseZoom);
  }
  .boxHeader-center {
    font-size: calc(48px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    line-height: 100%;
    margin-top: calc(70px*@baseZoom);
  }
  .boxHeader-right {
    width: calc(260px*@baseZoom);
    height: calc(72px*@baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(36px*@baseZoom);
    margin-top: calc(24px*@baseZoom);
    margin-right: calc(60px*@baseZoom);
    display: flex;
    align-items: center;
    img {
      width: calc(28px*@baseZoom);
      height: calc(32px*@baseZoom);
      margin-left: calc(25px*@baseZoom);
      margin-right: calc(11px*@baseZoom);
      margin-bottom: calc(-2px*@baseZoom);
    }
    p {
      font-size: calc(28px*@baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
    }
  }
}
.boxContent {
  //width: calc(73%*@baseZoom);
	width: 73%;
  height: calc(280px*@baseZoom);
  background: url(../assets/unmanned_vehicle/takeparts_inputbg.png) no-repeat;
  background-size: 100% 100%;
  margin: auto;
  margin-top: calc(45px*@baseZoom);
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-form {
  display: flex;
  align-items: center;
}
/deep/.el-form-item__content {
  display: flex;
}
.imgUrlAll {
  width: calc(36px*@baseZoom);
  height: calc(42px*@baseZoom);
}
/deep/.el-input__inner {
  width: calc(310px*@baseZoom);
  border: none;
  height: calc(42px*@baseZoom);
  font-size: calc(40px*@baseZoom);
  font-weight: 400;
  color: #141414;
}
.border-bor {
  width: 1px;
  height: calc(140px*@baseZoom);
  background: #aaaccf;
  border-radius: 1px;
  margin-right: calc(145px*@baseZoom);
  margin-left: calc(100px*@baseZoom);
}
.demo-form-inline{
	& /deep/ .el-form-item{
		margin-bottom: 0;
	}
}
</style>