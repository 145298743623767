<template>
  <div class="main-index">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <img src="@/assets/unmanned_vehicle/logo.png" class="boxHeader-left-logo" alt="" />
        <p>
	        <timer-component />
        </p>
      </div>
       <div class="boxHeader-right" @click="centerDialogVisible = true">
        <img src="@/assets/unmanned_vehicle/homepage_helphandle.png" alt="" />
        <p>视频帮办</p>
      </div>
    </div>
    <div class="boxContent">
      <div class="boxContent-left" @click="goPiece">
        <img src="@/assets/unmanned_vehicle/homepage_module1_word.png" alt="" />
        <p>快速办理热门事项</p>
      </div>
      <div class="boxContent-right">
        <div class="cunJian" @click="goDoSavea">
          <img
            src="@/assets/unmanned_vehicle/homepage_module2_word.png"
            alt=""
          />
          <p>轻松放置办理所需文件</p>
        </div>
        <div class="quJian" @click="goCollect">
          <img
            src="@/assets/unmanned_vehicle/homepage_module3_word.png"
            alt=""
          />
          <p>随时随地一键取出</p>
        </div>
      </div>
    </div>
    <ul class="boxFooter">
      <li>
        <img src="@/assets/unmanned_vehicle/homepage_location.png" alt="" />
        <p>当前位置：北京市丰台区六里桥奈伦大厦</p>
      </li>
      <li>
        <img src="@/assets/unmanned_vehicle/homepage_phone.png" alt="" />
        <p>客服电话：010-82318888</p>
      </li>
      <li>
        <img src="@/assets/unmanned_vehicle/homepage_technology.png" alt="" />
        <p>技术支持：北京新广视通科技集团有限责任公司</p>
      </li>
      <li>
        <img src="@/assets/unmanned_vehicle/homepage_edition.png" alt="" />
        <p>版本号：V1.0</p>
      </li>
    </ul>

    <!-- 视频帮办弹框 -->
    <div class="elDialogAll" v-show="centerDialogVisible">
      <div class="elDialogAllCenter">
        <img src="@/assets/unmanned_vehicle/videocall.png" alt="" />
        <p>您将进行视频通话，确认开启帮办吗？</p>
        <div class="btn">
          <el-button class="btn-quXiao" @click="centerDialogVisible = false">取消</el-button>
          <el-button type="primary" class="btn-queDing" @click="notarize">确认</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timerComponent from "@/components/timerComponent";
export default {
  name: "mainIndex",
	components:{
		timerComponent
	},
  data() {
    return {
      centerDialogVisible: false,
    };
  },
	methods:{
    /**跳转视频帮办 */
   notarize(){
	   this.centerDialogVisible = false;
	   this.$router.push('/communicate');
   },
   /**跳转办件 */
   goPiece(){
	   this.$router.push('/doPiece');
   },
    /**跳转存件 */
   goDoSavea(){
    this.$router.push('/doSavea');
   },
   /**跳转取件 */
   goCollect(){
     this.$router.push('/collectAparcel');
   }
  }
};
</script>

<style lang="less" scoped>
@baseZoom:0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.main-index {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/homepage_bg.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    display: flex;
    p {
      font-size: calc(24px*@baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(60px*@baseZoom);
      margin-left: calc(31px*@baseZoom);
    }
	  .boxHeader-left-logo{
		  width: calc(390px*@baseZoom);
		  height: calc(120px*@baseZoom);
	  }
  }
  .boxHeader-right {
	  cursor: pointer;
    width: calc(214px*@baseZoom);
    height: calc(72px*@baseZoom);
    background: rgba(255, 255, 255, 0.5);
    border-radius: calc(10px*@baseZoom);
    margin-right: calc(60px*@baseZoom);
    margin-top: calc(23px*@baseZoom);
    display: flex;
    img {
      width: calc(36px*@baseZoom);
      height: calc(32px*@baseZoom);
      margin-top: calc(20px*@baseZoom);
      margin-left: calc(21px*@baseZoom);
    }
    p {
      font-size: calc(32px*@baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(20px*@baseZoom);
      margin-left: calc(9px*@baseZoom);
    }
  }
}
.boxContent {
  height: calc(785px*@baseZoom);
  margin: auto;
  margin-top: calc(67px*@baseZoom);
  display: flex;
  .boxContent-left {
    width: calc(694px*@baseZoom);
    height: calc(785px*@baseZoom);
    background: url(../assets/unmanned_vehicle/homepage_module1.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    img {
      width: calc(182px*@baseZoom);
      height: calc(92px*@baseZoom);
      position: absolute;
      top: calc(73px*@baseZoom);
      left: calc(59px*@baseZoom);
    }
    p {
      font-size: calc(32px*@baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      position: absolute;
      top: calc(175px*@baseZoom);
      left: calc(95px*@baseZoom);
    }
  }
  .boxContent-right {
    width: calc(100% - calc(694px*@baseZoom));
    .cunJian {
      height:calc(392.5px*@baseZoom);
      background: url(../assets/unmanned_vehicle/homepage_module2.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
      img {
        width: calc(182px*@baseZoom);
        height: calc(92px*@baseZoom);
        position: absolute;
        top: calc(50px*@baseZoom);
        left: calc(47px*@baseZoom);
      }
      p {
        font-size: calc(32px*@baseZoom);
        font-weight: 400;
        color: #ffffff;
        line-height: 100%;
        position: absolute;
        top: calc(152px*@baseZoom);
        left: calc(88px*@baseZoom);
      }
    }
    .quJian {
      height: calc(392.5px*@baseZoom);
      background: url(../assets/unmanned_vehicle/homepage_module3.png) no-repeat;
      background-size: 100% 100%;
      position: relative;
      img {
        width: calc(182px*@baseZoom);
        height: calc(92px*@baseZoom);
        position: absolute;
        top: calc(50px*@baseZoom);
        left: calc(47px*@baseZoom);
      }
      p {
        font-size: calc(32px*@baseZoom);
        font-weight: 400;
        color: #ffffff;
        line-height: 100%;
        position: absolute;
        top: calc(152px*@baseZoom);
        left: calc(88px*@baseZoom);
      }
    }
  }
}
.boxFooter {
  width: 100%;
  height: calc(95px*@baseZoom);
  border-top: 1px solid #e3e9ff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  li {
    display: flex;
    img {
      width: calc(24px*@baseZoom);
      height: calc(24px*@baseZoom);
      margin-right: calc(9px*@baseZoom);
      margin-top: calc(5px*@baseZoom);
    }
    p {
      font-size: calc(24px*@baseZoom);
      font-weight: 400;
      color: #141414;
    }
  }
}
.elDialogAll {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  .elDialogAllCenter {
    width: calc(640px*@baseZoom);
    height: calc(560px*@baseZoom);
    background: #ffffff;
    border-radius: calc(20px*@baseZoom);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    img {
      display: block;
      width: calc(309px*@baseZoom);
      height: calc(190px*@baseZoom);
      margin: auto;
      margin-top: calc(62px*@baseZoom);
    }
    p {
      font-size: calc(32px*@baseZoom);
      font-weight: 400;
      color: #141414;
      line-height: 100%;
      text-align: center;
      margin-top: calc(44px*@baseZoom);
    }
    .btn {
      margin-top: calc(81px*@baseZoom);
      text-align: center;
      .btn-quXiao {
        width: calc(200px*@baseZoom);
        height: calc(88px*@baseZoom);
        background: #ffffff;
        border: 1px solid #2f56ff;
        border-radius: calc(88px*@baseZoom);
        font-size: calc(32px*@baseZoom);
        font-weight: 400;
        color: #2f56ff;
        line-height: calc(88px*@baseZoom);
      }
      .btn-queDing {
        width: calc(200px*@baseZoom);
        height: calc(88px*@baseZoom);
        background: #2f56ff;
	      border: 1px solid #2F56FF;
        border-radius: calc(88px*@baseZoom);
        font-size: calc(32px*@baseZoom);
        font-weight: 400;
        color: #ffffff;
        line-height: clac(88px*@baseZoom);
      }
    }
  }
}
</style>
