<template>
  <div class="collectAparcel">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
      </div>
      <div class="boxHeader-center">请输入6位取件码</div>
      <div class="boxHeader-right">
        <img src="@/assets/unmanned_vehicle/handle_countdown.png" alt="" />
        <p>倒计时：{{ authTime }}s</p>
      </div>
    </div>
    <div class="boxContent">
      <div
        class="input-content"
        @keydown="keydown"
        @keyup="keyup"
        @paste="paste"
        @mousewheel="mousewheel"
        @input="inputEvent"
      >
        <input
          max="9"
          min="0"
          maxlength="1"
          data-index="0"
          v-model.trim.number="input[0]"
          type="number"
          ref="firstinput"
        />
        <input
          max="9"
          min="0"
          maxlength="1"
          data-index="1"
          v-model.trim.number="input[1]"
          type="number"
        />
        <input
          max="9"
          min="0"
          maxlength="1"
          data-index="2"
          v-model.trim.number="input[2]"
          type="number"
        />
        <input
          max="9"
          min="0"
          maxlength="1"
          data-index="3"
          v-model.trim.number="input[3]"
          type="number"
        />
        <input
          max="9"
          min="0"
          maxlength="1"
          data-index="4"
          v-model.trim.number="input[4]"
          type="number"
        />
        <input
          max="9"
          min="0"
          maxlength="1"
          data-index="5"
          v-model.trim.number="input[5]"
          type="number"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { openTheDoor, doorStatus } from "@/utils/instructionList";
import { serialHandler } from "@/utils/serialHandler";
export default {
  name: "collectAparcel",
  components: {},
  data() {
    return {
      // 存放粘贴进来的数字
      pasteResult: [],
      authTime: 120, //倒计时
    };
  },
  props: ["code"],
  computed: {
    input() {
      // code 是父组件传进来的默认值，必须是6位长度的数组，这里就不再做容错判断处理
      // 最后空数组是默认值
      return this.code || this.pasteResult.length === 6
        ? this.pasteResult
        : ["", "", "", "", "", ""];
    },
  },
  created() {},
  mounted() {
    this.getTimeCode(); //倒计时
    // 等待dom渲染完成，在执行focus,否则无法获取到焦点
    this.$nextTick(() => {
      this.$refs.firstinput.focus();
    });
  },
  methods: {
    /**返回 */
    black() {
      this.$router.push("/mainIndex");
    },
    /**倒计时 */
    getTimeCode() {
      var authTimeNum = setInterval(() => {
        this.authTime--;
        if (this.authTime === 0) {
          clearInterval(authTimeNum);
          this.$router.push("/mainIndex");
        }
      }, 1000);
    },
    // 解决一个输入框输入多个字符
    inputEvent(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      this.$set(this.input, index, el.value.slice(0, 1));
    },
    keydown(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      if (e.key === "Backspace") {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, "");
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus();
            this.$set(this.input, index - 1, "");
          }
        }
      } else if (e.key === "Delete") {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, "");
        } else {
          if (el.nextElementSibling) {
            this.$set(this.input, (index = 1), "");
          }
        }
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === "Home") {
        el.parentElement.children[0] && el.parentElement.children[0].focus();
      } else if (e.key === "End") {
        el.parentElement.children[this.input.length - 1] &&
          el.parentElement.children[this.input.length - 1].focus();
      } else if (e.key === "ArrowLeft") {
        if (el.previousElementSibling) {
          el.previousElementSibling.focus();
        }
      } else if (e.key === "ArrowRight") {
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === "ArrowUp") {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.key === "ArrowDown") {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      }
    },
    keyup(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      if (/Digit|Numpad/i.test(e.code)) {
        this.$set(this.input, index, e.code.replace(/Digit|Numpad/i, ""));
        el.nextElementSibling && el.nextElementSibling.focus();
        if (index === 5) {
          if (this.input.join("").length === 6) {
            document.activeElement.blur();
            this.$emit("complete", this.input);
            //获取取件码接口
            let quCode = this.input.join("");
            let data = {
              code: quCode,
            };
            this.$post("/CarCabinet/findCode", data).then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                let cabinetLocation = res.data.carCabinet.cabinetLocation;
                let instruction;
                if (cabinetLocation == 1) {
                  instruction = openTheDoor.b1;
                } else if (cabinetLocation == 2) {
                  instruction = openTheDoor.b2;
                } else if (cabinetLocation == 3) {
                  instruction = openTheDoor.b3;
                } else if (cabinetLocation == 4) {
                  instruction = openTheDoor.b4;
                } else if (cabinetLocation == 5) {
                  instruction = openTheDoor.b5;
                } else if (cabinetLocation == 6) {
                  instruction = openTheDoor.b6;
                } else if (cabinetLocation == 7) {
                  instruction = openTheDoor.b7;
                } else if (cabinetLocation == 8) {
                  instruction = openTheDoor.b8;
                }
                console.log(instruction);
                serialHandler.init().then(() => {
                  serialHandler.writeCOM(instruction).then(() => {
                    serialHandler.readCOM().then((value) => {
                      console.log(value,'取件打开柜子返回的数据--------');
                        this.$router.push({
                          path: "/cabinetDoor",
                          query: {
                            cabinetCode: res.data.carCabinet.cabinetCode,
                            cabinetLocation:res.data.carCabinet.cabinetLocation,
                            cabinetStatus: res.data.carCabinet.cabinetStatus,
                            cabinetTime: res.data.carCabinet.cabinetTime,
                            cabinetType: res.data.carCabinet.cabinetType,
                            carCode: res.data.carCabinet.carCode,
                            id: res.data.carCabinet.id,
                            iphone: res.data.carCabinet.iphone,
                            recvid: res.data.carCabinet.recvid,
                            quCode: res.data.carCode,
                          },
                        });
                    });
                  });
                });
              } else {
                this.$message.error(res.data.msg);
              }
            });
          }
        }
      } else {
        if (this.input[index] === "") {
          this.$set(this.input, index, "");
        }
      }
    },
    mousewheel(e) {
      var index = e.target.dataset.index;
      if (e.wheelDelta > 0) {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.wheelDelta < 0) {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      } else if (e.key === "Enter") {
        if (this.input.join("").length === 6) {
          document.activeElement.blur();
          this.$emit("complete", this.input);
        }
      }
    },
    paste(e) {
      // 当进行粘贴时
      e.clipboardData.items[0].getAsString((str) => {
        if (str.toString().length === 6) {
          this.pasteResult = str.split("");
          document.activeElement.blur();
          this.$emit("complete", this.input);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@baseZoom: 0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.collectAparcel {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/1.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    width: calc(160px * @baseZoom);
    height: calc(72px * @baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(10px * @baseZoom);
    margin-left: calc(60px * @baseZoom);
    margin-top: calc(24px * @baseZoom);
    font-size: calc(32px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(72px * @baseZoom);
  }
  .boxHeader-center {
    font-size: calc(48px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    line-height: 100%;
    margin-top: calc(70px * @baseZoom);
  }
  .boxHeader-right {
    width: calc(260px * @baseZoom);
    height: calc(72px * @baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(36px * @baseZoom);
    margin-top: calc(24px * @baseZoom);
    margin-right: calc(60px * @baseZoom);
    display: flex;
    align-items: center;
    img {
      width: calc(28px * @baseZoom);
      height: calc(32px * @baseZoom);
      margin-left: calc(25px * @baseZoom);
      margin-right: calc(11px * @baseZoom);
      margin-bottom: -2px;
    }
    p {
      font-size: calc(28px * @baseZoom);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
    }
  }
}
.boxContent {
  width: 65%;
  height: calc(280px * @baseZoom);
  background: url(../assets/unmanned_vehicle/takeparts_inputbg.png) no-repeat;
  background-size: 100% 100%;
  margin: auto;
  margin-top: calc(45px * @baseZoom);
  display: flex;
  align-items: center;
  .input-content {
    width: 70%;
    height: calc(60px * @baseZoom);
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      color: inherit;
      font-family: inherit;
      border: 0;
      outline: 0;
      width: calc(120px * @baseZoom);
      height: calc(120px * @baseZoom);
      background: #fafbff;
      border: 1px solid #aaaccf;
      border-radius: calc(20px * @baseZoom);
      font-size: calc(44px * @baseZoom);
      text-align: center;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
</style>