<template>
<span>{{nowTime}}</span>
</template>

<script>
import {parseTime} from '@/utils/commonFunc';
/**
 * 时钟组件
 */
export default {
	name: "timerComponent",
	data(){
		return {
			timer:null,
			nowTime:null,
		}
	},
	created() {
		this.timer = setInterval(()=>{
			this.nowTime = parseTime(new Date(),null);
		},1000);
	},
	beforeDestroy() {
		clearInterval(this.timer)
	},
}
</script>

<style scoped>

</style>