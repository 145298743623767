var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.checked
    ? _c("div", { staticClass: "checked-style" }, [
        _c("div", {
          staticStyle: {
            width: "0.14rem",
            height: "0.14rem",
            "margin-top": "0.06rem",
            "margin-left": "0.06rem",
            "background-color": "#6F8DE6",
            "border-radius": "50%"
          }
        })
      ])
    : _c("div", { staticClass: "unchecked-style" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }