<template>
  <div class="identityCard">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
      </div>
      <div class="boxHeader-center">身份证扫描</div>
      <div class="boxHeader-right">
        <img src="@/assets/unmanned_vehicle/handle_countdown.png" alt="" />
        <p>倒计时：{{countdown}}s</p>
      </div>
    </div>
    <div class="boxContent">
      <div class="carImg">
        <img src="@/assets/unmanned_vehicle/handle_idcard.png" alt="" />
      </div>
      <p>请在屏幕下方进行身份证扫描</p>
      <div class="xiaImg">
        <img src="@/assets/unmanned_vehicle/handle_downarrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "identityCard",
  data() {
    return {
			/*倒计时的计时器id*/
			timer:null,
	    /* 倒计时 */
	    countdown:120,
	    /* 轮询计时器 */
			setTimeOutId:null,
    };
  },
	created() {
		this.getIdInfo(2000)
	},
	mounted() {
		this.timer = setInterval(()=>{
			if(this.countdown===0){
				clearInterval(this.timer);
				this.$router.push("/mainIndex");
			}else{
				this.countdown--;
			}
		},1000);
		window.setUserInfo=this.setUserInfo;
	},
	methods: {
    /**返回 */
    black() {
      this.$router.push("/doPiece");
    },
		/**
		 * 存储用户信息
		 * @param name
		 * @param idNumber 身份证号
		 */
		setUserInfo(name,idNumber){
			sessionStorage.setItem('isAgentInfo',JSON.stringify({name,idNumber}));
			sessionStorage.setItem('platform','自助机');
			// todo 跳转到智能填报
			this.$router.push({path:'/dynamicList'})
		},
		/**
		 * 获取身份信息
		 * @param {number} timeout
		 */
		getIdInfo(timeout){
			if(this.countdown>0){
				this.setTimeOutId = setInterval(()=>{
					this.$get('http://127.0.0.1:5005/idInfo/getIdCardInfo').then(res=>{
						let {code,msg,name,idNum} = res.data;
						if(code===200){
							clearInterval(this.setTimeOutId)
							this.setUserInfo(name,idNum);
							this.$message.success('身份证识别成功')
							this.$router.push({path:'/dynamicList'})
						}
					})
				},timeout)
			}else{
				clearInterval(this.setTimeOutId)
			}
		},
  },
	beforeRouteLeave(to,from,next){
		if(this.setTimeOutId){
			clearInterval(this.setTimeOutId);
			this.setTimeOutId = null;
		}
		if(this.timer){
			clearInterval(this.timer);
			this.timer = null;
		}
		next()
	}
};
</script>

<style lang="less" scoped>
@baseZoom:0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.identityCard {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/homepage_bg.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    width: calc(160px*@baseZoom);
    height: calc(72px*@baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(10px*@baseZoom);
    margin-left: calc(60px*@baseZoom);
    margin-top: calc(24px*@baseZoom);
    font-size: calc(32px*@baseZoom);
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(72px*@baseZoom);
  }
  .boxHeader-center {
    font-size: calc(48px*@baseZoom);
    font-weight: 400;
    color: #ffffff;
    line-height: 100%;
    margin-top: calc(38px*@baseZoom);
  }
  .boxHeader-right {
    width: calc(260px*@baseZoom);
    height: calc(93px*@baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(47px*@baseZoom) calc(47px*@baseZoom) 0px 0px;
    margin-right: calc(60px*@baseZoom);
    margin-top: calc(44px*@baseZoom);
    display: flex;
    img {
      width: calc(28px*@baseZoom);
      height: calc(32px*@baseZoom);
      margin-top: calc(25px*@baseZoom);
      margin-left: calc(25px*@baseZoom);
    }
    p {
      font-size: calc(28px*@baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(28px*@baseZoom);
      margin-left: calc(11px*@baseZoom);
    }
  }
}
.boxContent {
  width: 100%;
  .carImg {
    width: calc(1446px*@baseZoom);
    height: calc(506px*@baseZoom);
    margin: auto;
    margin-top: calc(117px*@baseZoom);
    img {
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-size: calc(48px*@baseZoom);
    font-weight: 500;
    color: #ffa414;
    line-height: 100%;
    text-align: center;
    margin-top: calc(88px*@baseZoom);
  }
  .xiaImg {
    width: calc(96px*@baseZoom);
    height: calc(96px*@baseZoom);
    margin: auto;
    margin-top: calc(49px*@baseZoom);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>