<template>
  <div>
    <van-form v-for="(item, index) in data" :key="index" :ref="`vanForm_${item.category}`" label-width="120px" :show-error="false" validate-first class="form-card">
      <div class="form-card-content">
        <img class="form-card-icon" src="../assets/icon_2.png" alt="">
        <span class="form-card-span">{{item.category}}</span>
      </div>
      <div class="form-content-item" v-for="(i,index2) in item.bmgTaskFieldList" :key="index2">
        <!-- input 👇 -->
        <van-field class="van-field-c" v-if="i.fieldType===3" v-model="formObj[i.fieldCode]" :name="i.fieldCode" :label="i.fieldName" :placeholder="`请输入${i.fieldName}`" @input="inputChange($event,i.fieldCode)" :rules="formRules[i.fieldCode]" :required="i.isRequire===1"></van-field>
        <!-- input 👆 -->

        <!-- checkbox 👇 -->
        <van-field class="van-field-c" v-else-if="i.fieldType===2" :name="i.fieldCode" :label="i.fieldName" :rules="formRules[i.fieldCode]"  :required="i.isRequire===1">
          <template #input>
            <van-checkbox-group v-model="formObj[i.fieldCode]" direction="horizontal" @change="checkboxChange($event,i.optionValues,i.fieldCode)">
              <van-checkbox v-for="i2 in i.optionValues" :key="i2.optionValue" :name="i2.optionValue" shape="square">{{i2.optionName}}</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <!-- checkbox 👆 -->

        <!-- radio 👇 -->
        <van-field class="van-field-c" v-else-if="i.fieldType===1" :name="i.fieldCode" :label="i.fieldName" :rules="formRules[i.fieldCode]"  :required="i.isRequire===1">
          <template #input>
            <van-radio-group v-model="formObj[i.fieldCode]" direction="horizontal" @change="radioChange($event,i.optionValues,i.fieldCode)">
              <van-radio v-for="i2 in i.optionValues" :key="i2.optionValue" :name="i2.optionValue">{{i2.optionName}}
                <template #icon="p">
                  <custom-radio :checked="p.checked"></custom-radio>
                </template>
              </van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <!-- radio 👆 -->

        <!-- select -->
        <van-field class="van-field-c" v-else-if="i.fieldType === 5" :name="i.fieldCode" readonly clickable v-model="formObj[i.fieldCode]" :label="i.fieldName" placeholder="" @click="selectClick(i)" />
      </div>
    </van-form>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar :columns="pickerOptions" value-key="optionName" @confirm="onConfirm($event)" @cancel="onCancel" />
    </van-popup>
  </div>
</template>

<script>
  import {Cell, CellGroup, Form,Field,Picker,Radio, RadioGroup, Checkbox,CheckboxGroup, Popup} from 'vant';
  import customRadio from './customRadio.vue'
  export default {
    props:{
      data:{
        default:()=>{
          return []
        }
      }
    },
    name:'vantDynamicForm',
    components:{
      vanCell:Cell,
      vanCellGroup:CellGroup,
      vanForm:Form,
      vanField:Field,
      vanPicker:Picker,
      vanRadio:Radio,
      vanRadioGroup:RadioGroup,
      vanCheckbox:Checkbox,
      vanCheckboxGroup:CheckboxGroup,
      vanPopup:Popup,
      customRadio
    },
    data(){
      return {
        formObj:{},
        newFormObj:{},
        showPicker:false,
        pickerOptions: [],

        selectKey:'',
        formRules:{}
      }
    },
    watch:{
      data(val){
        val.forEach(item=>{
          item.bmgTaskFieldList.forEach(i=>{
            if(i.isRequire===1){
              this.formRules[i.fieldCode] = [{required:true,message:`${this.triggerType(i.fieldType).text}${i.fieldName}`,trigger:this.triggerType(i.fieldType).method}]
            }
          })
        })
      }
    },
    methods:{
      triggerType(t){
        if(t===1||t===2){
          return {text:'请选择',method:'onChange'}
        }else if(t===3){
          return {text:'请输入',method:'onBlur'}
        }
      },
      onConfirm(value) {
        this.formObj[this.selectKey] = value.optionName;
        this.showPicker = false;
        this.$nextTick(()=>{
          this.pickerOptions=[];
          this.selectKey=''
        })
      },
      onCancel(){
        this.pickerOptions=[];
        this.selectKey='';
        this.showPicker = false;
      },
      selectClick(x){
        this.showPicker=true;
        this.pickerOptions = x.optionValues;
        this.selectKey = x.fieldCode
      },
      checkboxChange(value,column,propName){
        let arr = []
        let dicData = JSON.parse(JSON.stringify(column));
        value.forEach(val=>{
          dicData.forEach((item,index)=>{
            if(val === item.optionName){
              item.optionValue = 1;
            }
          })
        })
        arr = dicData;
        this.newFormObj[propName] = arr;
      },
      radioChange(value,column,propName){
        let arr = []
        column.forEach(item=>{
          if(value === item.optionName){
            arr.push({label:item.optionName,value:1,optionSort:item.optionSort})
          }else{
            arr.push({label:item.optionName,value:0,optionSort:item.optionSort})
          }
        })
        this.newFormObj[propName] = arr;
      },
      inputChange(value,propName){
        this.newFormObj[propName] = value;
      },
      validateVanForm(){
        return new Promise((resolve,reject)=>{
          if(this.data.length>0){
            for(let i=0;i<this.data.length;i++){
              this.$refs[`vanForm_${this.data[i].category}`][0].validate().then(()=>{
                resolve()
              }).catch(err=>{
                reject(err);
              })
            }
          }else{
            resolve()
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.form-card{
  border-radius: 10px;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 10px;
  .form-card-content{
    height:0.9rem;width: 100%;display:flex;align-items:center;    
    .form-card-icon{
      width: 0.28rem;
    }
    .form-card-span{
      font-size: 0.3rem;
      line-height: 0.9rem;
      margin-left:0.16rem;
      color:#333;
      font-weight: bold;
    }
  }
}
</style>
<style lang="less" scope>
.van-field-c{
  padding: 0.2rem 0.05rem;
}
.van-field-c.van-cell--required::before{
  left: -5px;
}
.van-field-c{
  &::after{
    left: 0;
    right: 0;
    border-bottom: 1px dashed #D3D3D3;
  }
}
.form-content-item:last-child{
  .van-field-c::after{
    border-bottom: none;
  }
}
</style>