<template>
  <div class="footer">
    技术支持：北京新广视通科技集团有限责任公司
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
  .footer{
    height: 60px;
    background-color: #F4E9D5;
    font-size: 16px;
    color:#75595C;
    text-align: center;
    line-height: 60px;
  }
</style>
