<template>
  <div class="doSavea">
    <div class="box" v-if="ifBaoGuo">
      <div class="boxHeader">
        <div class="boxHeader-left">
          <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
        </div>
        <div class="boxHeader-center">请输入包裹单号</div>
        <div class="boxHeader-right" @click="getShowPhone">
          <img src="@/assets/unmanned_vehicle/phonenum.png" alt="" />
          <p>手机号存件</p>
        </div>
      </div>
      <div class="boxContent">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input
              v-model="formInline.recvId"
              placeholder="请输入包裹单号"
              @keyup.native.enter="submitForm('1')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="box2" v-else>
      <div class="boxHeader">
        <div class="boxHeader-left">
          <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
        </div>
        <div class="boxHeader-center">请输入收件人手机号</div>
        <div class="boxHeader-right" @click="getShowBaoGuo">
          <img src="@/assets/unmanned_vehicle/num.png" alt="" />
          <p>单号存件</p>
        </div>
      </div>
      <div class="boxContent">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          @submit.native.prevent
        >
          <el-form-item>
            <el-input
              v-model="formInline.phone"
              placeholder="请输入手机号"
              @keyup.native.enter="submitForm('2')"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 事项弹框 -->
    <el-dialog
      title="请选择材料对应的事项"
      center
      width="79%"
      :visible.sync="dialogTableVisible"
      @closed="getClose"
    >
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="recvId" label="办件编号" width="350">
        </el-table-column>
        <el-table-column prop="taskName" label="事项名称"> </el-table-column>
        <el-table-column prop="address" width="180" align="center">
          <template slot-scope="scope">
            <el-checkbox class="checkBox" v-model="scope.row.label" @change="getSelect"><span>选择</span></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <div class="xuanZe" @click="goBergsonism">选择完成，下一步</div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "doSavea",
  data() {
    return {
      checked: false,
      ifBaoGuo: true,
      ifPhone: false,
      formInline: {
        recvId: "",
        phone: "",
      },
      dialogTableVisible: false,
      tableData: [],
      arrCaiData:[],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  methods: {
    /**返回 */
    black() {
      this.$router.push("/mainIndex");
    },
    /**点击手机号存件 */
    getShowPhone() {
      this.ifBaoGuo = false;
      this.ifPhone = true;
      this.formInline.recvId = '';
    },
    /**点击单号存件 */
    getShowBaoGuo() {
      this.ifPhone = false;
      this.ifBaoGuo = true;
      this.formInline.phone = '';
    },
    /**跳选择柜格 */
    goBergsonism() {
      console.log(this.arrCaiData);
      if(this.arrCaiData.length <= 0){
        return this.$message.error('请选择要存的材料！');
      }
      // this.$router.push("/bergsonism");
      this.$router.push({
        path:'/bergsonism',
        query:{
          informationData:JSON.stringify(this.arrCaiData),
        }
      })
    },

    /**输入存件方式回车时间 */
    submitForm(num) {
      let param = {};
      if (num == 1) {
        if (this.formInline.recvId) {
          param.phone = "";
          param.recvId = this.formInline.recvId;
        }
        console.log("包裹单号方式");
      } else if (num == 2) {
        if (this.formInline.phone) {
          param.phone = this.formInline.phone;
          param.recvId = "";
        }
        console.log("手机号方式");
      }
      this.$post("/CarCabinet/basicinformation", param).then((res) => {
        if (res.data.code == 200) {
          this.tableData = res.data.bmgTaskRecv;
          this.tableData.forEach(item=>{
            this.$set(item,'label',false);
          })
          this.dialogTableVisible = true;
          this.formInline.recvId = '';
          this.formInline.phone = '';
        }else{
          this.$message.error(res.data.msg);
        }
      });
    },
    /**选择每个材料 */
    getSelect(){
      console.log(this.tableData)
      let arrData = [];
      this.tableData.forEach(item=>{
        if(item.label == true){
           arrData.push(item)
        }
      })
      console.log(arrData)
      this.arrCaiData = arrData;
    },
    /**关闭弹框的回调 */
    getClose(){
      this.arrCaiData = [];
    },
  },
};
</script>

<style lang="less" scoped>
@baseZoom: 0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.doSavea {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/1.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    width: calc(120px * @baseZoom);
    height: calc(54px * @baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(8px * @baseZoom);
    margin-left: calc(45px * @baseZoom);
    margin-top: calc(18px * @baseZoom);
    font-size: calc(24px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(54px * @baseZoom);
  }
  .boxHeader-center {
    font-size: calc(36px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    line-height: 100%;
    margin-top: calc(53px * @baseZoom);
  }
  .boxHeader-right {
    width: calc(180px * @baseZoom);
    height: calc(53px * @baseZoom);
    background: #23a6dd;
    border-radius: calc(8px * @baseZoom);
    margin-top: calc(18px * @baseZoom);
    margin-right: calc(47px * @baseZoom);
    display: flex;
    align-items: center;
    img {
      width: calc(24px * @baseZoom);
      height: calc(24px * @baseZoom);
      margin-left: calc(15px * @baseZoom);
      margin-right: calc(7px * @baseZoom);
    }
    p {
      font-size: calc(24px * @baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
    }
  }
}
.boxContent {
  width: calc(65% * @baseZoom);
  height: calc(210px * @baseZoom);
  background: url(../assets/unmanned_vehicle/takeparts_inputbg.png) no-repeat;
  background-size: 100% 100%;
  margin: calc(34px * @baseZoom) auto auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.el-form {
  display: flex;
  align-items: center;
}
/deep/.el-form-item__content {
  display: flex;
}
.imgUrlAll {
  width: calc(27px * @baseZoom);
  height: calc(32px * @baseZoom);
}
/deep/.el-input__inner {
  border: none;
  height: calc(36px * @baseZoom);
  font-size: calc(36px * @baseZoom);
  font-weight: 400;
  color: #141414;
  margin-left: calc(70px * @baseZoom);
}
.border-bor {
  width: calc(1px * @baseZoom);
  height: calc(105px * @baseZoom);
  background: #aaaccf;
  border-radius: calc(1px * @baseZoom);
  margin-right: calc(116px * @baseZoom);
  margin-left: calc(75px * @baseZoom);
}
/deep/.el-dialog__header {
  padding: calc(40px * @baseZoom);
}
/deep/.el-dialog__title {
  font-size: calc(30px * @baseZoom);
  font-weight: 500;
  color: #141414;
}
/deep/.cell {
  font-size: calc(24px * @baseZoom);
  font-weight: 400;
  color: #666666;
  line-height: calc(35px * @baseZoom);
}
.checkBox {
  zoom: 2;
}
/deep/.el-dialog__close {
  zoom: 2;
}
/deep/.el-form-item--small.el-form-item {
  margin-bottom: 0;
}
.xuanZe {
  width: calc(270px * @baseZoom);
  height: calc(60px * @baseZoom);
  background: #2f56ff;
  box-shadow: 0 calc(8px * @baseZoom) calc(15px * @baseZoom) 0
    rgba(47, 86, 255, 0.4);
  border-radius: calc(8px * @baseZoom);
  font-size: calc(24px * @baseZoom);
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: calc(60px * @baseZoom);
  margin: calc(88px * @baseZoom) auto calc(36px * @baseZoom);
}
</style>