var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dynamic-form" },
    [
      _c(
        "el-form",
        {
          ref: "formCompony",
          staticClass: "form-area",
          attrs: { rules: _vm.rules }
        },
        [
          _c(
            "el-row",
            [
              _c("el-col", [
                _c(
                  "i",
                  {
                    staticClass: "el-icon-arrow-left backIcon",
                    on: { click: _vm.goback }
                  },
                  [_vm._v("返回")]
                )
              ]),
              _c("el-col", { staticClass: "style-col" }, [
                _c("h3", { staticClass: "title-top" }, [
                  _vm._v(_vm._s(_vm.taskName))
                ]),
                _c("span", { staticClass: "recv-id" }, [
                  _vm._v("申请编号：" + _vm._s(_vm.subjectRecvId))
                ])
              ]),
              _c(
                "el-col",
                [
                  _c("el-card", { staticClass: "card-items" }, [
                    _c("span", { staticClass: "fillSpan" }, [
                      _c("i", {
                        staticClass: "el-icon-bottom-right fillSpan-icon"
                      }),
                      _vm._v(" 选择类型 "),
                      _c("i", { staticClass: "fillI" })
                    ]),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px" } },
                      [
                        _c(
                          "el-form",
                          {
                            attrs: {
                              model: _vm.baseInfoForm,
                              "label-width": "220px"
                            }
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "类型:" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    model: {
                                      value: _vm.baseInfoForm.servType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.baseInfoForm,
                                          "servType",
                                          $$v
                                        )
                                      },
                                      expression: "baseInfoForm.servType"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: 2 } }, [
                                      _vm._v("企业申请")
                                    ]),
                                    _c("el-radio", { attrs: { label: 1 } }, [
                                      _vm._v("个人申请")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.baseInfoForm.servType == 2,
                      expression: "baseInfoForm.servType==2"
                    }
                  ]
                },
                [
                  _c("el-card", { staticClass: "card-items" }, [
                    _c("span", { staticClass: "fillSpan" }, [
                      _c("i", {
                        staticClass: "el-icon-bottom-right fillSpan-icon"
                      }),
                      _vm._v(" 企业申请 "),
                      _c("i", { staticClass: "fillI" })
                    ]),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "enterForm",
                            attrs: {
                              model: _vm.enterForm,
                              "label-width": "220px",
                              rules: _vm.formRules
                            }
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "申请单位",
                                          prop: "enterpriseName"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入申请单位",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.enterForm.enterpriseName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "单位类型",
                                          prop: "enterpriseType"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择单位类型",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.enterForm.enterpriseType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.enterForm,
                                                  "enterpriseType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "enterForm.enterpriseType"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                value: "1",
                                                label: "中小企业"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "2",
                                                label: "民营企业"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "3",
                                                label: "私营企业"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "4",
                                                label: "个体工商户"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "5",
                                                label: "社会组织"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "6",
                                                label: "国有企业"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "7",
                                                label: "政府机构"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "99",
                                                label: "其他"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "是否法定代表人",
                                          prop: "isLegal"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: _vm.enterForm.isLegal,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.enterForm,
                                                  "isLegal",
                                                  $$v
                                                )
                                              },
                                              expression: "enterForm.isLegal"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "1" } },
                                              [_vm._v("是")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "0" } },
                                              [_vm._v("否")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "代表人",
                                          prop: "enterpriseLegalName"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入代表人姓名",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm.enterpriseLegalName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseLegalName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseLegalName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "法人证件类型",
                                          prop: "enterpriseLegalType"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择法人证件类型",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function(val) {
                                                _vm.cardTypeChange(
                                                  val,
                                                  "enterpriseLegalIdcard"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.enterForm
                                                  .enterpriseLegalType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.enterForm,
                                                  "enterpriseLegalType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "enterForm.enterpriseLegalType"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label: "身份证",
                                                value: "111"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "其他",
                                                value: "999"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "法人证件号码",
                                          prop: "enterpriseLegalIdcard"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入法人证件号码",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm
                                                .enterpriseLegalIdcard,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseLegalIdcard",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseLegalIdcard"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件类型",
                                          prop: "enterpriseCardType"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择证件类型",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.enterForm
                                                  .enterpriseCardType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.enterForm,
                                                  "enterpriseCardType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "enterForm.enterpriseCardType"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                label:
                                                  "工商局登记号（营业执照）",
                                                value: "003"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                label: "其他",
                                                value: "999"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件号码",
                                          prop: "enterpriseCardNum"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入证件号码",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm.enterpriseCardNum,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseCardNum",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseCardNum"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "联系人",
                                          prop: "enterpriseContactName"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入联系人",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm
                                                .enterpriseContactName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseContactName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseContactName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "联系人手机",
                                          prop: "enterpriseContactMobile"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入联系人手机",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm
                                                .enterpriseContactMobile,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseContactMobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseContactMobile"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "固定电话" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入固定电话",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm
                                                .enterpriseContactFixed,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseContactFixed",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseContactFixed"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "联系人邮箱" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入联系人邮箱",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm
                                                .enterpriseContactEmail,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseContactEmail",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseContactEmail"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "企业地址" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入企业地址",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.enterForm
                                                .enterpriseContactAddr,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "enterpriseContactAddr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "enterForm.enterpriseContactAddr"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "事项备注" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入事项备注",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.enterForm.remark,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.enterForm,
                                                "remark",
                                                $$v
                                              )
                                            },
                                            expression: "enterForm.remark"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.baseInfoForm.servType == 1,
                      expression: "baseInfoForm.servType==1"
                    }
                  ]
                },
                [
                  _c("el-card", { staticClass: "card-items" }, [
                    _c("span", { staticClass: "fillSpan" }, [
                      _c("i", {
                        staticClass: "el-icon-bottom-right fillSpan-icon"
                      }),
                      _vm._v(" 个人申请 "),
                      _c("i", { staticClass: "fillI" })
                    ]),
                    _c(
                      "div",
                      { staticStyle: { margin: "10px" } },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "personForm",
                            attrs: {
                              model: _vm.personForm,
                              "label-width": "220px",
                              rules: _vm.formRules
                            }
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "姓名",
                                          prop: "personName"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入姓名",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.personForm.personName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personName",
                                                $$v
                                              )
                                            },
                                            expression: "personForm.personName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "性别",
                                          prop: "personSex"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择性别",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.personForm.personSex,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.personForm,
                                                  "personSex",
                                                  $$v
                                                )
                                              },
                                              expression: "personForm.personSex"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: { value: "男" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "女" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "出生日期" } },
                                      [
                                        _c("el-date-picker", {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "value-format": "yyyy-MM-dd",
                                            type: "date",
                                            editable: false,
                                            placeholder: "选择出生日期",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.personForm.personBirth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personBirth",
                                                $$v
                                              )
                                            },
                                            expression: "personForm.personBirth"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件类型",
                                          prop: "personCardType"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择证件类型",
                                              clearable: ""
                                            },
                                            on: {
                                              change: function(val) {
                                                _vm.cardTypeChange(
                                                  val,
                                                  "personCardNum"
                                                )
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.personForm.personCardType,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.personForm,
                                                  "personCardType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "personForm.personCardType"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: {
                                                value: "111",
                                                label: "身份证"
                                              }
                                            }),
                                            _c("el-option", {
                                              attrs: {
                                                value: "99",
                                                label: "其他"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "证件号码",
                                          prop: "personCardNum"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入证件号码",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.personForm.personCardNum,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personCardNum",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.personCardNum"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "籍贯" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入籍贯",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.personForm.personBirthPlace,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personBirthPlace",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.personBirthPlace"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "手机号码",
                                          prop: "personMobile"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入手机号码",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.personForm.personMobile,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personMobile",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.personMobile"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "固定号码" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入固定号码",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.personForm.personFixed,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personFixed",
                                                $$v
                                              )
                                            },
                                            expression: "personForm.personFixed"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "电子邮箱" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入电子邮箱",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.personForm.personContactEmail,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personContactEmail",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.personContactEmail"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "文化程度" } },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择文化程度",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.personForm.personDegree,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.personForm,
                                                  "personDegree",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "personForm.personDegree"
                                            }
                                          },
                                          [
                                            _c("el-option", {
                                              attrs: { value: "博士" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "硕士" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "本科" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "博士" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "大专" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "高中（中专）" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "初中" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "小学" }
                                            }),
                                            _c("el-option", {
                                              attrs: { value: "其他" }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "是否外籍" } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            attrs: { clearable: "" },
                                            model: {
                                              value:
                                                _vm.baseInfoForm
                                                  .personIsForeign,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.baseInfoForm,
                                                  "personIsForeign",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "baseInfoForm.personIsForeign"
                                            }
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "1" } },
                                              [_vm._v("是")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "0" } },
                                              [_vm._v("否")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "国籍" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入国籍",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.personForm.personNationality,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personNationality",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.personNationality"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "联系人" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入联系人",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.personForm.personContactName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personContactName",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.personContactName"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 12 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "地址" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入地址",
                                            clearable: ""
                                          },
                                          model: {
                                            value:
                                              _vm.personForm.personContactAddr,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.personForm,
                                                "personContactAddr",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "personForm.personContactAddr"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _vm.baseInfoForm.isAgent === 1
                ? _c(
                    "el-col",
                    [
                      _c("el-card", { staticClass: "card-items" }, [
                        _c("span", { staticClass: "fillSpan" }, [
                          _c("i", {
                            staticClass: "el-icon-bottom-right fillSpan-icon"
                          }),
                          _vm._v(" 委托人信息 "),
                          _c("i", { staticClass: "fillI" })
                        ]),
                        _c(
                          "div",
                          { staticStyle: { margin: "10px" } },
                          [
                            _c(
                              "el-form",
                              {
                                attrs: {
                                  model: _vm.baseInfoForm,
                                  "label-width": "220px"
                                }
                              },
                              [
                                _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "委托人姓名:" } },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "请输入委托人姓名",
                                                clearable: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.baseInfoForm.applyerName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.baseInfoForm,
                                                    "applyerName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "baseInfoForm.applyerName"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "委托人证件类型:" }
                                          },
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                staticStyle: { width: "100%" },
                                                attrs: {
                                                  placeholder:
                                                    "请选择委托人证件类型",
                                                  clearable: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.baseInfoForm
                                                      .applyerCardType,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.baseInfoForm,
                                                      "applyerCardType",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "baseInfoForm.applyerCardType"
                                                }
                                              },
                                              _vm._l(
                                                _vm.cardTypeList1,
                                                function(item) {
                                                  return _c("el-option", {
                                                    key: item.value,
                                                    attrs: {
                                                      value: item.value,
                                                      label: item.label
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "委托人证件号码:" }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "请输入委托人证件号码",
                                                clearable: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.baseInfoForm
                                                    .applyerCardCode,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.baseInfoForm,
                                                    "applyerCardCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "baseInfoForm.applyerCardCode"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-col",
                                      { attrs: { span: 12 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: { label: "委托人手机号码:" }
                                          },
                                          [
                                            _c("el-input", {
                                              attrs: {
                                                placeholder:
                                                  "请输入委托人手机号码",
                                                clearable: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.baseInfoForm
                                                    .applyerMobile,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.baseInfoForm,
                                                    "applyerMobile",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "baseInfoForm.applyerMobile"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.avueOptions, function(item) {
                return _c(
                  "el-col",
                  { key: item.name },
                  [
                    _c("el-card", { staticClass: "card-items" }, [
                      _c("div", { staticClass: "fillSpan" }, [
                        _c("i", {
                          staticClass: "el-icon-bottom-right fillSpan-icon"
                        }),
                        _vm._v(" " + _vm._s(item.name) + " "),
                        _c("i", { staticClass: "fillI" })
                      ]),
                      _c(
                        "div",
                        { staticStyle: { margin: "10px 10px" } },
                        [
                          _c(
                            "el-form",
                            {
                              ref: item.name,
                              refInFor: true,
                              attrs: {
                                "label-width": "220px",
                                model: _vm.avueForm
                              }
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 15 } },
                                _vm._l(item.form.column, function(_item) {
                                  return _c(
                                    "el-col",
                                    { key: _item.prop, attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: _item.label,
                                            rules: _item.rules,
                                            prop: _item.prop
                                          }
                                        },
                                        [
                                          _item.type === "input"
                                            ? _c("el-input", {
                                                attrs: { clearable: "" },
                                                on: {
                                                  change: function(value) {
                                                    _vm.dynamicFormChange(
                                                      value,
                                                      _item
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.avueForm[_item.prop],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.avueForm,
                                                      _item.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "avueForm[_item.prop]"
                                                }
                                              })
                                            : _vm._e(),
                                          _item.type === "textarea"
                                            ? _c("el-input", {
                                                attrs: {
                                                  type: "textarea",
                                                  autosize: {
                                                    minRows: 2,
                                                    maxRows: 4
                                                  },
                                                  clearable: ""
                                                },
                                                on: {
                                                  change: function(value) {
                                                    _vm.dynamicFormChange(
                                                      value,
                                                      _item
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value:
                                                    _vm.avueForm[_item.prop],
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.avueForm,
                                                      _item.prop,
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "avueForm[_item.prop]"
                                                }
                                              })
                                            : _item.type === "checkbox"
                                            ? _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change: function(value) {
                                                      _vm.dynamicFormChange(
                                                        value,
                                                        _item
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.avueForm[_item.prop],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.avueForm,
                                                        _item.prop,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "avueForm[_item.prop]"
                                                  }
                                                },
                                                _vm._l(_item.dicData, function(
                                                  __item
                                                ) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      key: __item.optionSort,
                                                      attrs: {
                                                        label: __item.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(__item.label)
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            : _item.type === "radio"
                                            ? _c(
                                                "el-radio-group",
                                                {
                                                  on: {
                                                    change: function(value) {
                                                      _vm.dynamicFormChange(
                                                        value,
                                                        _item
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.avueForm[_item.prop],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.avueForm,
                                                        _item.prop,
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "avueForm[_item.prop]"
                                                  }
                                                },
                                                _vm._l(_item.dicData, function(
                                                  __item
                                                ) {
                                                  return _c(
                                                    "el-radio",
                                                    {
                                                      key: __item.optionSort,
                                                      attrs: {
                                                        label: __item.value
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(__item.label) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              }),
              _c(
                "el-col",
                [
                  _c("el-card", { staticClass: "card-items" }, [
                    _c("span", { staticClass: "fillSpan" }, [
                      _c("i", {
                        staticClass: "el-icon-bottom-right fillSpan-icon"
                      }),
                      _vm._v(" 需要上传的材料 "),
                      _c("i", { staticClass: "fillI" })
                    ]),
                    _c(
                      "div",
                      { staticStyle: { margin: "20px 10px" } },
                      [
                        _c(
                          "el-table",
                          {
                            ref: "tableData",
                            attrs: {
                              data: _vm.tableData,
                              stripe: "",
                              border: "",
                              "header-cell-style": { background: "#f7f7f7" },
                              height: "300"
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "index",
                                label: "序号",
                                width: "80",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "materialName",
                                label: "材料名称",
                                width: "180",
                                align: "center"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                label: "数量要求",
                                width: "180",
                                align: "center"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      scope.row.mSource == 10
                                        ? _c("span", [_vm._v("申请人自备、")])
                                        : scope.row.mSource == 20
                                        ? _c("span", [_vm._v("政府部门核发、")])
                                        : scope.row.mSource == 30
                                        ? _c("span", [
                                            _vm._v("中介机构或法定机构产生、")
                                          ])
                                        : _vm._e(),
                                      scope.row.isOriginal == 1
                                        ? _c("span", [
                                            _vm._v(
                                              "正本原件" +
                                                _vm._s(scope.row.originalNum) +
                                                "份；"
                                            )
                                          ])
                                        : _vm._e(),
                                      scope.row.isCopy == 1
                                        ? _c("span", [
                                            _vm._v(
                                              "正本复印件" +
                                                _vm._s(scope.row.copyNum) +
                                                "份；"
                                            )
                                          ])
                                        : _vm._e(),
                                      scope.row.isDuplicateOriginal == 1
                                        ? _c("span", [
                                            _vm._v(
                                              "副本原件" +
                                                _vm._s(scope.row.duplicateNum) +
                                                "份；"
                                            )
                                          ])
                                        : _vm._e(),
                                      scope.row.isDuplicateCopy == 1
                                        ? _c("span", [
                                            _vm._v(
                                              "副本复印件" +
                                                _vm._s(
                                                  scope.row.duplicateCopyNum
                                                ) +
                                                "份；"
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "打印样表", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(s) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            plain: "",
                                            icon: "el-icon-printer"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.printFile(
                                                s.row.sampleFile
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("打印")]
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: { label: "扫码上传", align: "center" },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(s) {
                                    return [
                                      _c(
                                        "div",
                                        { key: _vm.uploadKey },
                                        [
                                          s.row.fileIds.length === 0
                                            ? _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "primary",
                                                    plain: "",
                                                    icon: "el-icon-mobile-phone"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.openUploadDialog(
                                                        s.row.materialId
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("手机扫码")]
                                              )
                                            : _c(
                                                "el-tag",
                                                {
                                                  attrs: { closable: "" },
                                                  on: {
                                                    close: function($event) {
                                                      return _vm.removePhoneUploadFile(
                                                        s.row.materialId
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("已上传")]
                                              )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "address",
                                label: "材料要求",
                                align: "center"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(s) {
                                    return [
                                      s.row.mMedia == 1
                                        ? _c("span", [_vm._v("纸质")])
                                        : s.row.mMedia == 2
                                        ? _c("span", [_vm._v("电子")])
                                        : s.row.mMedia == 3
                                        ? _c("span", [_vm._v("纸质、电子")])
                                        : _vm._e()
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "text-align": "center" },
                  attrs: { id: "nextHandlerButton" }
                },
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "saveBtn",
                      attrs: { type: "primary", loading: _vm.showLoading },
                      on: { click: _vm.prepareSave }
                    },
                    [_vm._v("下一步")]
                  )
                ],
                1
              )
            ],
            2
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "扫码上传",
                visible: _vm.uploadDialogControl,
                width: "30%",
                "close-on-click-modal": false,
                "close-on-press-escape": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.uploadDialogControl = $event
                },
                closed: function($event) {
                  return _vm.closeUploadDialog()
                }
              }
            },
            [
              _c("div", { staticClass: "wechat-upload" }, [
                _vm._v(" 请用手机微信-扫描二维码上传材料 ")
              ]),
              _c("el-result", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.fileUploadStatus,
                    expression: "fileUploadStatus"
                  }
                ],
                attrs: { icon: "success", title: "上传成功" }
              }),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.fileUploadStatus,
                    expression: "!fileUploadStatus"
                  }
                ],
                ref: "qrCodeUrl",
                staticClass: "qrcode"
              }),
              _c("div", { attrs: { slot: "footer" }, slot: "footer" })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }