<template>
  <div class="not-found">
    404
    <p>页面未找到</p>
  </div>
</template>
<script>
import {setRem} from '@/utils/commonFunc.js'
export default {
  name:'NotFound',
  mounted(){
    document.title = '页面未找到...'
    setRem(document)

    window.onresize = () => {
      return (() => {
        setRem(document)
      })();
    }
  }
}
</script>
<style lang="less" scoped>
.not-found{
  margin-top: 1rem;
  text-align: center;
  font-size: 1.5rem;
  p{
    font-size: 0.4rem;
  }
}
</style>