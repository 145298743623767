
/**
 * 开锁命令
 * 数组第3位：锁地址
 * 数组第4位：开锁功能码
 * 数组第5位：校验码
 */
export const openTheDoor = {
    // b1:['8A','01','01','11','9B'],
    // b2:['8A','01','02','11','98'],
    // b3:['8A','01','03','11','99'],
    // b4:['8A','01','04','11','9E'],
    // b5:['8A','01','05','11','9F'],
    // b6:['8A','01','06','11','9C'],
    // b7:['8A','01','07','11','9D'],
    // b8:['8A','01','08','11','92'],
    b1: ['8A', '01', '01', '11', '9B'],
    b2: ['8A', '01', '05', '11', '9F'],
    b3: ['8A', '01', '02', '11', '98'],
    b4: ['8A', '01', '06', '11', '9C'],
    b5: ['8A', '01', '03', '11', '99'],
    b6: ['8A', '01', '07', '11', '9D'],
    b7: ['8A', '01', '04', '11', '9E'],
    b8: ['8A', '01', '08', '11', '92'],
}

/**
 * 检查锁状态
 * @type {{b2: string[], b3: string[], b4: string[], b5: string[], b6: string[], b7: string[], b8: string[], b1: string[]}}
 */
export const doorStatus = {
    // b1:['80','01','01','33','B3'],
    // b2:['80','01','02','33','B0'],
    // b3:['80','01','03','33','B1'],
    // b4:['80','01','04','33','B6'],
    // b5:['80','01','05','33','B7'],
    // b6:['80','01','06','33','B4'],
    // b7:['80','01','07','33','B5'],
    // b8:['80','01','08','33','BA'],
    b1: ['80', '01', '01', '33', 'B3'],
    b2: ['80', '01', '05', '33', 'B7'],
    b3: ['80', '01', '02', '33', 'B0'],
    b4: ['80', '01', '06', '33', 'B4'],
    b5: ['80', '01', '03', '33', 'B1'],
    b6: ['80', '01', '07', '33', 'B5'],
    b7: ['80', '01', '04', '33', 'B6'],
    b8: ['80', '01', '08', '33', 'BA'],
}

