var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cabinetDoor" }, [
    _c("div", { staticClass: "boxHeader" }, [
      _c("div", { staticClass: "boxHeader-left" }, [
        _c("div", { on: { click: _vm.black } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }),
          _vm._v("返回")
        ])
      ]),
      _c("div", { staticClass: "boxHeader-center" }, [
        _vm._v("柜门已开，请取件后关闭柜门")
      ]),
      _c("div", { staticClass: "boxHeader-right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/handle_countdown.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("倒计时：" + _vm._s(_vm.authTime) + "s")])
      ])
    ]),
    _c("div", { staticClass: "boxContent" }, [
      _c(
        "ul",
        { staticClass: "boxContent-left" },
        _vm._l(_vm.arrData, function(item, index) {
          return _c("li", { key: index }, [
            _c("img", { attrs: { src: item.imgUrl, alt: "" } })
          ])
        }),
        0
      ),
      _c("div", { staticClass: "boxContent-right" })
    ]),
    _c("p", { staticClass: "text" }, [
      _vm._v("0" + _vm._s(_vm.cabinetObj.cabinetLocation) + "柜门在左下方打开")
    ]),
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.centerDialogVisible,
            expression: "centerDialogVisible"
          }
        ],
        staticClass: "elDialogAll"
      },
      [
        _c("div", { staticClass: "elDialogAllCenter" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unmanned_vehicle/takeparts_success.png"),
              alt: ""
            }
          }),
          _c("p", { staticClass: "p1" }, [_vm._v("取件成功")]),
          _c("p", { staticClass: "p2" }, [
            _vm._v("若遇到问题，请向客服求助：010-52315198")
          ]),
          _c("div", { staticClass: "btn", on: { click: _vm.notarize } }, [
            _vm._v("返回首页")
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "xiaJianTou" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/unmanned_vehicle/takeparts_leftarrow.png"),
          alt: ""
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }