var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "doPiece" }, [
    _c("div", { staticClass: "boxHeader" }, [
      _c("div", { staticClass: "boxHeader-left" }, [
        _c("div", { on: { click: _vm.black } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }),
          _vm._v("返回")
        ])
      ]),
      _c("div", { staticClass: "boxHeader-center" }, [
        _vm._v("请选择身份识别方式")
      ]),
      _c("div", { staticClass: "boxHeader-right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/handle_countdown.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("倒计时：" + _vm._s(_vm.countdown) + "s")])
      ])
    ]),
    _c("div", { staticClass: "boxContent" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/unmanned_vehicle/handle_logon1.png"),
          alt: ""
        }
      }),
      _c("div", { staticClass: "btnNam", on: { click: _vm.goIdentity } }, [
        _vm._v("身份证扫描")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }