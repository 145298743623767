<template>
  <div class="doPiece">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
      </div>
      <div class="boxHeader-center">请选择身份识别方式</div>
      <div class="boxHeader-right">
        <img src="@/assets/unmanned_vehicle/handle_countdown.png" alt="" />
        <p>倒计时：{{countdown}}s</p>
      </div>
    </div>
    <div class="boxContent">
      <img src="@/assets/unmanned_vehicle/handle_logon1.png" alt="" />
      <div class="btnNam" @click="goIdentity">身份证扫描</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "doPiece",
  data() {
    return {
			timer:null,
	    countdown:120
    };
  },
	mounted() {
		this.timer = setInterval(()=>{
			if(this.countdown===0){
				clearInterval(this.timer);
				this.$router.push('/mainIndex');
			}else{
				this.countdown--;
			}
		},1000)
	},
	beforeDestroy() {
		clearInterval(this.timer)
	},
	methods: {
      /**返回 */
      black(){
          this.$router.push('/mainIndex');
      },
      /**跳转身份证扫描 */
      goIdentity(){
         this.$router.push('/identityCard'); 
      }
  },
};
</script>

<style lang="less" scoped>
@baseZoom:0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.doPiece {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/homepage_bg.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    width: calc(160px*0.75);
    height: calc(72px*@baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(10px*@baseZoom);
    margin-left: calc(60px*@baseZoom);
    margin-top: calc(24px*@baseZoom);
    font-size: calc(32px*@baseZoom);
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(72px*@baseZoom);
  }
  .boxHeader-center {
    font-size: calc(48px*@baseZoom);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 100%;
    margin-top: calc(38px*@baseZoom);
  }
  .boxHeader-right {
    width: calc(260px*@baseZoom);
    height: calc(93px*@baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(47px*@baseZoom) calc(47px*@baseZoom) 0 0;
    margin-right: calc(60px*@baseZoom);
    margin-top: calc(44px*@baseZoom);
    display: flex;
    img {
      width: calc(28px*@baseZoom);
      height: calc(32px*@baseZoom);
      margin-top: calc(25px*@baseZoom);
      margin-left: calc(25px*@baseZoom);
    }
    p {
      font-size: calc(28px*@baseZoom);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(28px*@baseZoom);
      margin-left: calc(11px*@baseZoom);
    }
  }
}
.boxContent {
  width: calc(590px*@baseZoom);
  height: calc(720px*@baseZoom);
  background: #f3f6ff;
  border-radius: calc(20px*@baseZoom);
  margin: auto;
  margin-top: calc(100px*@baseZoom);
  overflow: hidden;
  img {
    display: block;
    width: calc(380px*@baseZoom);
    height: calc(260px*@baseZoom);
    margin: auto;
    margin-top: calc(128px*@baseZoom);
  }
  .btnNam {
    width: calc(360px*@baseZoom);
    height: calc(88px*@baseZoom);
    background: #2f56ff;
    box-shadow: 0px calc(10px*@baseZoom) calc(20px*@baseZoom) 0px rgba(38, 105, 255, 0.4);
    border-radius: calc(44px*@baseZoom);
    font-size: calc(32px*@baseZoom);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(88px*@baseZoom);
    margin: auto;
    margin-top: calc(133px*@baseZoom);
  }
}
</style>