<template>
  <div class="matters-list">
    <div class="search-area">
      <form action="/">
        <van-search class="van-search-c" v-model="searchForm" placeholder="请输入搜索关键词" @search="onSearch" @clear="onClear">
          <template #left-icon>
            <van-icon name="search" color="#fff" />
          </template>
        </van-search>
      </form>
    </div>
      <van-list 
        v-model="listLoading" 
        :finished="listFinished" 
        finished-text="没有更多了" 
        :error.sync="listError"
        error-text="请求失败，点击重新加载" 
        @load="getTaskList()">
        <van-row type="flex" gutter="8">
          <van-col span="12" v-for="item in listData" :key="item.taskId">
            <div class="themes-list-item">
              <div class="themes-list-item-link" :title="item.taskName" @click="goToFill(item)">
                <div class="themes-names">{{item.taskName}}</div>
                <div class="right-arraw"></div>
              </div>
            </div>
          </van-col>
        </van-row>
      </van-list>
  </div>
</template>

<script>
  import {Row, Col,Search, List, Icon } from 'vant'; // ui

  import {setRem} from '@/utils/commonFunc.js'
  export default {
    name:'mattersList',
    components:{
      vanRow:Row,
      vanCol:Col,
      vanSearch:Search,
      vanList:List,
      vanIcon:Icon
    },
    data(){
      return {
        searchForm:'',
        pageNum:1,
        pageSize:20,
        listLoading:false,
        listFinished:false,
        listError:false,
        listData:[],
      }
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          setRem(document)
        })();
      }
      this.$nextTick(()=>{
        setRem(document);
        // this.createQrcode()
      })
    },
    methods:{
      /** search */
      onSearch(){
        this.listData = [];
        this.pageNum=1;
        this.listFinished=false;
        this.$nextTick(()=>{
          this.getTaskList();
        })
      },
      /** 清空搜索 */
      onClear(){
        this.onSearch()
      },
      /**获取列表 */
      getTaskList(){
        let param = {
          areaId:'110109000000',pageNum:this.pageNum,pageSize:10
        };
        if(this.searchForm){
          param.taskName = this.searchForm
        }
        this.$post('/template/getTaskList',param,).then(res=>{
          let {code,list,pageNum} = res.data;
          if(code===200){
            if(list.length===0){
              this.listFinished=true;
              this.pageNum = pageNum
            }else{
              this.listLoading=false;
              list.forEach(item=>{
                this.listData.push(item)
              })
              this.pageNum++;
            }
          }else{
            this.listError=true;
          }
        }).catch(()=>{
          this.listError=true;
        })
      },
      /**填写表单 */
      goToFill(item){
        this.$router.push({name:'formFill',query:{taskId:item.taskId,taskName:item.taskName}})
      }
    }
  }
</script>

<style lang="less" scoped>
.matters-list{
  padding: 0 0.24rem;
}
.search-area{
  /* position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  background-color: rgba(255, 255, 255,0);
  z-index:10; */
  margin-top: 0.72rem;
  margin-bottom: 0.48rem;
}
.themes-list-item{
  margin-bottom: 0.16rem;
}
.themes-list-item > div{
  height: 1.42rem;
  background-color: #fff;
  border-radius: 10px;
  padding: 0 0.2rem;
}
.themes-list-item-link{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.themes-names{
  width: 2.4rem;
  color: #333;
  font-size: 0.3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient:vertical;
  -webkit-line-clamp:2; 
}
.right-arraw{
  width: 0.32rem;
  height: 0.32rem;
  background: #98B0F7;
  box-shadow: 0px 10px 12px 0px rgba(119, 150, 242, 0.2);
  border-radius: 50%;
  background-image: url('./assets/taiyuan_theme_next.png');
  background-size: 0.12rem 0.18rem;
  background-repeat: no-repeat;
  background-position:65%;
}
/* .themes-list-item:nth-child(odd){
  padding-left: 0;
  padding-right: 0.08rem;
}
.themes-list-item:nth-child(even){
  padding-left: 0.08rem;
  padding-right: 0;
} */
.van-search-c{
  border-radius: 40px;
  background-color: rgba(255,255,255,0.3);
  &::v-deep .van-field__control{
    color:#fff;
  }
  &::v-deep .van-field__control::-webkit-input-placeholder{
    color:#fff
  }
  & .van-cell::after{
    border-bottom:none;
  }
}
</style>
<style lang="less">
body{
  background-image:url('./assets/taiyuan_theme_bg.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #EDEDED;
}
</style>