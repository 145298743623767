<template>
  <div class="bergsonism">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
      </div>
      <div class="boxHeader-center">请选择箱格大小</div>
      <div class="boxHeader-right">
        <img src="@/assets/unmanned_vehicle/handle_countdown.png" alt="" />
        <p>倒计时：{{ authTime }}s</p>
      </div>
    </div>
    <div class="boxContent">
      <el-form :model="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="收件人信息">
          <el-input v-model="ruleForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="办件编号">
          <el-input v-model="ruleForm.reacId" disabled></el-input>
        </el-form-item>
      </el-form>
      <ul class="geZi">
        <li
          v-for="(item, index) in arrData"
          :key="index"
          @click="getBox(index)"
        >
          <img :src="index == ins ? item.imgUrlAll : item.imgUrl" alt="" />
        </li>
      </ul>
      <p class="tiShi">注：数值代表 长*宽*深，单位为毫米（mm）</p>
      <div class="btn" @click="submitForm()">下一步</div>
    </div>
  </div>
</template>

<script>
import { openTheDoor, doorStatus } from "@/utils/instructionList";
import { serialHandler } from "@/utils/serialHandler";
export default {
  name: "bergsonism",
  data() {
    return {
      authTime: 120, //倒计时
      informationData: this.$route.query.informationData,
      ins: 3,
      arrData: [
        {
          id: 1,
          imgUrl: require("../assets/unmanned_vehicle/litterbox-unselect.png"),
          imgUrlAll: require("../assets/unmanned_vehicle/litterbox-selected.png"),
        },
        {
          id: 2,
          imgUrl: require("../assets/unmanned_vehicle/mediumbox-unselect.png"),
          imgUrlAll: require("../assets/unmanned_vehicle/mediumbox-selected.png"),
        },
      ],
      ruleForm: {
        name: "",
        reacId: "",
        taskName: "",
        iphone: "",
        cabinetLocation: "",
      },
    };
  },
  created() {
    this.getMaterials();
  },
  mounted() {
    this.getTimeCode(); //倒计时
  },
  methods: {
    /**处理材料数据 */
    getMaterials() {
      let materialsData = JSON.parse(this.informationData);
      this.ruleForm.iphone = materialsData[0].applyerMobile;
      let materialsDataTo = []; //收件人信息
      let materialsDataThree = []; //办件编号
      let materialsDataFoor = []; //事项名称
      materialsData.map((item) => {
        let str = item.applyerName + " " + item.applyerMobile;
        let strTo = item.recvId;
        let strToo = item.taskName;
        materialsDataTo.push(str);
        materialsDataThree.push(strTo);
        materialsDataFoor.push(strToo);
      });
      this.ruleForm.name = materialsDataTo.join(",");
      this.ruleForm.reacId = materialsDataThree.join(",");
      this.ruleForm.taskName = materialsDataFoor.join(",");
    },
    /**倒计时 */
    getTimeCode() {
      var authTimeNum = setInterval(() => {
        this.authTime--;
        if (this.authTime === 0) {
          clearInterval(authTimeNum);
          this.$router.push("/mainIndex");
        }
      }, 1000);
    },
    /**返回 */
    black() {
      this.$router.push("/mainIndex");
    },
    /**点击切换箱子 */
    getBox(index) {
      this.ins = index;
      console.log(this.ins);
    },
    /**下一步 */
    submitForm() {
      if (this.ins > 1) {
        return this.$message.error("请选择箱子大小！");
      }
      let data = {
        carCode: "1",
      };
      this.$post("/CarCabinet/findCarCode", data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          this.cabinetLocation = res.data.list[0];
          let instruction;
          if (this.cabinetLocation == "1") {
            instruction = openTheDoor.b1;
          } else if (this.cabinetLocation == "2") {
            instruction = openTheDoor.b2;
          } else if (this.cabinetLocation == "3") {
            instruction = openTheDoor.b3;
          } else if (this.cabinetLocation == "4") {
            instruction = openTheDoor.b4;
          } else if (this.cabinetLocation == "5") {
            instruction = openTheDoor.b5;
          } else if (this.cabinetLocation == "6") {
            instruction = openTheDoor.b6;
          } else if (this.cabinetLocation == "7") {
            instruction = openTheDoor.b7;
          } else if (this.cabinetLocation == "8") {
            instruction = openTheDoor.b8;
          }

          serialHandler.init().then(() => {
            serialHandler.writeCOM(instruction).then(() => {
              serialHandler.readCOM().then((value) => {
                console.log(value,'存件看看返回开门数据没-----');
                  this.$router.push({
                    path: "/cabinetDoorOpen",
                    query: {
                      iphone: this.ruleForm.iphone,
                      recvId: this.ruleForm.reacId,
                      ins: this.ins,
                      taskName: this.ruleForm.taskName,
                      cabinetLocation: this.cabinetLocation,
                    },
                  });
              });
            });
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@baseZoom: 0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.bergsonism {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/homepage_bg.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    width: calc(160px * @baseZoom);
    height: calc(72px * @baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(10px * @baseZoom);
    margin-left: calc(60px * @baseZoom);
    margin-top: calc(24px * @baseZoom);
    font-size: calc(32px * @baseZoom);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(72px * @baseZoom);
  }
  .boxHeader-center {
    font-size: calc(48px * @baseZoom);
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 100%;
    margin-top: calc(38px * @baseZoom);
  }
  .boxHeader-right {
    width: calc(260px * @baseZoom);
    height: calc(93px * @baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(47px * @baseZoom) calc(47px * @baseZoom) 0px 0px;
    margin-right: calc(60px * @baseZoom);
    margin-top: calc(44px * @baseZoom);
    display: flex;
    img {
      width: calc(28px * @baseZoom);
      height: calc(32px * @baseZoom);
      margin-top: calc(25px * @baseZoom);
      margin-left: calc(25px * @baseZoom);
    }
    p {
      font-size: calc(28px * @baseZoom);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(28px * @baseZoom);
      margin-left: calc(11px * @baseZoom);
    }
  }
}
.boxContent {
  width: 95%;
  margin: auto;
  margin-top: calc(48px * @baseZoom);
}
.demo-ruleForm {
  zoom: 1.5;
}
.geZi {
  width: 100%;
  height: calc(482px * @baseZoom);
  display: flex;
  justify-content: space-between;
  li {
    width: calc(842px * @baseZoom);
    height: calc(482px * @baseZoom);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.tiShi {
  font-size: calc(24px * @baseZoom);
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #e67e2a;
  line-height: 100%;
  margin-top: calc(23px * @baseZoom);
}
.btn {
  width: calc(360px * @baseZoom);
  height: calc(80px * @baseZoom);
  background: #2f56ff;
  box-shadow: 0px calc(10px * @baseZoom) calc(20px * @baseZoom) 0px
    rgba(47, 86, 255, 0.4);
  border-radius: calc(10px * @baseZoom);
  font-size: calc(32px * @baseZoom);
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffffff;
  line-height: calc(80px * @baseZoom);
  text-align: center;
  margin: auto;
  margin-top: calc(40px * @baseZoom);
  cursor: pointer;
}
</style>