var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-submit" },
    [
      _c(
        "van-form",
        { staticClass: "form-card", attrs: { "label-width": "120px" } },
        [
          _c("div", { staticClass: "form-card-content" }, [
            _c("img", {
              staticClass: "form-card-icon",
              attrs: { src: require("./assets/icon_5.png"), alt: "" }
            }),
            _c("span", { staticClass: "form-card-span" }, [
              _vm._v(" 材料提交 ")
            ])
          ]),
          _c("van-field", {
            staticClass: "van-field-c",
            attrs: { label: "材料提交途径", readonly: "" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function() {
                  return [
                    _c(
                      "van-radio-group",
                      { attrs: { value: "2", direction: "horizontal" } },
                      [
                        _c(
                          "van-radio",
                          {
                            attrs: { name: "1" },
                            scopedSlots: _vm._u([
                              {
                                key: "icon",
                                fn: function(p) {
                                  return [
                                    _c("custom-radio", {
                                      attrs: { checked: p.checked }
                                    })
                                  ]
                                }
                              }
                            ])
                          },
                          [_vm._v("上门取件 ")]
                        ),
                        _c(
                          "van-radio",
                          {
                            attrs: { name: "2" },
                            scopedSlots: _vm._u([
                              {
                                key: "icon",
                                fn: function(p) {
                                  return [
                                    _c("custom-radio", {
                                      attrs: { checked: p.checked }
                                    })
                                  ]
                                }
                              }
                            ])
                          },
                          [_vm._v("现场办理 ")]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          }),
          false
            ? _c(
                "div",
                [
                  _c("van-field", {
                    staticClass: "van-field-c",
                    attrs: { label: "姓名", placeholder: "请输入联系人姓名" }
                  }),
                  _c("van-field", {
                    staticClass: "van-field-c",
                    attrs: {
                      label: "手机号",
                      placeholder: "请输入联系人手机号"
                    }
                  }),
                  _c("van-field", {
                    staticClass: "van-field-c",
                    attrs: { label: "地址", placeholder: "请输入联系人地址" }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("van-cell", {
            staticClass: "van-field-c",
            attrs: { title: "办理地址", value: _vm.guidesObject.transactaddr }
          }),
          _c("van-cell", {
            staticClass: "van-field-c",
            attrs: { title: "工作时间", value: _vm.guidesObject.transacttime }
          })
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "submit-button-c",
          on: {
            click: function($event) {
              return _vm.submitData()
            }
          }
        },
        [_vm._v(" 提 交 ")]
      ),
      _c("van-overlay", { attrs: { show: _vm.showOverlay } }, [
        _c(
          "div",
          { staticClass: "wrapper-c" },
          [
            _c("van-loading", { attrs: { type: "spinner", vertical: "" } }, [
              _vm._v("提交中，请稍后...")
            ])
          ],
          1
        )
      ]),
      _c(
        "van-dialog",
        {
          attrs: { showConfirmButton: false, "close-on-click-overlay": true },
          on: {
            closed: function($event) {
              return _vm.judgeRouter()
            }
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "span",
            {
              staticClass: "close-span",
              on: {
                click: function() {
                  _vm.showDialog = false
                }
              }
            },
            [_c("van-icon", { attrs: { name: "cross" } })],
            1
          ),
          _c("div", { staticClass: "dialog-content" }, [
            _c("img", {
              staticStyle: { width: "1.28rem" },
              attrs: { src: require("./assets/success.png"), alt: "提交成功" }
            }),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "0.36rem",
                  color: "#6F8DE6",
                  margin: "0"
                }
              },
              [_vm._v("申报成功")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "0.24rem",
                  color: "#666",
                  "margin-top": "0.24rem",
                  "margin-bottom": "0.39rem"
                }
              },
              [_vm._v("您已申报成功，我们正在加速处理")]
            ),
            _c(
              "p",
              {
                staticStyle: {
                  margin: "0",
                  color: "#999",
                  "font-size": "0.24rem",
                  "line-height": "0.4rem"
                }
              },
              [
                _vm._v(_vm._s(_vm.guidesObject.deptname) + "，工作时间： "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.guidesObject.transacttime))
              ]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }