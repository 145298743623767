var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "phone-upload" }, [
    _c("p", { staticClass: "phone-upload-title" }, [
      _vm._v(_vm._s(_vm.materialName))
    ]),
    _c(
      "div",
      { staticClass: "phone-upload-tab" },
      _vm._l(_vm.tabList, function(item) {
        return _c(
          "div",
          {
            key: item.id,
            staticClass: "phone-upload-tabs",
            class: { "active-class": _vm.tabIndex === item.id },
            on: {
              click: function() {
                _vm.tabIndex = item.id
              }
            }
          },
          [_vm._v(_vm._s(item.title))]
        )
      }),
      0
    ),
    _vm.tabIndex === 1
      ? _c(
          "div",
          { staticClass: "upload-content" },
          [
            _c(
              "vanUploader",
              {
                attrs: { "after-read": _vm.afterRead, "max-count": "1" },
                model: {
                  value: _vm.fileList1,
                  callback: function($$v) {
                    _vm.fileList1 = $$v
                  },
                  expression: "fileList1"
                }
              },
              [_c("div", { staticClass: "uploader-icon-p" })]
            )
          ],
          1
        )
      : _vm._e(),
    _vm.tabIndex === 2
      ? _c(
          "div",
          { staticClass: "upload-content" },
          [
            _c(
              "vanUploader",
              {
                attrs: {
                  "after-read": _vm.afterRead,
                  capture: "camera",
                  "max-count": "1"
                },
                model: {
                  value: _vm.fileList2,
                  callback: function($$v) {
                    _vm.fileList2 = $$v
                  },
                  expression: "fileList2"
                }
              },
              [_c("div", { staticClass: "uploader-icon-c" })]
            )
          ],
          1
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "submit-button", on: { click: _vm.submitHandler } },
      [_vm._v(" 上传至无人车 ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }