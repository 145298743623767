/**
 * 访问串口
 */
class SerialHandler{
    reader;
    writer;
    serialPort;
    encoder = new TextEncoder();
    decoder = new TextDecoder();
    async init(){
        if("serial" in navigator){
            try {
                let port;
                port = await navigator.serial.getPorts();
                if(port.length===0){
                    this.serialPort = await navigator.serial.requestPort();
                }else{
                    this.serialPort = port[0];
                }
                await this.serialPort.open({ baudRate: 9600, dataBits:8, stopBits:1, flowControl:'none' });
                this.writer = this.serialPort.writable.getWriter();
                this.reader = this.serialPort.readable.getReader();
                const signals = await this.serialPort.getSignals();
                console.log(signals);
            }
            catch (err) {
                console.error('There was an error opening the serial port:', err);
            }
        }else {
            console.error('Web serial doesn\'t seem to be enabled in your browser. Try enabling it by visiting:');
            console.error('chrome://flags/#enable-experimental-web-platform-features');
            console.error('opera://flags/#enable-experimental-web-platform-features');
            console.error('edge://flags/#enable-experimental-web-platform-features');
        }
    }
    async readCOM(){
        try {
            while (true) {
                const { value, done } = await this.reader.read();
                if (done) {
                    // 接收完毕，串口解锁
                    this.reader.releaseLock();
                    break;
                }
                if (value) {
                    let origin = []
                    value.forEach(item=>{
                        origin.push(item.toString(16).toLocaleUpperCase())
                    })
                    console.log('readCOM:',origin)
                    return origin
                }
            }
        } catch (error) {
            // 错误处理
        }
    }

    /**
     * @param {string[]} data
     * @return {Promise<*>}
     */
    async writeCOM(data){
        let hex_array = data.map(el=>parseInt(el,16));
        let uint8Array = new Uint8Array(hex_array);
        console.log('writeCOM:',uint8Array)
        return await this.writer.write(uint8Array)
    }
    async closeCOM(){
        await this.serialPort.close()
    }
}

export const serialHandler = new SerialHandler();
