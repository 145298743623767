var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "collectAparcel" }, [
    _c("div", { staticClass: "boxHeader" }, [
      _c("div", { staticClass: "boxHeader-left" }, [
        _c("div", { on: { click: _vm.black } }, [
          _c("i", { staticClass: "el-icon-arrow-left" }),
          _vm._v("返回")
        ])
      ]),
      _c("div", { staticClass: "boxHeader-center" }, [
        _vm._v("请输入6位取件码")
      ]),
      _c("div", { staticClass: "boxHeader-right" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/handle_countdown.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("倒计时：" + _vm._s(_vm.authTime) + "s")])
      ])
    ]),
    _c("div", { staticClass: "boxContent" }, [
      _c(
        "div",
        {
          staticClass: "input-content",
          on: {
            keydown: _vm.keydown,
            keyup: _vm.keyup,
            paste: _vm.paste,
            mousewheel: _vm.mousewheel,
            input: _vm.inputEvent
          }
        },
        [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.number",
                value: _vm.input[0],
                expression: "input[0]",
                modifiers: { trim: true, number: true }
              }
            ],
            ref: "firstinput",
            attrs: {
              max: "9",
              min: "0",
              maxlength: "1",
              "data-index": "0",
              type: "number"
            },
            domProps: { value: _vm.input[0] },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.input, 0, _vm._n($event.target.value.trim()))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.number",
                value: _vm.input[1],
                expression: "input[1]",
                modifiers: { trim: true, number: true }
              }
            ],
            attrs: {
              max: "9",
              min: "0",
              maxlength: "1",
              "data-index": "1",
              type: "number"
            },
            domProps: { value: _vm.input[1] },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.input, 1, _vm._n($event.target.value.trim()))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.number",
                value: _vm.input[2],
                expression: "input[2]",
                modifiers: { trim: true, number: true }
              }
            ],
            attrs: {
              max: "9",
              min: "0",
              maxlength: "1",
              "data-index": "2",
              type: "number"
            },
            domProps: { value: _vm.input[2] },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.input, 2, _vm._n($event.target.value.trim()))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.number",
                value: _vm.input[3],
                expression: "input[3]",
                modifiers: { trim: true, number: true }
              }
            ],
            attrs: {
              max: "9",
              min: "0",
              maxlength: "1",
              "data-index": "3",
              type: "number"
            },
            domProps: { value: _vm.input[3] },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.input, 3, _vm._n($event.target.value.trim()))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.number",
                value: _vm.input[4],
                expression: "input[4]",
                modifiers: { trim: true, number: true }
              }
            ],
            attrs: {
              max: "9",
              min: "0",
              maxlength: "1",
              "data-index": "4",
              type: "number"
            },
            domProps: { value: _vm.input[4] },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.input, 4, _vm._n($event.target.value.trim()))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim.number",
                value: _vm.input[5],
                expression: "input[5]",
                modifiers: { trim: true, number: true }
              }
            ],
            attrs: {
              max: "9",
              min: "0",
              maxlength: "1",
              "data-index": "5",
              type: "number"
            },
            domProps: { value: _vm.input[5] },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.input, 5, _vm._n($event.target.value.trim()))
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }