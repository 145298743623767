var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "communicate" }, [
    _c("div", { staticClass: "boxHeader" }, [
      _c("div", { staticClass: "boxHeader-left" }, [
        _c("img", {
          staticClass: "boxHeader-left-logo",
          attrs: { src: require("@/assets/unmanned_vehicle/logo.png"), alt: "" }
        }),
        _c("p", [_c("timer-component")], 1)
      ])
    ]),
    _c("div", { staticClass: "boxContet" }, [
      _c("div", { staticClass: "boxContet-left" }, [
        _c("div", { staticClass: "heFrames", attrs: { id: "he-frames" } }),
        _c(
          "div",
          {
            staticClass: "guaDuan",
            attrs: { title: "挂断" },
            on: { click: _vm.hungup }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/unmanned_vehicle/refuse.png"),
                alt: ""
              }
            })
          ]
        ),
        _c("p", [_vm._v("挂断")])
      ]),
      _vm._m(0)
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "boxContet-right" }, [
      _c("div", { staticClass: "meFrames", attrs: { id: "meFrames" } }),
      _c("div", { staticClass: "diZhi" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/location.png"),
            alt: ""
          }
        }),
        _vm._v("北京市亦庄经济技术开发区街道 ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }