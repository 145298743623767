var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-index" }, [
    _c("div", { staticClass: "boxHeader" }, [
      _c("div", { staticClass: "boxHeader-left" }, [
        _c("img", {
          staticClass: "boxHeader-left-logo",
          attrs: { src: require("@/assets/unmanned_vehicle/logo.png"), alt: "" }
        }),
        _c("p", [_c("timer-component")], 1)
      ]),
      _c(
        "div",
        {
          staticClass: "boxHeader-right",
          on: {
            click: function($event) {
              _vm.centerDialogVisible = true
            }
          }
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/unmanned_vehicle/homepage_helphandle.png"),
              alt: ""
            }
          }),
          _c("p", [_vm._v("视频帮办")])
        ]
      )
    ]),
    _c("div", { staticClass: "boxContent" }, [
      _c(
        "div",
        { staticClass: "boxContent-left", on: { click: _vm.goPiece } },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/unmanned_vehicle/homepage_module1_word.png"),
              alt: ""
            }
          }),
          _c("p", [_vm._v("快速办理热门事项")])
        ]
      ),
      _c("div", { staticClass: "boxContent-right" }, [
        _c("div", { staticClass: "cunJian", on: { click: _vm.goDoSavea } }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unmanned_vehicle/homepage_module2_word.png"),
              alt: ""
            }
          }),
          _c("p", [_vm._v("轻松放置办理所需文件")])
        ]),
        _c("div", { staticClass: "quJian", on: { click: _vm.goCollect } }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unmanned_vehicle/homepage_module3_word.png"),
              alt: ""
            }
          }),
          _c("p", [_vm._v("随时随地一键取出")])
        ])
      ])
    ]),
    _vm._m(0),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.centerDialogVisible,
            expression: "centerDialogVisible"
          }
        ],
        staticClass: "elDialogAll"
      },
      [
        _c("div", { staticClass: "elDialogAllCenter" }, [
          _c("img", {
            attrs: {
              src: require("@/assets/unmanned_vehicle/videocall.png"),
              alt: ""
            }
          }),
          _c("p", [_vm._v("您将进行视频通话，确认开启帮办吗？")]),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                {
                  staticClass: "btn-quXiao",
                  on: {
                    click: function($event) {
                      _vm.centerDialogVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "btn-queDing",
                  attrs: { type: "primary" },
                  on: { click: _vm.notarize }
                },
                [_vm._v("确认")]
              )
            ],
            1
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", { staticClass: "boxFooter" }, [
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/homepage_location.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("当前位置：北京市丰台区六里桥奈伦大厦")])
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/homepage_phone.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("客服电话：010-82318888")])
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/homepage_technology.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("技术支持：北京新广视通科技集团有限责任公司")])
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src: require("@/assets/unmanned_vehicle/homepage_edition.png"),
            alt: ""
          }
        }),
        _c("p", [_vm._v("版本号：V1.0")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }