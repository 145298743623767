<template>
  <div class="cabinetDoor">
    <div class="boxHeader">
      <div class="boxHeader-left">
        <div @click="black"><i class="el-icon-arrow-left"></i>返回</div>
      </div>
      <div class="boxHeader-center">柜门已开，请取件后关闭柜门</div>
      <div class="boxHeader-right">
        <img src="@/assets/unmanned_vehicle/handle_countdown.png" alt="" />
        <p>倒计时：{{ authTime }}s</p>
      </div>
    </div>
    <div class="boxContent">
      <ul class="boxContent-left">
        <li v-for="(item, index) in arrData" :key="index">
          <img :src="item.imgUrl" alt="" />
        </li>
      </ul>
      <div class="boxContent-right"></div>
    </div>
    <p class="text">0{{ cabinetObj.cabinetLocation }}柜门在左下方打开</p>
    <div class="xiaJianTou">
      <img src="@/assets/unmanned_vehicle/takeparts_leftarrow.png" alt="" />
    </div>

    <!-- 取件成功弹框 -->
    <div class="elDialogAll" v-show="centerDialogVisible">
      <div class="elDialogAllCenter">
        <img src="@/assets/unmanned_vehicle/takeparts_success.png" alt="" />
        <p class="p1">取件成功</p>
        <!-- <div class="anew" @click="getNewOpen" v-show="showDoom">手快关错门？点击再次打开</div> -->
        <p class="p2">若遇到问题，请向客服求助：010-52315198</p>
        <div class="btn" @click="notarize">返回首页</div>
      </div>
    </div>
  </div>
</template>

<script>
import { openTheDoor, doorStatus } from "@/utils/instructionList";
import { serialHandler } from "@/utils/serialHandler";
export default {
  name: "cabinetDoor",
  data() {
    return {
      authTime: 120, //倒计时
      //柜子信息
      cabinetObj: {
        cabinetCode: this.$route.query.cabinetCode, //柜子验证码
        cabinetLocation: this.$route.query.cabinetLocation, //柜子位置
        cabinetStatus: this.$route.query.cabinetStatus, //柜子状态
        cabinetTime: this.$route.query.cabinetTime, //柜子更新时间
        cabinetType: this.$route.query.cabinetType, //柜子类型
        carCode: this.$route.query.carCode, //无人车编号
        id: this.$route.query.id, //ID
        iphone: this.$route.query.iphone, //手机号
        recvid: this.$route.query.recvid, //办件单号
        quCode: this.$route.query.quCode, //取件码
      },
      centerDialogVisible: false,
      showDoom: true,
      arrData: [
        {
          id: 1,
          imgUrl: require("@/assets/unmanned_vehicle/01.png"),
        },
        {
          id: 2,
          imgUrl: require("@/assets/unmanned_vehicle/02.png"),
        },
        {
          id: 3,
          imgUrl: require("@/assets/unmanned_vehicle/03.png"),
        },
        {
          id: 4,
          imgUrl: require("@/assets/unmanned_vehicle/04.png"),
        },
        {
          id: 5,
          imgUrl: require("@/assets/unmanned_vehicle/05.png"),
        },
        {
          id: 6,
          imgUrl: require("@/assets/unmanned_vehicle/06.png"),
        },
        {
          id: 7,
          imgUrl: require("@/assets/unmanned_vehicle/07.png"),
        },
        {
          id: 8,
          imgUrl: require("@/assets/unmanned_vehicle/08.png"),
        },
      ],
    };
  },
  created() {
    this.getCabinet();
    this.getCirculations();
  },
  mounted() {
    this.getTimeCode(); //倒计时
  },
  methods: {
    /**轮循红外线 */
    getCirculations() {
      console.log('开始循环取件红外线-----');
      var timeOutAus = setInterval(() => {
        //获取9c开头的
        serialHandler.readCOM().then((value) => {
          console.log(value,'查看取件红外线返回数据-------------------')
          if (value) {
            clearInterval(timeOutAus);
            this.getLookTions();
          }
        });
      }, 2000);
    },

    /**轮循门的状态 */
    getLookTions() {
      console.log('开始循环取件门关没关-----');
      let doorStatusNum;
      if (this.cabinetObj.cabinetLocation == "1") {
        doorStatusNum = doorStatus.b1;
      } else if (this.cabinetObj.cabinetLocation == "2") {
        doorStatusNum = doorStatus.b2;
      } else if (this.cabinetObj.cabinetLocation == "3") {
        doorStatusNum = doorStatus.b3;
      } else if (this.cabinetObj.cabinetLocation == "4") {
        doorStatusNum = doorStatus.b4;
      } else if (this.cabinetObj.cabinetLocation == "5") {
        doorStatusNum = doorStatus.b5;
      } else if (this.cabinetObj.cabinetLocation == "6") {
        doorStatusNum = doorStatus.b6;
      } else if (this.cabinetObj.cabinetLocation == "7") {
        doorStatusNum = doorStatus.b7;
      } else if (this.cabinetObj.cabinetLocation == "8") {
        doorStatusNum = doorStatus.b8;
      }

      var timeOutAus1 = setInterval(() => {
        serialHandler.writeCOM(doorStatusNum).then(() => {
          serialHandler.readCOM().then((valueTo) => {
            console.log(valueTo,'查看取件关门状态-------')
            if (valueTo[3] == "0") {
              clearInterval(timeOutAus1);
              //关门成功  弹窗
              this.getTakOut();
            }
          });
        });
      }, 2000);
    },

    /**倒计时 */
    getTimeCode() {
      var authTimeNum = setInterval(() => {
        this.authTime--;
        if (this.authTime === 0) {
          clearInterval(authTimeNum);
          this.$router.push("/mainIndex");
        }
      }, 1000);
    },
    /**返回 */
    black() {
      this.$router.push("/collectAparcel");
    },
    /**返回首页 */
    notarize() {
      this.centerDialogVisible = false;
      this.$router.push("/mainIndex");
    },
    /**替换柜子图片 */
    getCabinet() {
      this.arrData.map((item) => {
        if (this.cabinetObj.cabinetLocation == 1 && item.id == 1) {
          item.imgUrl = require("@/assets/unmanned_vehicle/01-open.png");
        } else if (this.cabinetObj.cabinetLocation == 2 && item.id == 2) {
          item.imgUrl = require("@/assets/unmanned_vehicle/02-open.png");
        } else if (this.cabinetObj.cabinetLocation == 3 && item.id == 3) {
          item.imgUrl = require("@/assets/unmanned_vehicle/03-open.png");
        } else if (this.cabinetObj.cabinetLocation == 4 && item.id == 4) {
          item.imgUrl = require("@/assets/unmanned_vehicle/04-open.png");
        } else if (this.cabinetObj.cabinetLocation == 5 && item.id == 5) {
          item.imgUrl = require("@/assets/unmanned_vehicle/05-open.png");
        } else if (this.cabinetObj.cabinetLocation == 6 && item.id == 6) {
          item.imgUrl = require("@/assets/unmanned_vehicle/06-open.png");
        } else if (this.cabinetObj.cabinetLocation == 7 && item.id == 7) {
          item.imgUrl = require("@/assets/unmanned_vehicle/07-open.png");
        } else if (this.cabinetObj.cabinetLocation == 8 && item.id == 8) {
          item.imgUrl = require("@/assets/unmanned_vehicle/08-open.png");
        }
      });
    },
    /**取件成功 */
    getTakOut() {
      let data = {
        code: this.cabinetObj.quCode,
      };
      this.$post("/CarCabinet/storageTakeOut", data).then((res) => {
        if (res.data.code == 200) {
          this.centerDialogVisible = true;
        }
      });
    },
    /** 重新打开*/
    getNewOpen() {
      this.centerDialogVisible = false;
      let data = {
        code: "666666",
      };
      this.$post("/CarCabinet/findCode", data).then((res) => {
        console.log(res);
        this.showDoom = false;
        if (res.data.code == 200) {
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
@baseZoom: 0.75;
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
.cabinetDoor {
  width: 100%;
  height: 100vh;
  background: url(../assets/unmanned_vehicle/homepage_bg.png) no-repeat;
  background-size: 100% 100%;
}
.boxHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .boxHeader-left {
    width: calc(160px * @baseZoom);
    height: calc(72px * @baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(10px * @baseZoom);
    margin-left: calc(60px * @baseZoom);
    margin-top: calc(24px * @baseZoom);
    font-size: calc(32px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: calc(72px * @baseZoom);
  }
  .boxHeader-center {
    font-size: calc(48px * @baseZoom);
    font-weight: 400;
    color: #ffffff;
    line-height: 100%;
    margin-top: calc(38px * @baseZoom);
  }
  .boxHeader-right {
    width: calc(260px * @baseZoom);
    height: calc(93px * @baseZoom);
    background: rgba(255, 255, 255, 0.3);
    border-radius: calc(47px * @baseZoom) calc(47px * @baseZoom) 0px 0px;
    margin-right: calc(60px * @baseZoom);
    margin-top: calc(44px * @baseZoom);
    display: flex;
    img {
      width: calc(28px * @baseZoom);
      height: calc(32px * @baseZoom);
      margin-top: calc(25px * @baseZoom);
      margin-left: calc(25px * @baseZoom);
    }
    p {
      font-size: calc(28px * @baseZoom);
      font-weight: 400;
      color: #ffffff;
      line-height: 100%;
      margin-top: calc(28px * @baseZoom);
      margin-left: calc(11px * @baseZoom);
    }
  }
}
.boxContent {
  width: 100%;
  height: calc(522px * @baseZoom);
  margin-top: calc(102px * @baseZoom);
  display: flex;
  .boxContent-left {
    width: calc(636px * @baseZoom);
    height: calc(450px * @baseZoom);
    border: calc(4px * @baseZoom) solid #ffa414;
    border-radius: calc(20px * @baseZoom);
    margin-left: calc(170px * @baseZoom);
    margin-top: calc(5px * @baseZoom);
    li {
      width: 49.5%;
      height: calc(110px * @baseZoom);
      float: left;
      margin-bottom: calc(2px * @baseZoom);
      margin-left: calc(2px * @baseZoom);
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .boxContent-right {
    width: calc(100% - calc(636px * @baseZoom));
    height: calc(522px * @baseZoom);
    background: url(../assets/unmanned_vehicle/storage_door.png) no-repeat;
    background-size: 100% 100%;
    margin-right: calc(144px * @baseZoom);
  }
}
.text {
  width: 100%;
  font-size: calc(48px * @baseZoom);
  font-weight: 500;
  color: #ffa414;
  line-height: 100%;
  text-align: center;
  margin-top: calc(88px * @baseZoom);
}
.xiaJianTou {
  width: calc(90px * @baseZoom);
  height: calc(90px * @baseZoom);
  margin: auto;
  margin-top: calc(49px * @baseZoom);
  img {
    width: 100%;
    height: 100%;
  }
}
.elDialogAll {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  .elDialogAllCenter {
    width: calc(960px * @baseZoom);
    height: calc(800px * @baseZoom);
    background: #ffffff;
    border-radius: calc(20px * @baseZoom);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    img {
      display: block;
      width: calc(256px * @baseZoom);
      height: calc(185px * @baseZoom);
      margin: auto;
      margin-top: calc(100px * @baseZoom);
    }
    .p1 {
      font-size: calc(32px * @baseZoom);
      font-weight: 500;
      color: #141414;
      line-height: 100%;
      text-align: center;
      margin-top: calc(40px * @baseZoom);
    }
    .anew {
      width: calc(440px * @baseZoom);
      height: calc(81px * @baseZoom);
      background: #ffffff;
      border: 1px solid #2f56ff;
      border-radius: calc(40px * @baseZoom);
      font-size: calc(32px * @baseZoom);
      font-weight: 400;
      color: #2f56ff;
      text-align: center;
      line-height: calc(81px * @baseZoom);
      margin: auto;
      margin-top: calc(44px * @baseZoom);
      cursor: pointer;
    }
    .p2 {
      font-size: calc(28px * @baseZoom);
      font-weight: 400;
      color: #666666;
      line-height: 100%;
      text-align: center;
      margin-top: calc(24px * @baseZoom);
    }
    .btn {
      width: calc(360px * @baseZoom);
      height: calc(80px * @baseZoom);
      background: #2f56ff;
      box-shadow: 0px calc(10px * @baseZoom) calc(20px * @baseZoom) 0px
        rgba(47, 86, 255, 0.4);
      border-radius: calc(10px * @baseZoom);
      font-size: calc(32px * @baseZoom);
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      line-height: calc(80px * @baseZoom);
      margin: auto;
      margin-top: calc(81px * @baseZoom);
    }
  }
}
</style>