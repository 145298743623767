<template>
  <el-dialog 
    center 
    :visible="visible" 
    lock-scroll 
    :close-on-click-modal="false" 
    :close-on-press-escape="false"
    width="430px"
    top="254px"
    @close="dialogClose"
    @closed="dialogClosed">
    <div slot="title" class="dialog-title-box">
      <span class="dialog-title">短信登录</span>
    </div>
    <el-form :model="loginForm" label-width="0" class="login-form" ref="loginForm" :rules="formRules">
      <el-form-item prop="phone">
        <el-input class="login-input" prefix-icon="el-icon-mobile-phone" placeholder="请输入手机号" v-model="loginForm.phone"></el-input>
      </el-form-item>
      <el-form-item style="margin-bottom:38px" prop="code">
        <el-input class="login-input" prefix-icon="el-icon-lock" placeholder="请输入验证码" v-model="loginForm.code" @keyup.enter.native="login()">
          <template slot="append">
            <el-button style="color:#909399" disabled v-if="codeLoading">发送中...</el-button>
            <el-button :style="verificationCodeStyle" :disabled="timeControl" @click="getVerificationCode()" v-else>{{timeControl?btnTime+' s':'获取验证码'}}</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item style="text-align:center">
        <el-button class="login-button" :loading="loading" @click="login()">登 录</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>
<script>
import * as rules from '@/utils/formRules'

/**
 * 组件使用说明：
 * @param visible boolean 使用时应该添加 .sync 修饰符
*/

export default {
  name:'publicLogin',
  props:{
    /* 使用时应该使用 :visible.sync */
    visible:{
      default:false,
      type:Boolean
    },
  },
  data(){
    return {
      loginForm:{phone:'',code:''},
      loading:false,
      timer:null, // 全局定时器
      btnTime:60,
      timeControl:false,
      formRules:{
        phone:[
          {required:true,message:'请输入手机号',trigger:'blur'},
          {validator:rules.FormValidate.validatePhone,trigger:'blur'}
        ],
        code:[
          {required:true,message:'请输入验证码',trigger:'blur'},
        ]
      },
      codeLoading:false,
    }
  },
  computed:{
    /**获取验证码的样式 */
    verificationCodeStyle(){
      if(this.timeControl===true){
        return {
          color:'#909399'
        }
      }else{
        return {
          color:'#BD1A2D'
        }
      }
    }
  },
  methods:{
    /**获取验证码 */
    getVerificationCode(){
      this.$refs.loginForm.validateField('phone',(err)=>{
        if(!err){
          this.codeLoading=true;
          this.$post('message/messageLogin',{phone:this.loginForm.phone}).then(res=>{
            this.codeLoading=false;
            this.timeControl=true;
            let {code,msg} = res.data;
            if(code===200){
              this.$message.info('验证码发送成功')
              this.timer =  setInterval(()=>{
                this.btnTime = this.btnTime - 1;
                if(this.btnTime===0){
                  this.clearTimer()
                }
              },1000);
            }else{
              this.$message.error(msg);
              this.clearTimer()
            }
          })
        }
      })
    },
    /**清除定时器 */
    clearTimer(){
      clearInterval(this.timer);
      this.timer = null;
      this.btnTime = 60;
      this.timeControl=false;
    },
    /**登录 */
    login(){
      this.$refs.loginForm.validate(flag=>{
        if(flag){
          this.loading = true;
          this.$post('/message/loginVerify/',{messageCode:this.loginForm.code,phone:this.loginForm.phone}).then(res=>{
            let {code,msg,user} = res.data;
            if(code===200){
              this.resetSetItem('userInfo',JSON.stringify(user))
              this.clearTimer();
              this.$message.success('登录成功');
              setTimeout(()=>{
                this.loading = false;
                this.$router.push({name:'inquiryProcess',query:{phone:this.loginForm.phone}});
                this.dialogClose()
              },1000)
            }else{
              this.loading = false;
              if(code === 1001){
                this.$message.warning('当前用户没有办件信息')
              }else{
                this.$message.warning(msg)
              }
            }
          }).catch(()=>{
            this.loading=false;
          })
        }
      })
    },

    /**点击 x 号关闭弹窗 */
    dialogClose(){
      this.$emit('update:visible',false)
    },
    /**弹窗关闭完成后的回调 */
    dialogClosed(){
      this.loginForm = {phone:'',code:''};
      this.$nextTick(()=>{
       this.$refs.loginForm.clearValidate()
      })
    }
  }
}
</script>
<style lang="less" scoped>
@baseBack:#BD1A2D;


.dialog-title-box{
  margin-top: 20px;
  .dialog-title{
    font-size: 24px;
    color:#333;
    font-weight: 600;
  }  
}

.login-form{
  width: 320px;
  margin:auto;
  .el-form-item{
    margin-bottom: 20px;
  }
}
.login-input{
  width: 320px;
  &::v-deep .el-input__inner{
    height: 48px;
    &:focus{
      border-color:@baseBack;
    }  
  }
  &::v-deep .el-input__icon{
    line-height: 48px;
  }
}
.login-button{
  width: 320px;
  height: 48px;
  background-color: @baseBack;
  border-color: @baseBack;
  color:#fff;
  font-size: 16px;
  &:focus, &:hover{
    background: @baseBack;
    border-color: @baseBack;
    color: #fff;
  }
  &:active{
    border-color: @baseBack;
    background-color: #fff;
    color:@baseBack;
  }
}
</style>