<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name:'App',
  data(){
    return {
      timer:null
    }
  },
  mounted() {
    if(sessionStorage.getItem('userInfo')){
      this.setLoginTimeout()
    }else{
      window.addEventListener('setItem',()=>{
       this.setLoginTimeout()
      })
    }

  },
  methods:{
    setLoginTimeout(){
      var _this = this
      //设置超时退出
      var lastTime = new Date().getTime();
      var currentTime = new Date().getTime();
      var timeOut = 10 * 60 * 1000; //设置超时时间： 10分

      window.addEventListener('mousemove',function(){
        lastTime = new Date().getTime(); //更新操作时间
      })

      function testTime() {
        currentTime = new Date().getTime(); //更新当前时间
        if (currentTime - lastTime > timeOut) { //判断是否超时
          _this.$message.info('登录超时')
          sessionStorage.clear()
          if(_this.$route.name==='inquiryProcess'){
            _this.$router.replace('/');
            clearInterval(_this.$timer)
          }
        }
      }
      /* 定时器  间隔1秒检测是否长时间未操作页面  */
      this.$timer = setInterval(testTime, 1000);  
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:#333;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #333;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
