<template>
  <div class="material-comfirm">
    <div class="submit-status">
      <div class="status-icon">
      </div>
      <p class="status-text">核验通过，请您确认以下申报材料</p>
    </div>
    <div class="form-card">
      <div class="form-card-content">
        <img class="form-card-icon" src="./assets/icon_4.png" alt="">  
        <span class="form-card-span">
          材料清单
        </span>
      </div>
      <van-cell class="van-field-c" v-for="item in materialList" :key="item.materialId" :title="item.materialName" value="点击查看" is-link @click="cellClick(item.fileIds)" />
    </div>
    <div class="esign-area form-card">
      <span class="esign-desc">我承诺所有申报信息真实有效，自愿承担法律责任。请确认签字：</span>
      <vue-esign ref="esign" :isCrop="true" :height="200" style="border-bottom:1px solid #F2F2F2"></vue-esign>
      <div style="display:flex;flex-direction:row-reverse;margin-top:10px">
        <van-button class="reset-button" type="info" size="small" color="#6F8DE6" @click="resetEsign">重 置</van-button>
      </div>
    </div>
    <div class="submit-button-c" @click="handleConfirm()">
      确 认
    </div>


    <!-- loading 遮罩 👇 -->
    <van-overlay :show="showOverlay">
      <div style="display: flex;align-items: center;justify-content: center;height: 100%;">
        <van-loading type="spinner" vertical>提交中，请稍后...</van-loading>
      </div>
    </van-overlay>
    <!-- loading 遮罩 👆 -->

    <van-overlay :show="showPdf" @click="closePdfOverlay()">
      <div style="display:flex;justify-content:flex-end;height:8%;align-items:center;padding-right:0.2rem">
        <van-icon name="clear" color="#fff" size="0.5rem" @click="closePdfOverlay()" />
      </div>
      <iframe :src="pdfSrc" frameborder="0" width="100%" height="92%"></iframe>
    </van-overlay>
  </div>

</template>

<script>

import {CellGroup,Cell,Icon,Overlay,Loading,Button} from 'vant';
import {Toast,ImagePreview} from 'vant'
/**
 * 签名组件
 * reset() 重置
 * generate() 生成base64格式的图片
 */
import vueEsign from 'vue-esign'
import {setRem} from '@/utils/commonFunc.js'
  export default {
    name:'materialConfirm',
    components:{
      vanCellGroup:CellGroup,
      vanCell:Cell,
      vanIcon:Icon,
      vanOverlay:Overlay,
      vanLoading:Loading,
      vanButton:Button,
      vueEsign
    },
    data(){
      return{
        materialList:[],
        showOverlay:false,
        showDialog:false,
        userAgent:navigator.userAgent,
        showPdf:false,
        pdfSrc:'',
      }
    },
    created(){
      this.getMaterialList()
    },
    mounted() {
      window.onresize = () => {
        return (() => {
          setRem(document)
        })();
      }
      this.$nextTick(()=>{
        document.title=this.$route.query.taskName;
        setRem(document);
      })
    },
    computed:{
      filterMaterialList(){
        return this.materialList.filter(item=>{
          return item.tempFileIds===''
        })
      }
    },
    methods:{
      cellClick(fileIds){
        let baseUrl = '';
        if(process.env.NODE_ENV==='develop'){
          baseUrl = '/api';
        }else {
          baseUrl = process.env.VUE_APP_BASE_URL;
        }

        if(fileIds.length>0){
          if(fileIds[0].fileType==='pdf'){
            let url = `${baseUrl}/acceptFile/viewFile?fileId=${fileIds[0].fileId}`
            //window.open('/static/pdf/web/viewer.html?file='+encodeURIComponent(url))
            this.pdfSrc='/static/pdf/web/viewer.html?file='+encodeURIComponent(url);
            this.showPdf=true
            return;
          }
          let arr = []
          fileIds.forEach(item=>{
            arr.push(`${baseUrl}/acceptFile/viewFile?fileId=${item.fileId}`)
          })
          ImagePreview(arr)          
        }else{
          Toast('⚠ 暂无材料，请先上传！')
        }
      },
      getMaterialList(){
        this.$post('/template/findMaterialArr',{recvId:this.$route.query.recvId}).then(res=>{
          let {code, materialArr,msg} = res.data;
          if(code === 200){
            this.materialList = materialArr
          }else{
            Toast.fail(msg);
          }
        }).catch(()=>{
          Toast.fail('服务器错误')
        })
      },
      /**重置签名区域 */
      resetEsign(){
        this.$refs.esign.reset()
      },
      handleConfirm(){
        this.$refs.esign.generate().then(res=>{
          this.showOverlay=true;
          this.$post('/acceptFile/uploadBase64ToFile',
          {file:res,userId:'122'}).then(res=>{
            let {code,data,msg} = res.data;
            if(code===200){
              this.$post('/template/setRedisFileIdByRecvId',{recvId:this.$route.query.recvId,fileId:data.fileId}).then(res=>{
                this.showOverlay=false;
                let {code,data,msg} = res.data;
                if(code===200){
                  Toast('材料确认成功！');
                  this.$router.push({name:'reportSubmit',query:{recvId:this.$route.query.recvId,taskId:this.$route.query.taskId,taskName:this.$route.query.taskName}})
                }else{
                  Toast('材料确认失败，请重试！')
                }
              }).catch(()=>{
                this.showOverlay=false;
                Toast.fail('服务器错误')
              })
            }else{
              this.showOverlay=false;
              Toast.fail('签名失败')
            }
          }).catch(()=>{
            this.showOverlay=false;
            Toast.fail('服务器错误')
          })
        }).catch(()=>{
          Toast.fail('请先签名')
        })
      },
      closePdfOverlay(){
        this.showPdf=false;
        this.pdfSrc = ''
      }

    }
  }
</script>

<style lang="less" scoped>
.material-comfirm{
  padding: 0.24rem;
  .submit-status{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0.08rem;
    .status-icon{
      width: 0.94rem;
      height: 0.94rem;
      border-radius: 50%;
      background-image:url('./assets/icon_adopt.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
    .status-text{
      color:#fff;
      font-size: 0.33rem;
      text-align: center;
      margin-bottom:0.48rem;
    }
  }
  .form-card{
    border-radius: 10px;
    background-color: #fff;
    padding: 10px;
    margin-bottom: 10px;
    .form-card-content{
      height:0.9rem;width: 100%;display:flex;align-items:center;    
      .form-card-icon{
        width: 0.28rem;
      }
      .form-card-span{
        font-size: 0.3rem;
        line-height: 0.9rem;
        margin-left:0.16rem;
        color:#333;
        font-weight: bold;
      }
    }
    &:last-child{
      margin-bottom: 0;
    }
  }
  .submit-button-c{
    line-height: 0.8rem;
    width: 4.8rem;
    text-align: center;
    background-color: #6F8DE6;
    border-radius: 10px;
    font-size: 0.33rem;
    color:#fff;
    margin:0.48rem auto 0.78rem;
  }
  .dialog-content {
    text-align: center;
    padding: 0.5rem 0.2rem;
  }
  .van-field-c{
    padding: 0.2rem 0.05rem;
    &::after{
      left: 0;
      right: 0;
      border-bottom: 1px dashed #D3D3D3;
    }
    &:last-child{
      &::after{
        border-bottom: none;
      }
    }
  }
}
.reset-button{
  border-radius: 10px;
}
.esign-desc{
  font-size: 0.28rem;
  color:#F7332C;
  display: flex;
  margin-bottom: 0.1rem;
}
</style>
<style lang="less">
body{
  background-image:url('./assets/taiyuan_theme_bg.png') !important;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #EDEDED;
}
</style>