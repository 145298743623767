// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/taiyuan_theme_next.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".matters-list[data-v-c7261a1e] {\n  padding: 0 0.24rem;\n}\n.search-area[data-v-c7261a1e] {\n  /* position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  padding-left: 15px;\n  padding-right: 15px;\n  padding-top: 10px;\n  background-color: rgba(255, 255, 255,0);\n  z-index:10; */\n  margin-top: 0.72rem;\n  margin-bottom: 0.48rem;\n}\n.themes-list-item[data-v-c7261a1e] {\n  margin-bottom: 0.16rem;\n}\n.themes-list-item > div[data-v-c7261a1e] {\n  height: 1.42rem;\n  background-color: #fff;\n  border-radius: 0.06944rem;\n  padding: 0 0.2rem;\n}\n.themes-list-item-link[data-v-c7261a1e] {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.themes-names[data-v-c7261a1e] {\n  width: 2.4rem;\n  color: #333;\n  font-size: 0.3rem;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  display: -webkit-box;\n  -webkit-box-orient: vertical;\n  -webkit-line-clamp: 2;\n}\n.right-arraw[data-v-c7261a1e] {\n  width: 0.32rem;\n  height: 0.32rem;\n  background: #98B0F7;\n  box-shadow: 0 0.06944rem 0.08333rem 0 rgba(119, 150, 242, 0.2);\n  border-radius: 50%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 0.12rem 0.18rem;\n  background-repeat: no-repeat;\n  background-position: 65%;\n}\n/* .themes-list-item:nth-child(odd){\n  padding-left: 0;\n  padding-right: 0.08rem;\n}\n.themes-list-item:nth-child(even){\n  padding-left: 0.08rem;\n  padding-right: 0;\n} */\n.van-search-c[data-v-c7261a1e] {\n  border-radius: 0.27778rem;\n  background-color: rgba(255, 255, 255, 0.3);\n}\n.van-search-c[data-v-c7261a1e] .van-field__control {\n  color: #fff;\n}\n.van-search-c[data-v-c7261a1e] .van-field__control::-webkit-input-placeholder {\n  color: #fff;\n}\n.van-search-c .van-cell[data-v-c7261a1e]::after {\n  border-bottom: none;\n}\n", ""]);
// Exports
module.exports = exports;
